
export default {
  landing: {
    block1: {
      title: "See if you are pre-approved",
      list: [
        "It won't affect your credit score",
        "It takes less than a minute",
        "Review your offer and apply",
      ],
      start: "Get Started",
    },
    block2: {
      title: "How Double Credit Limit Works",
      body: "Simply make your first six required monthly minimum payments on time and your initial Credit Limit up to $1,000 doubles up to $2,000.",
    },
    block3: {
      title: "{ProductName} Mastercard® Features",
      list: [
        {
          title: "Initial Credit Limit up to $1,000",
          body: "Card offers are for unsecured Mastercard with a Credit Limit between $300 and $1000.",
        },
        {
          title: "Double Your Credit Limit",
          body: "Simply make your first six required monthly minimum payments on time and your Initial Credit Limit up to $1.000 doubles up to $2,000.",
        },
        {
          title: "The Buying Power of Mastercard",
          body: "Shop everywhere Mastercard is accepted with your new {ProductName} credit card. Make the purchases you need with the buying power of your new credit card.",
        },
        {
          title: "No Hidden Fees",
          body: "Transparent disclosure of all {LinkTerms}fees and terms and conditions{/LinkTerms}. With {ProductName} Mastercard you know what you are signing up for.",
        },
        {
          title: "Mastercard Zero Liability Protection",
          body: "Card holders not help responsible for unauthorizes charges on the {ProductName} card. This is subject to {LinkMastercard}Mastercard Guidelines{/LinkMastercard}.",
        },
        {
          title: "Reports to All 3 Major Credit Bureaus",
          body: "To help people build or re-establish credit.",
        },
      ],
    },
    block4: {
      Terms: "See Full Terms",
      title: "Ways we protect you and your information:",
      body: "We use 128-bit encryption technology to protect your personal information when you’re using our site or apps. You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and you see a lock symbol in your web browser.",
    },
    desktopText: {
      title: "How Double Credit Limit Works",
      text: "Simply make your first six required monthly minimum payments on time and your initial Credit Limit up to $1,000 doubles up to $2,000.",
    },
  },
  step1: {
    block1: {
      title: "Let's get started!",
      subtitle: "We need your legal name to verify your identity",
    },
    disclaimer: {
      line1:
        "If you become a customer, we'll notify you via text, email, or phone of any activity on your account.",
      line2: "You may opt out at any time.",
    },
  },
  step2: {
    block1: {
      title: "Nice to meet you {ApplicantName}. Let us know where you live.",
    },
    disclaimer: {
      line1:
        "If you become a customer, we'll notify you via text, email, or phone of any activity on your account.",
      line2: "You may opt out at any time.",
    },
    invalidStateModal: {
      invalidStateMessage:
        "This offer is not available for residents of Puerto Rico, U.S. Territories, Colorado, Illinois, Massachusetts and West Virginia.",
    },
    spouseInfo:
      "If a physical address is not provided, the notification to your spouse will be sent to the primary physical address on this application.",
  },
  step4: {
    block1: {
      title: "You are almost done! Help us protect you from fraud.",
      footer:
        "We use 128-bit encryption technology to protect your personal information when you’re using our site or apps. You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and you see a lock symbol in your web browser.",
    },
  },
  step3: {
    block1: {
      title: "Let's get a quick snapshot of your financial situation.",
    },
    form: {
      disclaimerAnnualIncome:
        "Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.",
      disclaimerAdditionalCardholder:
        "(Additional Fee of $30.00) Cannot be the same name as the primary cardholder.",
    },
  },
  step5: {
    block1: {
      title:
        "One last step to see if you are eligible. We need your agreement to the following terms:",
    },
    form: {
      prequalAuthDisclaimer:
        "Note: Pre-qualification will not impact your credit score. We will notify you if you are eligible and what product you qualify for. You decide if you want to continue and apply for the product. When you do apply, that will affect your credit score.",
    },
  },
  userAgreement: {
    title: "User Agreement",
    userAgreementText1:
      "By authorizing pre-qualification you (i) acknowledge that you are providing 'written instructions' and authorize Celtic Bank to obtain information from your personal credit profile from one or more credit reporting agencies in order to conduct a pre-qualification for credit and (ii) you authorize Celtic Bank to present you with other offers of credit and credit related products. Pre-qualification does not guarantee account approval and you must submit a full application for review in order to apply for any pre-qualified offer(s) of credit.",
  },
  approval: {
    block1: {
      approved: "You are Approved!",
      congratulations:
        "Congratulations! You are now a {ProductName} Platinum Cardholder",
      approved2:
        "You are approved for an Unsecured {ProductName} credit card issued by {bankName} and serviced by Continental Finance® Company, LLC.",
    },
    block2: {
      thanks:
        "Thank you for choosing the {ProductName} Mastercard®. Your new {ProductName} credit card should arrive in 7 to 10 business days.",
      list: [
        "Your $500 initial credit limit will double to $1,000 after making your first six monthly minimum payments on time",
        "Free monthly credit score from Experian when you sign up for e-statements",
        "Mastercard® Zero Liability Protection (subject to Mastercard guidelines)",
        "Monthly Reporting to three major credit bureaus",
        "Mobile App to help manage your account",
      ],
    },
    block3: {
      prohibitions:
        "The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, national origin, sex, marital status, age (provided that the applicant has the capacity to enter into a binding contract); because all or part of the applicant's income is derived from a public assistance program; or because the applicant has in good faith exercised his/her right under the Consumer Credit Protection Act. The federal agency that administers the compliance with this law concerning this creditor is the FDIC Consumer Response Center, 1100 Walnut Street, Box #11, Kansas City, MO 64106.",
    },
  },

  termsStep: {
    preApproved: {
      title: "You are Pre-Approved!",
      informationPreAmount:
        "Congratulations! You have been Pre-Approved for a Surge Mastercard® with an initial credit limit of",
      informationPostAmount:
        "To complete your application and get your card, you must review the information below and sign and submit.",
    },
    applicantDisclosures: {
      title: "Applicant Disclosures",
      shumerBoxText:
        "Account Opening Disclosures",
      checkboxPreDescription:
        "By clicking this box, you agree that you have ",
      checkboxPostDescription:
        " of the Surge Mastercard credit card offer.",
      linkText: 
        "read the full terms and conditions",
    },
    helpProtect: {
      title: "Help Protect Your Credit",
      yesText:
        "I want to purchase Optional CONTINENTAL CREDIT PROTECTION (the “Program”). My decision to purchase or not to purchase the Program will not affect any application for credit or the terms of any existing account I have with Celtic Bank.",
      noText: "I am not interested.",
    },
    acknowledgements: {
      title: "Acknowledgements",
      checkboxDescription:
        "You acknowledge that by checking this box and pressing “submit application” you are completing and signing this Application electronically. You understand you are applying to Celtic Bank for a Surge Mastercard credit card and that by submitting the application you are authorizing the following: Obtain and use credit bureau reports on you, contact you by any means you have provided to us, including via phone, SMS/text, email, and/or mail, and exchange information about you in connection with this Application and any update, extension of credit, review, or collection of your Account.",
    },
  },
};
