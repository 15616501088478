import React from 'react';
import { useStoreState } from 'easy-peasy';

function SurgeSliderTB() {
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    return (
        <div className="trustpilot-widget" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5cefd6f2951de70001a64d1d"
            data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-schema-type="Organization">
            <a href={productSettings.trustpilotUrl} target="_blank" rel="noopener noreferrer">Trustpilot</a>
        </div>
    );
}

export default SurgeSliderTB;