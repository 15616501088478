import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Wildcard from "../../../../services/wildcard";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ValidatorService from "../../../../services/validator";
import { masking, unmasking } from "../../../../services/masking";
import Stepers from "../components/stepers";
import ReactGA from "react-ga4";
import FacebookPixel from "react-fbq";

function Step4() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const text = useStoreState((state) => state.prequalText.step4);
  const {
    setError,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ shouldFocusError: true, mode: "onSubmit" });

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const validatorService = new ValidatorService();
  const wildcard = new Wildcard();
  const siteSetting = useStoreState((state) => state.siteSettingsModel);

  useEffect(() => {
    window.scrollTo(0, 0);
    //Google Pixels
    if (
      siteSetting.Pixels.find(
        (Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG
      )
    ) {
      ReactGA.send({ hitType: "pageview", page: "/step4" });
      ReactGA.event({category: "tracking", action: "step4",});
    }

    //Facebook Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")) {
      FacebookPixel.initialize({
        id: siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")[
          "Tag"
        ],
      });
      FacebookPixel.pageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      //The validation rule checks the ssn has only numbers so we need to replace all other characters
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldName !== "dob"
          ? fieldValueObject[fieldName].replaceAll("-", "")
          : fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    setIsLoading(false);
    if (!foundErrors) {
      history.push("/step5" + window.location.search);
    }
  }

  return (
    <>
      <div className="container appFormsBackground">
        <h6 className="displayFlexCenter">
          <strong>STEP 4/5</strong>
        </h6>
        <div className="steperContainer">
          <Stepers step={4} totalSteps={5} />
        </div>
        <div className="row">
          <div className="col-12">
            <h6>
              <strong>{wildcard.proccess(text.block1.title)}</strong>
            </h6>
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-12">
                <div className="form-group">
                  <label htmlFor="ssn">Social security number</label>
                  <div className="input-container">
                    <input
                      value={masking(info.ssn, "XXX-XX-XXXX")}
                      onInput={(e) =>
                        {
                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","") : e.nativeEvent.data;
                          if (/[0-9]/.test(value)  || e.nativeEvent.inputType === "deleteContentBackward") {
                              setInfo({ index: "ssn", value:  unmasking(e,"XXX-XX-XXXX", info.ssn) })}
                        }
                      }
                      type="text"
                      className="form-control"
                      placeholder="999-99-9999"
                      {...register("ssn")}
                    />
                  </div>
                  {errors.ssn &&
                    errors.ssn.message.map((message) => (
                      <div className="error-message"> {message} </div>
                    ))}
                </div>

                <div className="form-group">
                  <label htmlFor="dob">Date of Birth</label>
                  <div className="input-container">
                    <input
                      type="date"
                      id="start"
                      value={info.dob}
                      name="trip-start"
                      className="form-control"
                      onInput={(e) => {
                        setInfo({
                          index: "dob",
                          value: e.target.value
                        });
                      }}
                      {...register("dob")}
                    />
                  </div>

                  {errors.dob &&
                    errors.dob.message.map((message) => (
                      <div className="error-message"> {message} </div>
                    ))}
                </div>
              </div>
          </div>

          <div className="container disclaimerContainer">
            <div className="smallerText">{text.block1.footer}</div>
          </div>
          <div className="buttonGroup">
            <div className="buttonContainer">
              <Button
                bsPrefix="grey continueButton"
                id="btn-back"
                className="btn"
                onClick={() => {
                  history.push("/step3" + window.location.search);
                }}
              >
                Back
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                bsPrefix="mastercardOrangeBg continueButton"
                disabled={isLoading}
                id="btn-see-offers"
                className="btn"
                type="submit"
                onClick={() => {}}
              >
                {isLoading ? "Submitting..." : "Continue"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Step4;
