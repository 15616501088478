import React from "react";
import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function InvalidStateModal({ showInvalidStateModal, setShowInvalidStateModal }) {
  const text = useStoreState((state) => state.prequalText.step2);
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        show={showInvalidStateModal}
        onHide={() => setShowInvalidStateModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Invalid State </Modal.Title>
        </Modal.Header>
        <Modal.Body>{text.invalidStateModal.invalidStateMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {window.scrollTo(0, 0);history.push("/declined" + window.location.search)}}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InvalidStateModal;
