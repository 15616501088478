let GbText = {
    "tooltip" : {
        "upTo" : "Cover Me Now Overdraft Privilege helps manage your finances. Establishing a qualifying direct deposit earns you $50 of coverage and maintaining a $300 average YTD collected account balance earns you $100.",
        "checkingBundle" : "Your Grand360 account automatically includes a checking account and a separate account to help you save and get where you want to go! All the tools you need to organize your finances, budget, and save easily.",
        "easyAccess" : "Grand360 includes online and mobile banking, bill pay, mobile deposit, Zelle®, external transfers, 40,000 surcharge-free MoneyPass® ATMs, spend tracker, and e-statements. These tools require setup. Your mobile carrier determines access and message/data rates.",
        "paidEarly" : "Qualified direct deposits of payroll, pension, and government benefits can provide early payday! Access requires our receipt of funds and isn’t guaranteed. Deposits may vary, risk screenings and transactional limits apply.",
        "morePerk" : "With your Grand.bank Mastercard debit card you get even more benefits, such as: Zero Liability, ID Theft Protection, Extended Warranty, Emergency Cash Advance, and Satisfaction Guarantee services.",
        "moneyBack" : "If during the 1st six full-calendar months you feel the Grand360 plan has not improved your financial life simply close your account and we’ll return the monthly plan fees charged since you opened the plan, provided all accounts are in good standing and not otherwise overdrawn.",
        "automaticSaving" : "Saving can be easy with our automatic savings tools, such as Round-Up Savings and Recurring Transfers, plus you earn interest on your savings balances. It’s time to make your money work for YOU!",
        "jointAccount" : "Want to add a spouse, friend, or family member? Grand360 can do that!",
        "freeMonitoring" : "The credit score monitoring tool allows you to check your credit score anytime right from your phone or computer. It is available 24/7.",
        "wellnessTool" : "You'll have unlimited access to online, interactive financial education to help guide you to reach your goals. Best of all – you can access all the programs anywhere, anytime on our website: grand.bank.",
        "safeSound" : "Deposits with Grand Bank for Savings, FSB, are FDIC-insured up to $250,000 per account, plus you can set up real-time alerts and receive fraud alerts from us.",
        "noCheck" : "A credit check is not required to open a Grand360 account."
    }
}

export default GbText;