import React from 'react';
import { useStoreState } from 'easy-peasy';


function Pendfunding() {

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const applicant = useStoreState(state => state.applicantModel.applicant);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2>
                        Congratulations!
                    </h2>
                    <h2>
                        You are approved for the {brandSettings.ProductName.toUpperCase()} Mastercard® Credit Card.
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <img src={brandSettings.CardImage} alt="" className="col-lg-12" />
                </div>
                <div className="col-lg-6 result-message pendfee">
                    <div className="col-lg-12"><b>Approved Applicant:</b>&nbsp;{applicant.firstName}&nbsp;{applicant.lastName}</div>
                    <div className="col-lg-12"><b>Your Reference Number:</b>&nbsp;{applicationResult.referenceNumber}</div>
                    <div className="col-lg-12"><b>Credit Limit:</b>&nbsp;${applicationResult.credit_limit}</div>
                    <div className="col-lg-12"><b>Security Deposit:</b>&nbsp;${applicationResult.securityDeposit}</div>
                    <div className="col-lg-12">
                        In order to use your new credit card, you will need to submit your security deposit of ${applicationResult.securityDeposit}. Your
                        account can not be opened or used until this deposit is complete. If you would prefer to make this deposit over the phone, please call
                        us at 1-866-612-1404. If you would prefer to make this deposit online please select one of the options below. Thank you and congratulations!
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <p>Choose one of the following:</p>
                </div>
                <div className="col-lg-6">
                    <button className="btn col-lg-12" style={{ backgroundColor: brandSettings.Primary }}>DEBIT CARD</button>
                </div>
                <div className="col-lg-6">
                    <button className="btn col-lg-12" style={{ backgroundColor: brandSettings.Primary }}>CHECKING OR SAVINGS ACCOUNT</button>
                </div>
                <div className="col-lg-12 text-center">
                    <small><b>*Please note that paying by checking or savings account can delay access to your account for 10 days while the funds clear.</b></small>
                </div>
            </div>
            <div className="row remove-margin">
                <div className="col-lg-12 text-center">
                    <h2>Other ways to fund your security deposit</h2>
                </div>
            </div>
            <div className="row reminder">
                <p>CHOOSE ONE OF THE FOLLOWING:</p>
                <div>
                    <small>
                        <ol>
                            <li>
                                <b>Call us</b> to pay over the phone with your debit card - Call us at <b>1-866-612-1404</b> and we can process your payment
                            over the phone. Our hours of operations are <b>Monday - Friday 7:00 a.m. - 10:00 p.m.</b> and <b>Saturday 9:00 a.m. - 4:00 p.m. ET</b>
                            </li>
                            <li>
                                <b>Mail in a check</b> to Celtic Bank, P.O. Box 8099, Newark, DE 19714-8099.
                        </li>
                            <li>
                                <b>Western Union</b> - Sending money is fast and easy with over 380,000 participating agent locations worldwide. Western Union
                            is available at places such as supermarkets, check cashers and convenience stores.
                            <div>Go to your nearest Western Union branch and use the following information:</div>
                                <div>Send To: <b>Continental Deposit</b></div>
                                <div>Code City: <b>CFCDeposit</b></div>
                                <div>State: <b>DE</b></div>
                                <div>
                                    To properly credit your account, please include the last 4 digits of your social security # on the Account Number with Company
                                    line contained in Section 2 of the Western Union QuickCollect form.
                                </div>
                                <div>To send money by phone, please call <b>1-800-CALL-CASH (1-800-225-5227)</b> and use the following information:</div>
                                <div>Send To: <b>Continental Deposit</b></div>
                                <div>Code City: <b>CFCDeposit</b></div>
                                <div>State: <b>DE</b></div>
                                <div>Account Number: <b>Last 4 digits of your social security number</b></div>
                                <div>Your Security Deposit amount due: <b>${applicationResult.securityDeposit}</b></div>
                            </li>
                            <li>
                                <div><b>MoneyGram</b> - Money transfers are fast and easy when you use MoneyGram.</div>
                                <div>Go to your nearest MoneyGram branch and use the following information:</div>
                                <div>Receive Code: <b>9637</b></div>
                                <div>Company Paying: <b>Continental Finance</b></div>
                                <div>City/State: <b>Newark/DE</b></div>
                                <div>Account Number: <b>Last 4 digits of your social security number</b></div>
                                <div>Your Security Deposit amount due: <b>${applicationResult.securityDeposit}</b></div>
                            </li>
                        </ol>
                    </small>
                </div>
            </div>
        </div>

    );

}

export default Pendfunding;