import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useFormContext } from "react-hook-form";

function SpouseForm() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
   
    const { register, formState: { errors }, unregister, clearErrors } = useFormContext();

    useEffect(() => {
        //unregister controls to avoid validations
        if (!info.isMarried || !info.spouseHasDifferentAddress) {
            unregister(["spouseFirstName", "spouseMiddleInitial", "spouseLastName", "spouseEmail", "spouseHasDifferentAddress", "spouseAddress1", "spouseState", "spouseCity", "spouseZip"]);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [info.isMarried, info.spouseHasDifferentAddress]);

    return (
        <>
            { info.State === "WI" && (
                <div className="row mt-3">
                    <div className="col-12">
                        <div className=""> We are required under Wisconsin law to provide your spouse with information pertaining to the terms and conditions of this credit card offer.</div>
                    </div>
                </div>
            )}
                <div className="row  mt-3">
                <div className="col-6">
                    <div>Are you married?</div>
                    {errors.isMarried && errors.isMarried.message.map((message) => (
                        <div className="error-message"> <p className="pl-0">This field is missing. Please select an option.</p> </div>
                    ))}
                </div>
                <div className="col-6">
                    <div className="radioGroup">
                        <div className="radio-btn">
                            <input type="radio" checked={info.isMarried === true} value={true} {...register("isMarried")} onClick={(e) => setInfo({ index: "isMarried", value: true })}/>
                            <div>Yes</div>
                        </div>
                        <div className="radio-btn">
                            <input type="radio" checked={info.isMarried === false} value={false} {...register("isMarried")} onClick={(e) => setInfo({ index: "isMarried", value: false })}/>
                            <div>No</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (info.isMarried === true) && (
                    <>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <h6>Spouse's Information - <small>If a physical address is not provided, the notification to your spouse will be sent to the primary physical address on this application</small></h6>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="spouseFirstName">First Name</label>
                                    <div className="input-container">
                                        <input  
                                            maxLength={17} 
                                            value={info.spouseFirstName} 
                                            onInput={(e) =>{ 
                                                if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                    if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                        if((e.target.value.split(" ").length - 1) < 2){
                                                            setInfo({ index: "spouseFirstName", value: e.target.value })}
                                                        }
                                                    }
                                                }
                                                
                                            } 
                                            type="text" className="form-control" placeholder="First Name" {...register("spouseFirstName")} />
                                    </div>
                                    {errors.spouseFirstName && errors.spouseFirstName.message.map((message) => (
                                        <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-2 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="spouseMiddleInitial">MI</label>
                                    <div className="input-container">
                                        <input 
                                            maxLength={1} 
                                            value={info.spouseMiddleInitial} 
                                            onInput={(e) => {
                                                if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                    setInfo({ index: "spouseMiddleInitial", value: e.target.value })}
                                                }
                                            } 
                                            type="text" className="form-control" placeholder="MI" {...register("spouseMiddleInitial")} />
                                    </div>
                                    {errors.spouseMiddleInitial && errors.spouseMiddleInitial.message.map((message) => (
                                        <div className="error-message"> {message} </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="spouseLastName">Last Name</label>
                                    <div className="input-container">
                                        <input  
                                            maxLength={25}
                                            value={info.spouseLastName} 
                                            onInput={(e) => {
                                                if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                    if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                        if((e.target.value.split(" ").length - 1) < 2){
                                                            setInfo({ index: "spouseLastName", value: e.target.value })}
                                                        }
                                                    }
                                                }
                                            } 
                                            type="text" className="form-control" placeholder="Last Name" {...register("spouseLastName")} />
                                    </div>
                                    {errors.spouseLastName && errors.spouseLastName.message.map((message) => (
                                        <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-2 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="spouseSuffix">Suffix</label>
                                    <div className="input-container">
                                        <select
                                        className="form-control"
                                        id="spouseSuffix"
                                        onInput={(e) =>
                                            setInfo({ index: "spouseSuffix", value: e.target.value })
                                        }
                                        {...register("spouseSuffix")}
                                        >
                                        <option value=""></option>
                                        <option value="JR">JR</option>
                                        <option value="SR">SR</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                        <option value="IV">IV</option>
                                        </select>
                                    </div>
                                    {errors.spouseSuffix && errors.spouseSuffix.message.map((message) => (
                                        <div className="error-message"> {message} </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="spouseEmail">Email Address</label>
                                    <div className="input-container">
                                        <input 
                                            value={info.spouseEmail} 
                                            onInput={(e) => {
                                                if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                    if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                                        if((e.target.value.split("@").length - 1) < 2){
                                                            setInfo({ index: "spouseEmail", value: e.target.value })}
                                                        }
                                                    }
                                                }
                                            } 
                                            type="text" className="form-control" placeholder="" {...register("spouseEmail")} />
                                    </div>
                                    {errors.spouseEmail && errors.spouseEmail.message.map((message) => (
                                        <div className="error-message"> This is a required field. Please ensure this value is correct </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="pl-0">Does your spouse have a different address?</div>
                                {errors.spouseHasDifferentAddress && errors.spouseHasDifferentAddress.message.map((message) => (
                                    <div className="error-message"> This field is missing. Please select an option. </div>
                                ))}
                            </div>
                            <div className="col-6">
                                <div className="radioGroup">
                                    <div className="radio-btn">
                                        <input type="radio" checked={info.spouseHasDifferentAddress === true} value={true} {...register("spouseHasDifferentAddress")} onClick={(e) => setInfo({ index: "spouseHasDifferentAddress", value: true, })}/>
                                        <div>Yes</div>
                                    </div>
                                    <div className="radio-btn">
                                        <input type="radio" checked={info.spouseHasDifferentAddress === false} value={false} {...register("spouseHasDifferentAddress")} onClick={(e) => setInfo({ index: "spouseHasDifferentAddress", value: false, })}/>
                                        <div>No</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            ((info.spouseHasDifferentAddress) && (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-lg-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="spouseAddress1">Address <small>(no P.O. boxes)</small></label>
                                                <div className="input-container">
                                                    <input maxLength={26} value={info.spouseAddress1} onInput={(e) => {setInfo({ index: "spouseAddress1", value: e.target.value.trimLeft().replace("  ", " ") }); clearErrors("spouseAddress1Duplicated")}} type="text" className="form-control" placeholder="Address" {...register("spouseAddress1")} />
                                                </div>
                                                {errors.spouseAddress1 &&  (
                                                    <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                                                )}
                                                {errors.spouseAddress1Duplicated &&  (
                                                    <div className="error-message">The spouse address cannot be the same as the applicant address</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="spouseAddress1">City</label>
                                                <div className="input-container">
                                                    <input 
                                                        maxLength={18} 
                                                        value={info.spouseCity} 
                                                        onInput={(e) => {
                                                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                                if(/^[a-zA-Z ]+$/.test(e.target.value) || e.target.value === ""){
                                                                    setInfo({ index: "spouseCity", value: e.target.value })}
                                                                }
                                                            }
                                                        } 
                                                        type="text" className="form-control" placeholder="City" {...register("spouseCity")} />
                                                </div>
                                                {errors.spouseCity && errors.spouseCity.message.map((message) => (
                                                    <div className="error-message"> {message} </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="spouseState">State</label>
                                                <div className="input-container">
                                                    <select defaultValue={info.spouseState} className="form-control" id="spouseState" onInput={(e) => setInfo({ index: "spouseState", value: e.target.value })} {...register("spouseState")}>
                                                        <option value=""></option>
                                                        {Setting.statesList.map((state) => {
                                                            return (
                                                                <option value={state.Code} key={state.Code}>
                                                                {state.Name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                {errors.spouseState && errors.spouseState.message.map((message) => (
                                                    <div className="error-message"> This field is missing. Please select an option. </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="spouseZip">Zip Code</label>
                                                <div className="input-container">
                                                    <input 
                                                        maxLength={9} 
                                                        value={info.spouseZip} 
                                                        onInput={(e) => {
                                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                                if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
                                                                    setInfo({ index: "spouseZip", value: e.target.value })}
                                                                }
                                                            }
                                                        } 
                                                        type="text" className="form-control" placeholder="Zip Code" {...register("spouseZip")} />
                                                </div>
                                                {errors.spouseZip && (
                                                    <div className="error-message"> This field is missing or invalid. Please fill it correctly. </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                    </>
                )
            }
        </>
    )
}

export default SpouseForm;