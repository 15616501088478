import React from 'react';
import { useStoreState } from "easy-peasy";

class AudioEyeSupport extends React.Component {

    constructor() {
        super();
        this.Setting = useStoreState((state) => state.siteSettingsModel);
    }

    setup = () => {
        if(this.Setting.mainSettings.AudioEye !== null){
            
            let hash = this.Setting.mainSettings.AudioEye
            console.log("Loading Audio Eye Support: " + hash)
            var b = function() {
                window.__AudioEyeSiteHash = hash;
                var a = document.createElement("script");
                a.src = "https://wsmcdn.audioeye.com/aem.js";
                a.type = "text/javascript";
                a.setAttribute("async", "");
                document.getElementsByTagName("body")[0].appendChild(a)
                console.log("Audio Eye Support inserted!")
            };
            "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", b) : window.attachEvent && window.attachEvent("onload", b) : b();
        }
    }
}

export default AudioEyeSupport