import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import UserDataForm from "../../global-elements/userDataForm";
import ColorCardChoiceForm from "../../global-elements/colorCardChoiceForm";
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import marketing from "../../../../services/marketing";
import "../../../../assets/terms/dm-terms.css";
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import { useEffect } from "react";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import PixelsService from '../../../../services/pixels';

function Terms() {
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const setGrandBanckDeclined = useStoreActions(
    (actions) => actions.siteSettingsModel.setGrandBanckDeclined
  );
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const text = useStoreState((state) => state.dmText.terms);
  const setApplicationResult = useStoreActions(
    (actions) => actions.applicationResultModel.setApplicationResult
  );
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const alResult = useStoreState(state => state.alResult);
  const setAlResult = useStoreActions(actions => actions.setAlResult);
  const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
  const WaterfallEvents = { "DM" : 10, "PREQUAL" : 6, "CFC" : 18, "STANDALONE" : 24, "CS" : 29};
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const history = useHistory();
  const wildcard = new Wildcard();
  const client = new AxiosWrapper();
  const validatorService = new ValidatorService();
  const methods = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const utms = new marketing();
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const closeError = () => setErrorShow(false);
  const pendingInfo =  ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const termsPixels = useStoreState(state => state.termsPixels);
  const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
  const PixelService = new PixelsService();

  useEffect(() => {
    if (!termsPixels) {
      PixelService.pageView("/Terms").event("google","DM","Terms");
      getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
      setTermsPixel(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const validateState = async () => {
    //Validate Cardholders Address
    const response = await client.post("validate-state", {
      FirstName: info.FirstName,
      LastName: info.LastName,
      Address1: info.Address1,
      Address2: info.Address2,
      City: info.City,
      State: info.State,
      Zip: info.Zip,
      Product: brandSettings.ProductName,
      checkNonBusinessState: true,
    });
    
    if (response.Result === "INVALIDSTATE") {
      setInfo({ index: "step", value: 3 });
      response.ResultWebPageHash = "INVALIDSTATE";
      response.decline_message = "We do not currently do business in the state that you reside in";
      setApplicationResult(response);
      resultStep(true);
      setShowInvalidStateModal(true);

      return false;
    }
    if (response.Result === "MISMATCH") {
      methods.setError("City", {
        type: "string",
        message: [
          "Please Check Your City As It Does Not Match In Our Database.",
        ],
      });
      methods.setFocus("City");
      return false;
    }

    //Validate Spouse Address (If have one)
    if(info.spouseHasDifferentAddress === true){
      const response = await client.post("validate-state", {
        FirstName: info.spouseFirstName,
        LastName: info.spouseLastName,
        Address1: info.spouseAddress1,
        Address2: info.spouseAddress2,
        City: info.spouseCity,
        State: info.spouseState,
        Zip: info.spouseZip,
        Product: brandSettings.ProductName,
        checkNonBusinessState: true,
      });

      if (response.Result === "MISMATCH") {
        methods.setError("spouseCity", {
          type: "string",
          message: [
            "Please Check Your City As It Does Not Match In Our Database.",
          ],
        });
        methods.setFocus("spouseCity");
        return false;
      }
    }
    return true;
  };

  const submit = async (data) => {
    setLoading(true);
   
    if (!validatorService.preValidations(data, methods, info)) {
      const validState = await validateState();
      if (validState) {
        await submitApplication();
      }
    }

    setLoading(false);
  };

  async function submitApplication() {
    let applicantApiData = {
      Applicant: {
        FirstName: info.FirstName,
        MiddleName: info.MiddleName,
        LastName: info.LastName,
        Suffix: info.Suffix,
        Address1: info.Address1,
        Address2: info.Address2,
        City: info.City,
        State: info.State,
        Zip: info.Zip,
        Email: info.Email,
        PrimaryPhone: info.primaryPhone,
        SecondaryPhone: info.SecondaryPhone,
        DOB: info.dob,
        SSN: info.ssn,
        MonthlyIncome: info.MonthlyIncome,
        IncomeSource: info.incomeSource,
        HasCheckingAccount: info.hasCheckingAccount,
        cashIntent: info.cashIntent === "" ? false : info.cashIntent,
        ReservationCode: info.ReservationCode ? info.ReservationCode : "",
      },
      Terms: {
        ESignConsent: info.eSignConsent === "1" ? true : false,
        CreditProtection: info.creditProtection === "yes" ? true : false,
        TcAcknowledgement: info.tcAcknowledgement === "1" ? true : false,
        EmailOptIn: true,
        CardColorCode: info.cardColorCode,
        SMSConsent : info.SMSConsent
      },
      AdditionalCardholder: {
        AdditionalCardholder:
          info.hasAdditionalCardholder === "true" ? true : false,
        AdditionalCardholderFirstName: info.additionalCardholderFirstName || "",
        AdditionalCardholderMI: info.additionalCardholderMI || "",
        AdditionalCardholderLastName: info.additionalCardholderLastName || "",
        AdditionalCardholderSuffix: info.additionalCardholderSuffix || "",
      },
      SpouseInformation: {
        SpouseHasDifferentAddress: info.spouseHasDifferentAddress || "false",
        SpouseFirstName: info.spouseFirstName || "",
        SpouseMiddleName: info.spouseMiddleInitial || "",
        SpouseLastName: info.spouseLastName || "",
        SpouseSuffix: info.spouseSuffix || "",
        SpouseAddress1: info.spouseAddress1 || "",
        SpouseAddress2: info.spouseAddress2 || "",
        SpouseCity: info.spouseCity || "",
        SpouseState: info.spouseState || "",
        SpouseZip: info.spouseZip || "",
        SpouseEmail: info.spouseEmail || "",
        IsMarried: info.isMarried || "false",
      },
      Tracking: {
        SessionId: sessionStorage.getItem("oldSessionToken"),
        SrcId: "",
        SID: client.findParam("sid") || "",
        PubId:(client.findParam("pub") || client.findParam("pubid")) || Setting.productSettings.PubId,
        SubId: info.subId,
        HID: client.findParam("hid") || "",
        C1: client.findParam("c1") || "",
        C2: client.findParam("c2") || "",
        C3: client.findParam("c3") || "",
        Gclid:(JSON.parse(sessionStorage.getItem("trafficInfo"))["Provider"] === "Google")? JSON.parse(sessionStorage.getItem("trafficInfo"))["ClickId"] : "",
        GAdsExpirationDate: info.gAdsExpirationDate,
        Fbclid: client.findParam("fbclid") || "",
        FbAdsExpirationDate: info.fbAdsExpirationDate,
        Msclid: client.findParam("msclid") || "",
        MsAdsExpirationDate: info.msAdsExpirationDate,
        TransunionBlackBox: sessionStorage.getItem("blackBox") || "",
      },
      SiteSettings: {
        WebSite: Setting.domain,
        EngineID: 0,
        IsPrequal: false,
        PID: Setting.productSettings.PID,
        ProductCode: info.ProductCode,
        ProductId: Setting.productSettings.ProductId,
        AppRef: Setting.productSettings.ApplicationSourceReference
          ? Setting.productSettings.ApplicationSourceReference
          : "",
        Step: info.step,
        TermsId: Setting.mainSettings.TermsId,
        Token: client.findParam("token") || "",
      },
      HasCardColorChoice: Setting.mainSettings.HasCardColorChoice
    };

    let response = await client.post("submit-application", applicantApiData);

    if (response) {
      if (response.IsGrandbank === true) {
        setGrandBanckDeclined(true);
      }

      if (response.result != null) {
        //Marketing tracking
        utms.updateAppId(response.ref_number || "");
        utms.updateSessionReport( "appResult"  , response.result.toUpperCase());
        utms.updateSessionReport( "applicantId", response.ref_number || "");


        setApplicationResult(response);
        setInfo({ index: "step", value: 3 });
        resultStep(true);
        window.scrollTo(0, 0);
        
        if (response.result.toUpperCase() === "APPROVED") {
          if(Setting.mainSettings.Flow === 'DM' && Setting.brandSettings.ProductName.toLowerCase() === 'fit'){
            const url = "https://" + ((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? "test" : "") +"offer.your"+brandSettings.ProductName.toLowerCase()+"card.com/payment/?RefNo="+response.ref_number
            if(alResult === false){
              PixelService.pageView("/approved").approvedCalls(response.ref_number)
             
              utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, response.ref_number, Setting.productSettings.ProductId, "Flow");
              
              getRoutingSettings({ Url: Setting.domain, Route: "/approved"});
              setAlResult(true)
          }
            window.location.replace(url); 
            return;
          }

            

          history.push("/approved" + window.location.search);
          return;
        }
        if (pendingInfo.includes(response.result.toUpperCase())) {
          history.push("/pendinfo" + window.location.search);
          return;
        }
        history.push("/declined" + window.location.search);
        return;
      }
    } else {
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className="row"
        id="header-text-container"
        style={{ backgroundColor: Setting.brandSettings.Primary }}
      >
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <ErrorModal
          size="sm"
          show={errorShow}
          message={errorMessage}
          closeError={closeError}
        />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(submit)}
          >
            <div className="dm-form">
              <UserDataForm />
            </div>
            {Setting.productSettings.ProductId === 1 || Setting.productSettings.ProductId === 10 ? (
              <div className="row">
                <ColorCardChoiceForm />
              </div>
            ) : null}

            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">E-Sign Disclosure</h1>
              </div>
              <Esign />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Pricing and Terms</h1>
              </div>
              <div className="col-12 ">
                <div className="terms-wrapper">
                  <TC data={{ productCode: info.ProductCode }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Credit Protection</h1>
              </div>
              <CreditProtection />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Acknowledgements</h1>
              </div>
              <Acknowledgements data={{ productCode: info.ProductCode }} />
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <button
                onClick={() => {utms.trackEvent(11, "DM", "", Setting.productSettings.ProductId, "Flow");PixelService.event("google", "DM", "Accept Offer")}}
                  disabled={isLoading}
                  id="dm-submit-app"
                  style={{
                    backgroundColor: Setting.brandSettings.Tertiary,
                    width: "17%",
                    color: "#fff",
                  }}
                  className="btn text-small mt-3 rounded-pill"
                  type="submit"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <InvalidStateModal
        showInvalidStateModal={showInvalidStateModal}
        setShowInvalidStateModal={setShowInvalidStateModal}
      />
    </>
  );
}

export default Terms;
