import React, {useEffect} from "react";
import { useStoreState, } from "easy-peasy";
import "../../../../assets/prequal/newDesign.css";
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import FacebookPixel from 'react-fbq';

function UserTerms() {
  const text = useStoreState((state) => state.prequalText.userAgreement);
  const siteSetting = useStoreState((state) => state.siteSettingsModel);

  useEffect(() => { 
    window.scrollTo(0, 0);
    //Google Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
      ReactGA.send({ hitType: "pageview", page: "/terms" });
      ReactGA.event({category: "tracking", action: "terms",})
    }

    //Facebook Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
      FacebookPixel.initialize({id : siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")["Tag"]})
      FacebookPixel.pageView();
    }
  
    //Tiktok Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === "Tiktok")) {     
      TiktokPixel.track('View Content');
    }
  
   
// eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  return (
    <div className="container appFormsBackground">
      <div className="row userAgreementTitle">
        <div className="col-12 ">
            {text.title}
        </div>
      </div>
      <div className="row">
        <div className="col-12 userAgreementText smallerText">
          {text.userAgreementText1}
        </div>
      </div>
    </div>
  );
}

export default UserTerms;
