import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Wildcard from "../../../../services/wildcard";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ValidatorService from "../../../../services/validator";
import { masking, unmasking } from "../../../../services/masking";
import Stepers from "../components/stepers";
import AxiosWrapper from "../../../../services/axios";
import ReactGA from "react-ga4";
import FacebookPixel from "react-fbq";
import InvalidStateModal from "../../global-elements/invalidStateModal";

function Step2() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const text = useStoreState((state) => state.prequalText.step2);
  const {
    setError,
    handleSubmit,
    register,
    unregister,
    formState: { errors },
  } = useForm({ shouldFocusError: true, mode: "onSubmit" });

  const [isLoading, setIsLoading] = useState(false);
  const [backendZipError, setBackendZipError] = useState("");
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const history = useHistory();
  const validatorService = new ValidatorService();
  const statesList = useStoreState(
    (state) => state.siteSettingsModel.statesList
  );
  const wildcard = new Wildcard();
  const applicantApiClient = new AxiosWrapper();
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const siteSetting = useStoreState((state) => state.siteSettingsModel);


  useEffect(() => {
    window.scrollTo(0, 0);
    //Google Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
      ReactGA.send({ hitType: "pageview", page: "/step2" });
      ReactGA.event({category: "tracking", action: "step2",});
    }

    //Facebook Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")) {
      FacebookPixel.initialize({id: siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")["Tag"]});
      FacebookPixel.pageView();
    }

    //Tiktok Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Tiktok")) {
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  async function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      //The validation rule checks the primary phone has only numbers so we need to replace all other characters
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldName === "primaryPhone"
         // eslint-disable-next-line no-useless-escape
          ? fieldValueObject[fieldName].replace(/[\s\()-]+/g, "")
          : fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    setIsLoading(false);
    if (!foundErrors) {
      const validState = await validateState();
      if (validState) {
        history.push("/step3" + window.location.search);
      }
    }
  }

  const validateState = async () => {
    setBackendZipError("");
    setIsLoading(true);
    const response = await applicantApiClient.post("validate-state", {
      FirstName: info.FirstName,
      LastName: info.LastName,
      Address1: info.Address1,
      Address2: info.Address2,
      City: info.City,
      State: info.state,
      Zip: info.Zip,
      Product: brandSettings.ProductName,
      checkNonBusinessState: true,
    });
    setIsLoading(false);
    if (response.Result === "INVALIDSTATE") {
      setShowInvalidStateModal(true);
      return false;
    }
    if (response.Result === "MISMATCH") {
      setBackendZipError(response.Message);
      return false;
    }
    setIsLoading(false);
    return true;
  };

  useEffect(() => {
    //unregister controls to avoid validations
    let isMarriedInputs = [
      "spouseFirstName",
      "spouseMiddleInitial",
      "spouseLastName",
      "spouseSuffix",
      "spouseEmail",
      "spouseHasDifferentAddress",
    ];
    let spouseHasDifferentAddressInputs = [
      "spouseAddress1",
      "spouseState",
      "spouseCity",
      "spouseZip",
    ];

    if (info.isMarried === "false") {
      isMarriedInputs.forEach((input) => {
        unregister(input);
        setInfo({ index: input, value: "" });
      });
    }
    if (
      info.spouseHasDifferentAddress === "false" ||
      info.isMarried === "false"
    ) {
      spouseHasDifferentAddressInputs.forEach((input) => {
        unregister(input);
        setInfo({ index: input, value: "" });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [info.isMarried, info.spouseHasDifferentAddress]);

  return (
    <>
      <div className="container appFormsBackground">
        <h6 className="displayFlexCenter">
          <strong>STEP 2/5</strong>
        </h6>
        <div className="steperContainer">
          <Stepers step={2} totalSteps={5} />
        </div>
        <div className="row">
          <div className="col-12">
            <h6>
              <strong>{wildcard.proccess(text.block1.title)}</strong>
            </h6>
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="Address1">Home Address (no P.O. boxes)</label>
                <div className="input-container">
                  <input
                    maxLength={26}
                    value={info.Address1}
                    onInput={(e) =>
                      setInfo({
                        index: "Address1",
                        value: e.target.value,
                      })
                    }
                    type="text"
                    className="form-control"
                    placeholder="Home Address"
                    {...register("Address1")}
                  />
                </div>
                {errors.Address1 &&
                  errors.Address1.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </div>

              <div className="form-group">
                <label htmlFor="Address2">Address 2</label>
                <div className="input-container">
                  <input
                    maxLength={26}
                    value={info.Address2}
                    onInput={(e) =>
                      setInfo({
                        index: "Address2",
                        value: e.target.value,
                      })
                    }
                    type="text"
                    className="form-control"
                    placeholder="Address 2"
                    {...register("Address2")}
                  />
                </div>
                {errors.Address2 &&
                  errors.Address2.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </div>

              <div className="form-group">
                <label htmlFor="City">City</label>
                <div className="input-container">
                  <input
                    value={info.City}
                    onInput={(e) =>
                      {
                        if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                          if(/^[a-zA-Z ]+$/.test(e.target.value) || e.target.value === ""){
                              setInfo({ index: "City", value: e.target.value })
                          }
                        }
                      }
                    }
                    type="text"
                    className="form-control"
                    placeholder="City"
                    {...register("City")}
                  />
                </div>
                {errors.city &&
                  errors.city.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </div>

              <div className="form-group">
                <label htmlFor="state">State</label>
                <div className="input-container">
                  <select
                    className="form-control"
                    id="state"
                    onInput={(e) =>
                      setInfo({ index: "state", value: e.target.value })
                    }
                    value={info.state}
                    {...register("state")}
                  >
                    <option value=""></option>
                    {statesList.map((state) => {
                      return (
                        <option value={state.Code} key={state.Code}>
                          {state.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.state &&
                  errors.state.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </div>

              {info.state === "WI" && (
                <>
                  <div className="form-group">
                    <strong>
                      <label>Are you married?</label>
                    </strong>
                    <div className="radioGroup">
                      <div className="radio-btn">
                        <input
                          type="radio"
                          checked={info.isMarried === "true"}
                          value={"true"}
                          {...register("isMarried")}
                          onClick={(e) =>
                            setInfo({
                              index: "isMarried",
                              value: e.target.value,
                            })
                          }
                        />
                        <p>Yes</p>
                      </div>
                      <div className="radio-btn">
                        <input
                          type="radio"
                          checked={info.isMarried === "false"}
                          onClick={(e) => {
                            setInfo({
                              index: "isMarried",
                              value: e.target.value,
                            });
                          }}
                          value={"false"}
                          {...register("isMarried")}
                        />
                        <p>No</p>
                      </div>
                    </div>
                    {errors.isMarried &&
                      errors.isMarried.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>
                </>
              )}

              {info.isMarried === "true" && (
                <>
                  <label>Spouse's Information</label>
                  <div className="smallerText mb-3">{text.spouseInfo}</div>
                  <div className="form-group">
                    <label htmlFor="spouseFirstName">Spouse's First Name</label>
                    <div className="input-container">
                      <input
                       maxLength={17} 
                        value={info.spouseFirstName}
                        onInput={(e) =>
                          {
                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                              if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                  if((e.target.value.split(" ").length - 1) < 2){
                                      setInfo({ index: "spouseFirstName", value: e.target.value })
                                  }
                                }
                            }                            
                          }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's First Name"
                        {...register("spouseFirstName")}
                      />
                    </div>
                    {errors.spouseFirstName &&
                      errors.spouseFirstName.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseMiddleInitial">
                      Spouse's Middle Initial{" "}
                    </label>
                    <div className="input-container">
                      <input
                        maxLength={1} 
                        value={info.spouseMiddleInitial}
                        onInput={(e) =>
                          {
                            if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                              setInfo({ index: "spouseMiddleInitial", value: e.target.value })
                            }
                          }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's Middle Initial"
                        {...register("spouseMiddleInitial")}
                      />
                    </div>
                    {errors.spouseMiddleInitial &&
                      errors.spouseMiddleInitial.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseLastName">Spouse's Last Name </label>
                    <div className="input-container">
                      <input
                        maxLength={25}
                        value={info.spouseLastName}
                        onInput={(e) =>
                         {
                          if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                            if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                if((e.target.value.split(" ").length - 1) < 2){
                                    setInfo({ index: "spouseLastName", value: e.target.value })}
                                }
                            }
                         }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's Last Name"
                        {...register("spouseLastName")}
                      />
                    </div>
                    {errors.spouseLastName &&
                      errors.spouseLastName.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseSuffix">Spouse's Suffix</label>
                    <select
                      className="form-control"
                      id="spouseSuffix"
                      onInput={(e) =>
                        setInfo({
                          index: "spouseSuffix",
                          value: e.target.value,
                        })
                      }
                      value={info.spouseSuffix}
                      {...register("spouseSuffix")}
                    >
                      <option value=""></option>
                      <option value="JR">JR</option>
                      <option value="SR">SR</option>
                      <option value="II">II</option>
                      <option value="III">III</option>
                      <option value="IV">IV</option>
                    </select>
                  </div>
                  {errors.spouseSuffix &&
                    errors.spouseSuffix.message.map((message) => (
                      <div className="error-message"> {message} </div>
                    ))}

                  <div className="form-group">
                    <label htmlFor="spouseEmail">Spouse's Email Address </label>
                    <div className="input-container">
                      <input
                        maxLength={26}
                        value={info.spouseEmail}
                        onInput={(e) =>
                          {
                            if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                              if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                  setInfo({ index: "spouseEmail", value: e.target.value })}
                              }
                          }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's Email Address"
                        {...register("spouseEmail")}
                      />
                    </div>
                    {errors.spouseEmail &&
                      errors.spouseEmail.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <strong>
                      <label>Does your spouse have a different address?</label>
                    </strong>
                    <div className="radioGroup">
                      <div className="radio-btn">
                        <input
                          type="radio"
                          checked={info.spouseHasDifferentAddress === "true"}
                          value={"true"}
                          {...register("spouseHasDifferentAddress")}
                          onClick={(e) =>
                            setInfo({
                              index: "spouseHasDifferentAddress",
                              value: e.target.value,
                            })
                          }
                        />
                        <p>Yes</p>
                      </div>
                      <div className="radio-btn">
                        <input
                          type="radio"
                          checked={info.spouseHasDifferentAddress === "false"}
                          onClick={(e) => {
                            setInfo({
                              index: "spouseHasDifferentAddress",
                              value: e.target.value,
                            });
                          }}
                          value={"false"}
                          {...register("spouseHasDifferentAddress")}
                        />
                        <p>No</p>
                      </div>
                    </div>
                    {errors.spouseHasDifferentAddress &&
                      errors.spouseHasDifferentAddress.message.map(
                        (message) => (
                          <div className="error-message"> {message} </div>
                        )
                      )}
                  </div>
                </>
              )}

              {info.spouseHasDifferentAddress === "true" && (
                <>
                  <div className="form-group">
                    <label htmlFor="spouseAddress1">
                      Spouse's Address (no P.O. boxes)
                    </label>
                    <div className="input-container">
                      <input
                        maxLength={26}
                        value={info.spouseAddress1}
                        onInput={(e) =>
                          setInfo({
                            index: "spouseAddress1",
                            value: e.target.value,
                          })
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's Address"
                        {...register("spouseAddress1")}
                      />
                    </div>
                    {errors.spouseAddress1 &&
                      errors.spouseAddress1.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseCity">Spouse's City</label>
                    <div className="input-container">
                      <input
                      maxLength={18} 
                        value={info.spouseCity}
                        onInput={(e) =>
                          {
                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                              if(/^[a-zA-Z ]+$/.test(e.target.value) || e.target.value === ""){
                                  setInfo({ index: "spouseCity", value: e.target.value })}
                              }
                          }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's City"
                        {...register("spouseCity")}
                      />
                    </div>
                    {errors.spouseCity &&
                      errors.spouseCity.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseState">Spouse's State</label>
                    <div className="input-container">
                      <select
                        className="form-control"
                        id="spouseState"
                        onInput={(e) =>
                          setInfo({
                            index: "spouseState",
                            value: e.target.value,
                          })
                        }
                        value={info.spouseState}
                        {...register("spouseState")}
                      >
                        <option value=""></option>
                        {statesList.map((state) => {
                          return (
                            <option value={state.Code} key={state.Code}>
                              {state.Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {errors.spouseState &&
                      errors.spouseState.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                  </div>

                  <div className="form-group">
                    <label htmlFor="spouseZip">Spouse's Zip Code</label>
                    <div className="input-container">
                      <input
                       maxLength={9} 
                        value={info.spouseZip}
                        onInput={(e) =>
                          {
                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
                                    setInfo({ index: "spouseZip", value: e.target.value })}
                                }
                          }
                        }
                        type="text"
                        className="form-control"
                        placeholder="Spouse's Zip Code"
                        {...register("spouseZip")}
                      />
                    </div>
                    {errors.spouseZip &&
                      errors.spouseZip.message.map((message) => (
                        <div className="error-message"> {message} </div>
                      ))}
                    {backendZipError !== "" && (
                      <div className="error-message"> {backendZipError} </div>
                    )}
                  </div>
                </>
              )}

              <div className="form-group">
                <label htmlFor="zipcode">Zip Code</label>
                <div className="input-container">
                  <input
                    maxLength={9}
                    value={info.zip}
                    onInput={(e) =>
                      {
                        let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                        if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
                                setInfo({ index: "Zip", value: e.target.value })}
                            }
                      }
                    }
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    {...register("zip")}
                  />
                </div>
                {errors.zip &&
                  errors.zip.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
                {backendZipError !== "" && (
                  <div className="error-message"> {backendZipError} </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="primaryphone">
                  Primary phone number (Mobile preferred, 10-digits)
                </label>
                <div className="input-container">
                  <input
                    value={masking(info.primaryPhone, "(XXX) XXX-XXXX")}
                    onInput={(e) =>
                     {
                      let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                      if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                          setInfo({ index: "primaryPhone", value: unmasking(e, "(XXX) XXX-XXXX", info.primaryPhone) })
                      }
                     }
                    }
                    type="text"
                    className="form-control"
                    placeholder="(999) 999-9999"
                    {...register("primaryPhone")}
                  />
                </div>
                {errors.primaryPhone &&
                  errors.primaryPhone.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="container disclaimerContainer">
            <div className="smallerText">{text.disclaimer.line1}</div>
            <div className="smallerText">{text.disclaimer.line2}</div>
          </div>
          <div className="buttonGroup">
            <div className="buttonContainer">
              <Button
                bsPrefix="grey continueButton"
                id="btn-back"
                className="btn"
                onClick={() => {
                  history.push("/step1" + window.location.search);
                }}
              >
                Back
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                bsPrefix="mastercardOrangeBg continueButton"
                disabled={isLoading}
                id="btn-see-offers"
                className="btn"
                type="submit"
                onClick={() => {}}
              >
                {isLoading ? "Submitting..." : "Continue"}
              </Button>
            </div>
          </div>
        </form>
        <InvalidStateModal
          showInvalidStateModal={showInvalidStateModal}
          setShowInvalidStateModal={setShowInvalidStateModal}
        />
      </div>
    </>
  );
}

export default Step2;
