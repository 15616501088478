import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useFormContext } from "react-hook-form";

function ConcentSms() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
   
    const { register } = useFormContext();



    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="form-check check-fields">
                        <div className="input-container">
                            <input  onClick={() => { setInfo({index : "SMSConsent", value : info.SMSConsent ? false : true}) }} className="form-check-input" type="checkbox" value={info.SMSConsent} {...register("SMSConsent")} id="SMSConsent" />
                            <label className="form-check-label pt-0">
                                I AGREE to receive text messages about my {Setting.brandSettings.ProductName} Mastercard account. Message frequency will vary, and message and data rates may apply. Providing your consent
                                is not required to obtain an account from us. To opt-out at any time, reply STOP to any message received. For assintance, reply HELP to any message received, or call 1-866-449-4514.
                                Review the <a href={Setting.productSettings.OmpsUrl} target="_blank"  rel="noopener noreferrer">Online Mobile Privacy Statement</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConcentSms;