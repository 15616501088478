import React from 'react';
import { useStoreState } from 'easy-peasy';


function PendFee() {

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const applicant = useStoreState(state => state.applicantModel.applicant);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2>
                        Congratulations!
                    </h2>
                    <h2>
                        You are approved for the {brandSettings.ProductName.toUpperCase()} Mastercard® Credit Card.
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <img src={brandSettings.CardImage} alt="" className="col-lg-12" />
                </div>
                <div className="col-lg-6 result-message pendfee">
                    <div className="col-lg-12"><b>Approved Applicant:</b>&nbsp;{applicant.firstName}&nbsp;{applicant.lastName}</div>
                    <div className="col-lg-12"><b>Your Reference Number:</b>&nbsp;{applicationResult.referenceNumber}</div>
                    <div className="col-lg-12"><b>Credit Limit:</b>&nbsp;${applicationResult.credit_limit}</div>
                    <div className="col-lg-12"><b>Processing Fee:</b>&nbsp;${applicationResult.processingFee}</div>
                    <div className="col-lg-12">
                        In order to activate and use your new credit card when it arrives, you will need to submit payment of your ${applicationResult.processingFee} processing 
                        fee. Your account can not be opened or used until this payment is complete. If you would prefer to pay this fee over the
                        phone, please call us at 1-888-525-3038. If you would prefer to make this payment online please select one of the payment options
                        below. Thank you and congratulations!
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <p>Are you ready to make your processing fee payment online now?</p>
                </div>
                <div className="col-lg-6">
                    <button className="btn col-lg-12" style={{ backgroundColor: brandSettings.Primary }}>YES</button>
                </div>
                <div className="col-lg-6">
                    <button className="btn col-lg-12" style={{ backgroundColor: brandSettings.Primary }}>NO</button>
                </div>
            </div>
        </div>

    );

}

export default PendFee;