import React, { useEffect } from "react";
import "../../../assets/TimeoutPage.css";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useStoreState } from "easy-peasy";

const Timeout = () => {

  const timeout = useStoreState(state => state.timeout);
  const history = useHistory()

  useEffect(() => {
    if(timeout === false){
      history.push("/" + window.location.search)
    }
  })
  return (
    <>
      <div className="timeout-page">
        <h1 className="timeout-text">Your session timed out.</h1>
        <p className="timeout-text">
            Thanks for your interest in Continental Finance credit cards.
            Your session timed out so we ended it for your protection.
            Please visit <Link to="/">Continental Finance Company</Link> to
            start again. If we can help you in any way, please contact us.
        </p>

      </div>
    </>
  );
};

export default Timeout;
