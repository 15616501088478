import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ValidatorService from "../../../../services/validator";
import Stepers from "../components/stepers";
import ReactGA from 'react-ga4';
import FacebookPixel from 'react-fbq';
import "../../../../assets/prequal/newDesign.css";

function Step1() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const siteSetting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.prequalText.step1);
  const { setError, handleSubmit, register, formState: { errors }} = useForm({ shouldFocusError: true, mode: "onSubmit" });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const validatorService = new ValidatorService();
  const learnMoreLink = "https://blog.continentalfinance.net/cant-be-identified";

  useEffect(() => { 
    window.scrollTo(0, 0);
    //Google Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
      ReactGA.send({ hitType: "pageview", page: "/step1" });
      ReactGA.event({category: "tracking", action: "step1",});
    }

    //Facebook Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
      FacebookPixel.initialize({id : siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")["Tag"]})
      FacebookPixel.pageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    setIsLoading(false);
    if (!foundErrors) {
      history.push("/step2" + window.location.search);
    }
  }

  return (
    <div className="container appFormsBackground">
      <h6 className="displayFlexCenter">
        <strong>STEP 1/5</strong>
      </h6>
      <div className="steperContainer">
        <Stepers step={1} totalSteps={5} />
      </div>
      <div className="row">
        <div className="col-12">
          <h6>
            <strong>{text.block1.title}</strong>
          </h6>
          <p>
            {text.block1.subtitle} <br />{" "}
            <a href={learnMoreLink}  rel="noopener noreferrer" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="FirstName">First Name</label>
              <div className="input-container">
                <input
                  maxLength={17}
                  value={info.FirstName}
                  onInput={(e) =>{
                      if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                        if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                            if((e.target.value.split(" ").length - 1) < 2){
                                setInfo({ index: "FirstName", value: e.target.value.trimLeft() })
                            }
                        }
                      }
                    }
                  }
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  {...register("FirstName")}
                />
              </div>
              {errors.firstName &&
                errors.firstName.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="MiddleName">Middle Initial </label>
              <div className="input-container">
                <input
                  maxLength={1} 
                  value={info.MiddleName}
                  onInput={(e) =>{
                    if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                      setInfo({ index: "MiddleName", value: e.target.value })
                    }
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Middle Initial"
                  {...register("MiddleName")}
                />
              </div>
              {errors.MiddleName &&
                errors.MiddleName.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="LastName"> Last Name </label>
              <div className="input-container">
                <input
                  maxLength={25}
                  value={info.LastName}
                  onInput={(e) =>
                    {if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                      if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                          if((e.target.value.split(" ").length - 1) < 2){
                              setInfo({ index: "LastName", value: e.target.value.trimLeft() })
                          }
                        }
                      }
                    }
                  }
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  {...register("LastName")}
                />
              </div>
              {errors.LastName &&
                errors.LastName.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="Suffix">Suffix</label>
              <div className="input-container">
                <select
                  className="form-control"
                  id="addtl-ch-suffix"
                  onInput={(e) =>
                    setInfo({ index: "Suffix", value: e.target.value })
                  }
                  value={info.suffix}
                  {...register("Suffix")}
                >
                  <option value=""></option>
                  <option value="JR">JR</option>
                  <option value="SR">SR</option>
                  <option value="II">II</option>
                  <option value="III">III</option>
                  <option value="IV">IV</option>
                </select>
              </div>
              {errors.Suffix &&
                errors.Suffix.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="Email"> Email Address </label>
              <div className="input-container">
                <input
                  maxLength={100}
                  value={info.Email}
                  onInput={(e) =>
                    {
                      if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                          if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                              setInfo({ index: "Email", value: e.target.value})
                          }
                          
                      }
                    }
                  }
                  type="text"
                  className="form-control"
                  placeholder="Email Address"
                  {...register("Email")}
                />
              </div>
              {errors.Email &&
                errors.Email.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>
          </div>
        </div>

        <div className="buttonGroup">
          <div className="buttonContainer">
            <Button
              bsPrefix="grey continueButton"
              id="btn-back"
              className="btn"
              onClick={() => {
                history.push("/" + window.location.search);
              }}
            >
              Back
            </Button>
          </div>

          <div className="buttonContainer">
            <Button
              bsPrefix="mastercardOrangeBg continueButton"
              disabled={isLoading}
              id="btn-see-offers"
              className="btn"
              type="submit"
              onClick={() => {}}
            >
              {isLoading ? "Submitting..." : "Continue"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step1;
