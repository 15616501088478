import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import ApplicantDisclosures from '../../terms/applicantdisclosure';
import CreditProtectionDisclosures from '../../terms/creditprotectiondisclosures';
import CreditProtection from '../../terms/creditprotection';
import Acknowledgements from '../../terms/acknowledgements';
import { ErrorAlert } from '../components/errorAlert/ErrorAlert';
import ErrorModal from '../components/ErrorModal';
import Timeout from '../../add-ons/timeout';
import '../../../../assets/prequal/prequalterms.css';
import AxiosWrapper from '../../../../services/axios';
import marketing from "../../../../services/marketing";
import PixelsService from '../../../../services/pixels';

function PrequalTerms({ basePath }) {
    const history = useHistory();
    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const assetsBaseUrl = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const applicantData = useStoreState(state => state.applicantModel.applicant);
    const cardColors = useStoreState(state => state.siteSettingsModel.CardColors);
    const setCardColors = useStoreActions(action => action.siteSettingsModel.setCardColor);
    const resultStep = useStoreActions(actions => actions.setDisplayResult);
    const termsPixels = useStoreState(state => state.termsPixels);
    const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setApplicationResultOffer = useStoreActions(actions => actions.applicationResultOffer.setApplicationResultOffer);
    const [showAlert, setShowAlert] = useState(false);
    const methods = useForm({ shouldFocusError: false });
    const setApplicantData = useStoreActions(actions => actions.applicantModel.setApplicant);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const utms = new marketing();
    const setting = useStoreState((state) => state.siteSettingsModel);
    const setInfo = useStoreActions((actions) => actions.applicantModel.setApplicant);
    const PixelService = new PixelsService();
    // Error modal
    const [errorShow, setErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const ApplicantApiClient = new AxiosWrapper();
    const closeError = () => setErrorShow(false);
    const isMounted = useRef(true);
    const cpRef = useRef(null);
    const tcRef = useRef(null);
    const utm = new marketing();
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    
    
    useEffect(() => {      
      
        if (!termsPixels) {
            PixelService.pageView("/terms").event("google","tracking","Terms").event("tiktok", "-", "ClickButton");
            
            if(Flow === "PREQUAL"){
                utms.trackEvent(3, "Prequal", applicationResult.ref_number, productSettings.ProductId, "Flow");
            }
            getRoutingSettings({ Url: setting.domain, Route: window.location.pathname});
            setTermsPixel(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const submitApp = async (data) => {
        setDisableButton(true)
        if(cardColors.length > 0 && applicantData.cardColorCode === ""){
            setApplicantData({ index: "cardColorCode", value: cardColors[0].Code });
        }

        utms.trackEvent(5, "Prequal", applicationResult.ref_number, productSettings.ProductId, "Flow");
        PixelService.event("google", "tracking", "ClickAcceptMyOfferButton").event("Pinterest", "custom","ClickAcceptMyOfferButton");
            
        let submitApplicationOfferPayload = {
            ApplicationId: applicationResult.ref_number, // should come from submitApplication result
            Initials: "", //leave this blank
            CreditProtection: data.creditProtection === "yes" ? true : false,
            CardColorCode: applicantData.cardColorCode, // leave this blank for prequal reflex
            HasCardColorChoice: mainSettings.HasCardColorChoice,
            AgreeAcknowledgements: data.tcAcknowledgement,
            SiteId: "https://" + window.location.hostname,
        }
        const applicantResult = await ApplicantApiClient.post('submit-application-offer-de20', submitApplicationOfferPayload);
        const { decision } = applicantResult;
        setInfo({ index: "step", value: 3 });
        setDisableButton(false)
        if (applicantResult) {
            utm.updateSessionReport("appResultOffer", decision)
            if (decision === "APPROVED") {
                window.scrollTo(0, 0);
                resultStep(true)
                setApplicationResult({ result: "APPROVED" });
                history.push(`/approved`+window.location.search);
            }else if(decision === "SYS_ERROR"){
                window.scrollTo(0, 0);
                resultStep(true)
                history.push(`/pendinfo`+window.location.search);
                setApplicationResultOffer({ result: "SYS_ERROR" });
            }
            else {
                setApplicationResult({ result: "DECLINED" });
                setErrorMessage("An unknown error has occured. Please try again.");
                setErrorShow(true);
            }
        }
        else {
            setErrorMessage("An unknown error has occured. Please try again.");
            setErrorShow(true);
        }
       
    }
   
    useEffect(() => {
        if (isMounted.current && cardColors.length === 0) {
            ApplicantApiClient.post("get-card-color-choice/",{ productId : setting.productSettings.ProductId , productCode : applicationResult.product_code }).then((payload) => {
                if (payload) {
                    if (payload.length > 0) {
                        setCardColors(payload);
                        setApplicantData({ index: "cardColorCode", value: payload[0].Code });
                    }
                }
            });
        }
        return () => { isMounted.current = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            history.goForward(); //prevent going backwards
        }
        return () => { isMounted.current = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.goBack]);

    useEffect(() => {
        if (isMounted.current) {
            (Object.entries(methods.formState.errors).length === 0) ? setShowAlert(false) : setShowAlert(true);
        }
        return () => { isMounted.current = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.formState]);

    const errorHandler = () => {
        if (methods.formState.errors.creditProtection !== undefined) {
            cpRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            tcRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

   

    return (
        <FormProvider {...methods}>
            <form
                id="prequal-terms-container"
                className="userform shaded"
                onSubmit={methods.handleSubmit(submitApp, errorHandler)}
            >
                <div className="ribbon" style={{ backgroundColor: brandSettings.Primary }}>
                    <h1 className='mb-0'>You are Pre-Approved! To Get Your Card ...</h1>
                </div>
                <div className='container step-process'>
                    <div className="row">
                        <div className="col-lg-12">
                            <h5>Congratulations! You are just 3 Steps Away From Getting Your New Card</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <img alt="" src={assetsBaseUrl.CdnImage + "Common/step01.png"} />
                        </div>
                        <div className="col-lg-4">
                            <img alt="" src={assetsBaseUrl.CdnImage + "Common/step02.png"} />
                        </div>
                        <div className="col-lg-4">
                            <img alt="" src={assetsBaseUrl.CdnImage + "Common/step03.png"} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12">
                            <button
                                type="button"
                                onClick={() => document.getElementById("accept-card-anchor").focus()}
                                className="btn"
                                style={{ backgroundColor: brandSettings.Secondary }}
                            >ACCEPT YOUR CARD</button>
                        </div>
                    </div>
                </div>
                <div className="container whitebg terms-wrapper">
                    <ErrorModal size='sm' show={errorShow} message={errorMessage} closeError={closeError} />
                    <div className="row">
                        <div className="col-lg-12">
                            <p>
                                You have been Pre-Approved for a {brandSettings.ProductName} Mastercard® with an initial credit limit of ${applicationResult.credit_limit}.
                            </p>
                            <p><b>To complete your application, you must review the information below and press submit.</b></p>
                            <p>
                                -&nbsp;&nbsp;&nbsp;Applicant Disclosures <br />
                                {mainSettings.Flow.toUpperCase() === "PREQUAL" && productSettings.ProductId === 1 ? <>-&nbsp;&nbsp;&nbsp;Choose your card design, either standard or premium <br /></>: <></>}
			                    -&nbsp;&nbsp;&nbsp;Optional Continental Credit Protection
		                    </p>
                            <p>
                                Then agree to the Acknowledgements and click the “Click here
                                to sign and submit your application” below to complete your
                                application.
                            </p>
                        </div>
                    </div>
                    <div className="row mtop-30">
                        <div className="col-lg-12">
                            <h4>Applicant Disclosures</h4>
                        </div>
                    </div>
                    <div id="applicant-disclosures" className="terms-section-wrapper" tabindex="0">
                        <ApplicantDisclosures />
                    </div>
                    <div id="accept-card-anchor" tabindex="1"></div>
                    {cardColors.length > 0 ?
                        <div className="row mt-2 mb-2">
                            <div className='col-12 text-center' style={{ backgroundColor: "black", padding: "1% 2%" }}>
                                <h1 className='mb-0' style={{ color: "white" }}>Choose Your Card!</h1>
                            </div>
                            {cardColors.map((card) => {
                                return (
                                    <div className='col-6 p-3 mb-3'>
                                        <div className='row'>
                                            <div className="col-12" style={{ paddingLeft: "16%" }}>
                                                <div className="form-check">
                                                    <input
                                                        checked={applicantData.cardColorCode === card.Code ? true : false}
                                                        onClick={() => { setApplicantData({ index: "cardColorCode", value: card.Code }) }}
                                                        className="form-check-input"
                                                        type="radio"
                                                        value={card.Code}
                                                        name="CardColorCode"
                                                        id={"cardRadio-" + card.Code}
                                                    />
                                                    <label className="form-check-label color-prices" htmlFor={"cardRadio-" + card.Code}>
                                                        {card.Code} - {card.DisplayName}{
                                                            parseInt(card.Fee) === 0 ? <>- <strong>No Fee</strong></> : <>, <strong>${card.Fee}</strong></>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row text-center mt-3'>
                                            <img
                                                alt="" src={card.ColorCardFileName}
                                                style={{ width: "70%", textAlign: "center", margin: "auto" }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    : <></>}
                    <div className="row mtop-30">
                        <div className="col-lg-12">
                            <div className="input-container">
                                <h1>Help Protect Your Credit:</h1>

                            </div>
                        </div>
                    </div>
                    <div className="terms-section-wrapper" ref={cpRef}>
                        <CreditProtection valid={methods.formState.errors.creditProtection} />
                    </div>
                    <div className="row mtop-30">
                        <div className="col-lg-12">
                            <h1>Optional Continental Credit Protection Important Disclosures</h1>
                        </div>
                    </div>
                    <div className="terms-section-wrapper">
                        <CreditProtectionDisclosures />
                    </div>
                    <div className="row mtop-30">
                        <div className="col-lg-12">
                            <div className="input-container">
                                <h1>Acknowledgements</h1>
                                <h5>YOU MUST INDICATE YOU'VE READ AND CONSENT TO THE BELOW STATEMENTS:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="terms-section-wrapper" ref={tcRef}>
                        <div className='row'>
                           
                        </div>
                        <Acknowledgements />
                    </div>
                    <div className="row mtop-30 text-center">
                        <div className="col-lg-12">
                            {showAlert && <ErrorAlert show={errorShow} />}
                            <button
                                disabled={disableButton}
                                id="btn-submit-application"
                                className="btn"
                                style={{ backgroundColor: brandSettings.Secondary, width: "90%" }}
                                onClick={() => { utm.updateSessionReport("acceptOfferButton");  }}
                            >Click here to sign and submit your application »</button>
                        </div>
                    </div>
                </div>
            </form >
            <Timeout />
        </FormProvider>
    )
}

export default PrequalTerms;