import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import ValidatorService from "../../../../services/validator";
import AxiosWrapper from "../../../../services/axios";
import Wildcard from "../../../../services/wildcard";
import Parser from "react-html-parser";
import marketing from "../../../../services/marketing";
import ReactGA from "react-ga4";
import FacebookPixel from "react-fbq";
import applicantModel from "../../../../models/applicantModel";

function Step6Terms() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState(
    (state) => state.applicationResultModel.applicationResult
  );
  const setApplicationResult = useStoreActions(
    (actions) => actions.applicationResultModel.setApplicationResult
  );
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );

  const text = useStoreState((state) => state.prequalText.termsStep);
  const assetsBaseUrl = useStoreState(
    (state) => state.siteSettingsModel.webAssetsBaseUrlSettings
  );
  const ApplicantApiClient = new AxiosWrapper();
  const cardColors = useStoreState(
    (state) => state.siteSettingsModel.CardColors
  );


  const setCardColors = useStoreActions(
    (action) => action.siteSettingsModel.setCardColor
  );
  const applicantData = useStoreState(
    (state) => state.applicantModel.applicant
  );
  const setApplicantData = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const setCompleteApplicant = useStoreActions(
    (actions) => actions.applicantModel.setCompleteApplicant
  );
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const activeCheck = useStoreState((state) => state.siteSettingsModel.activateChecks); 

  const {
    setError,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ shouldFocusError: true, mode: "onSubmit" });

  const [isLoading, setIsLoading] = useState(false);
  const [generalAcknowledgement, setGeneralAcknowledgement] = useState(false);
  const [backendError, setBackendError] = useState("");
  const history = useHistory();
  const siteSetting = useStoreState((state) => state.siteSettingsModel);
  const validatorService = new ValidatorService();
  const wildcard = new Wildcard();
  const isMounted = useRef(true);
  const methods = useForm({ shouldFocusError: false });
  const utm = new marketing();

  useEffect(() => {
    window.scrollTo(0, 0);
    //Google Pixels
    if (
      siteSetting.Pixels.find(
        (Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG
      )
    ) {
      ReactGA.send({ hitType: "pageview", page: "/step6terms" });
      ReactGA.event({category: "tracking", action: "Terms",});
    }

    //Facebook Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")) {
      FacebookPixel.initialize({
        id: siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")[
          "Tag"
        ],
      });
      FacebookPixel.pageView();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!applicationResult.credit_limit) {
      history.push("/" + window.location.search);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      setCompleteApplicant(applicantModel.applicant);
      event.preventDefault();
      history.push("/step1" + window.location.search);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted.current && cardColors.length < 1) {
      ApplicantApiClient.post(
        "get-card-color-choice/",{ productId : siteSetting.productSettings.ProductId , productCode : applicantData.ProductCode === "" ? applicationResult.product_code : applicantData.ProductCode }
      ).then((payload) => {
        if (payload) {
          if (payload.length > 0) {
            setCardColors(payload);
            setApplicantData({
              index: "cardColorCode",
              value: payload[0].Code,
            });
          }
        }
      });
    }
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    if (!foundErrors) {
      submitApp(fieldValueObject);
    }
    setIsLoading(false);
  }

  const submitApp = async (data) => {
    let submitApplicationOfferPayload = {
      ApplicationId: applicationResult.ref_number, // should come from submitAPplication result
      Initials: "", //leave this blank
      CreditProtection: data.creditProtection,
      CardColorCode: applicantData.cardColorCode, // leave this blank for prequal reflex
    };
    const applicantResult = await ApplicantApiClient.post(
      "submit-application-offer",
      submitApplicationOfferPayload
    );
    const { decision } = applicantResult;
    if (applicantResult) {
      utm.updateSessionReport("appResultOffer", decision);
      if (decision === "APPROVED") {
        window.scrollTo(0, 0);
        resultStep(true);
        history.push(`/approved` + window.location.search);
      } else if (decision === "DECLINED") {
        window.scrollTo(0, 0);
        resultStep(true);
        history.push(`/declined` + window.location.search);
      } else {
        setApplicationResult({ result: "DECLINED" });
        setBackendError("An unknown error has occured. Please try again.");
      }
    } else {
      setBackendError("An unknown error has occured. Please try again.");
    }
  };

 

  return (
    <div className="container appFormsBackground terms">
      <div className="">
        <div className="preApprovedTitle">{text.preApproved.title}</div>
        <div className="infoParagraph">
          <div>
            {text.preApproved.informationPreAmount}{" "}
            <span className="preApprovedCreditAmount">
              ${applicationResult.credit_limit}
            </span>
            .
          </div>
          <div>{text.preApproved.informationPostAmount}</div>
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          id="prequal-terms-container"
          className="userform shaded"
          onSubmit={handleSubmit(submit)}
        >
          <div style={{ marginTop: "1em" }}>
            <div className="title">{text.applicantDisclosures.title}</div>
            <div
              className="shumerBox"
              onClick={ () => {
                localStorage.setItem("TCProductCode", applicationResult.product_code); 
                window.open("/tc?"+ window.location.search, "_blank")
               
              }
               
              }
            >
              <div className="shumerBoxTextMain">
                {text.applicantDisclosures.shumerBoxText}
              </div>
              <div className="shumerBoxText">Click for more information</div>
            </div>
            <div className="creditProtectionActionContainer">
              <div className="inputContainer">
                
              </div>

              <div className="creditProtectionText">                
                {Parser(wildcard.proccess(text.applicantDisclosures.checkboxPreDescription))}
                <a href="/tc" onClick={ localStorage.setItem("TCProductCode", applicationResult.product_code)} target="_blank" rel="noopener noreferrer">{Parser(wildcard.proccess(text.applicantDisclosures.linkText))}</a>
                {Parser(wildcard.proccess(text.applicantDisclosures.checkboxPostDescription))}
              </div>
            </div>
            {errors.tcAcknowledgement &&
              errors.tcAcknowledgement.message.map((message) => (
                <div className="error-message"> {message} </div>
              ))}
          </div>

          {cardColors.length > 0 ? (
            <div style={{ marginTop: "1em" }}>
              <div className="title">Choose Your Card!</div>
              <div className="cardsContainer">
                {cardColors.map((card) => {
                  return (
                    <div
                      className="singleCardContainer"
                      key={card.Code}
                      onClick={() => {
                        setApplicantData({
                          index: "cardColorCode",
                          value: card.Code,
                        });
                      }}
                    >
                      <label className="" htmlFor={"cardRadio-" + card.Code}>
                        <div className="cardName">
                          {card.Code} - {card.DisplayName}
                        </div>
                        <div>
                          {parseInt(card.Fee) === 0 ? (
                            <>
                              <strong>No Fee</strong>
                            </>
                          ) : (
                            <>
                              <strong>${card.Fee}</strong>
                            </>
                          )}
                        </div>
                      </label>
                      <img
                        alt=""
                        src={card.ColorCardFileName}
                        className="cardImage"
                      />
                      <input
                        checked={
                          applicantData.cardColorCode === card.Code
                            ? true
                            : false
                        }
                        className=""
                        type="radio"
                        value={card.Code}
                        name="CardColorCode"
                        id={"cardRadio-" + card.Code}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="" style={{ marginTop: "1em" }}>
            <div className="creditProtectionTitle">
              <img
                alt=""
                src={assetsBaseUrl.CdnImage + "Common/creditprot.gif"}
                className="creditProtectionImage"
              />
              <div className="title">{text.helpProtect.title}</div>
            </div>
            <div className="creditProtectionBox">
              <div className="creditProtectionActionContainer">
                <div className="inputContainer">
                  <input
                    type="checkbox"
                    className="creditProtectionCheckbox"
                    checked={info.creditProtection === true}
                    {...register("creditProtection")}
                    onClick={() =>
                      setInfo({
                        index: "creditProtection",
                        value: true,
                      })
                    }
                  />
                </div>

                <div className="creditProtectionText">
                  <strong> Yes, </strong> {text.helpProtect.yesText}
                </div>
              </div>
              <div className="creditProtectionActionContainer">
                <input
                  type="checkbox"
                  className="creditProtectionCheckbox"
                  checked={info.creditProtection === false}
                  onClick={() =>
                    setInfo({
                      index: "creditProtection",
                      value: false,
                    })
                  }
                />
                <div className="creditProtectionText">
                  {" "}
                  <strong>No, thank you. </strong> {text.helpProtect.noText}
                </div>
              </div>
            </div>
          </div>

          <div className="" style={{ marginTop: "1em" }}>
            <div className="creditProtectionTitle">
              <div className="title">{text.acknowledgements.title}</div>
            </div>
            <div className="creditProtectionActionContainer">
              <div className="inputContainer">
               
              </div>

              <div className="creditProtectionText">
                {text.acknowledgements.checkboxDescription}
              </div>
            </div>
          </div>
          {
              !activeCheck && (
                  <div className='col-12 mt-3 terms-acknowledgements'>
                      <div className="form-check">
                          <input 
                              checked={info.generalAcknowledgement === "1" ? true : false} value={info.generalAcknowledgement}
                              onClick={() => { 
                                  let value =  info.generalAcknowledgement === "1" ? "0" : "1";
                                  setInfo({ index: "generalAcknowledgement", value})
                                  setInfo({ index: "eSignConsent", value })
                                  setInfo({ index: "tcAcknowledgement", value })
                                  setGeneralAcknowledgement(true)
                              }} 
                              className="form-check-input" 
                              type="checkbox" id="generalAcknowledgement" {...register("generalAcknowledgement")} 
                          />
                          <label className="form-check-label" htmlFor="generalAcknowledgement">
                              I have read and agree to the 
                              <a rel="noopener noreferrer" target="_blank" href={siteSetting.productSettings.ESignUrl}> Electronic Communications Disclosure</a>, 
                              <a rel="noopener noreferrer" href='/tc' target="_blank" onClick={(e) => {utm.updateSessionReport("disclosureLink"); localStorage.setItem("TCProductCode", applicationResult.product_code)}}> Terms and Conditions</a> and Acknowledgements. 
                          </label>
                      </div>
                      {errors.generalAcknowledgement ? 
                          <div className="error-message"> You must acknowledge before continue. </div>
                          :
                          <></>
                      }
                  </div>
                )
            }
          <div className="form-group">
            <Button
              bsPrefix="mastercardOrangeBg continueButton"
              disabled={
                !generalAcknowledgement || info.tcAcknowledgement !== "1"
              }
              id="btn-see-offers"
              className="btn"
              type="submit"
              style={{ marginTop: "1.5em" }}
            >
              {isLoading ? "Submitting..." : "Sign and Submit"}
            </Button>
            {backendError !== "" && (
              <div className="error-message"> {backendError} </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Step6Terms;
