import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { useLocation } from "react-router-dom";
import marketing from "../../services/marketing";

function AppSiteFooter() {
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const productSettings = useStoreState(
    (state) => state.siteSettingsModel.productSettings
  );
  const [customerService] = useState("tel:" + productSettings.CustomerService);
  const utm = new marketing();
  const routingSettings = useStoreState(
    (state) => state.routingSettingsModel.routingSettings
  );

  const mainSettings = useStoreState(
    (state) => state.siteSettingsModel.mainSettings
  );
  const location = useLocation();
  const applicant = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState(
    (state) => state.applicationResultModel.applicationResult
  );
  const [pCode, setPCode] = useState(mainSettings.ProductCode);
  const MainPath          = useStoreState(state => state.siteSettingsModel.MainPath);

  useEffect(() => {

    if (applicant.ProductCode || applicant.ProductCode !== "") {
      
      setPCode(applicant.ProductCode);
    }
  }, [applicant.ProductCode]);

  useEffect(() => {
    if (
      applicationResult.product_code ||
      applicationResult.product_code !== ""
    ) {
      setPCode(applicationResult.product_code);
    }
  }, [applicationResult.product_code]);

  return (
    <div className="container">
      {mainSettings.Flow === "CS" && (
        <p>
          Continental Finance Company, LLC; P.O. Box 3220 Buffalo, NY
          14240-3220
        </p>
      )}

      {location.pathname === "/" && mainSettings.Flow === "CS" && (
        <p>
          *A Pre-approved credit card offer means you meet our initial credit
          and underwriting criteria and your application may be approved for
          this Surge® Mastercard® offer if you continue to meet this criteria.
          See Rates, Fees, Costs and Limitations.
        </p>
      )}
      <p className="footer-links">
        {routingSettings.PrivacyFooter && (
          <>
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.PrivacyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}   
            >
              Privacy
            </a>
            {(routingSettings.FaqFooter || routingSettings.TermsFooter) && (
              <span> | </span>
            )}
          </>
        )}
        {routingSettings.FaqFooter && (
          <>
            <a
              onClick={(e) => {
                utm.updateSessionReport("faqLink");
              }}
              target="_blank"
              href={productSettings.FaqUrl}
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              FAQ
            </a>
            {routingSettings.TermsFooter && <span> | </span>}
          </>
        )}
        {mainSettings.Flow === "DM" ?
        routingSettings.TermsFooter ? (
          routingSettings.TermsFooterCDN ? (
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.TermsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}
            >
              Terms & Conditions
            </a>
          ) : (
            window.location.href.indexOf('/declined') > -1 ?
            <a href={productSettings.TermsUrl} target="_blank"
             className={brandSettings.ProductName === 'Reflex' ?
              "footer-links-reflex" : "footer-links"} 
              rel="noopener noreferrer">Terms & Conditions</a> :
            window.location.href.indexOf('/pendinfo') > -1 ?
             <a href={productSettings.TermsUrl} target="_blank" 
             className={brandSettings.ProductName === 'Reflex' ?
              "footer-links-reflex" : "footer-links"} 
              rel="noopener noreferrer">Terms & Conditions</a> :
            <a
              onClick={ (e) => {utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)} }
              href="/tc"
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              Terms & Conditions
            </a>
          )
        ) : null  
        :
        routingSettings.TermsFooter ? (
          routingSettings.TermsFooterCDN ? (
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.TermsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}
            >
              Terms & Conditions
            </a>
          ) : (
            <a
              onClick={ (e) => {utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)} }
              href="/tc"
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              Terms & Conditions
            </a>
          )
        ) : null}
      </p>
      
      {mainSettings.Flow !== "CS" && (
        <>
          <p>
            Continental Finance Company, LLC; P.O. Box 3220 Buffalo, NY
            14240-3220
          </p>
          <p className="footer-links">
            Have a Question? Call Customer Service at{" "}
            <a 
              href={customerService}   
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}  
            >
              1-866-449-4514
            </a>
          </p>
        </>
      )}
      <p>
        The {
          (brandSettings.ProductName.toLowerCase() === 'revel' || brandSettings.ProductName.toLowerCase() === 'fit') ? `${brandSettings.ProductName}™`
          : `${brandSettings.ProductName}®`
        } Mastercard® Credit Card is issued by
        {brandSettings.BankName === "Celtic Bank"
          ? " Celtic Bank, a Utah-Chartered Industrial Bank, "
          : " The Bank of Missouri, "}
        Member FDIC under license from Mastercard International and serviced by
        Continental Finance Company, LLC.
      </p>
      <p>
        Obtaining Your Card: The USA PATRIOT Act is a federal law that requires
        all financial institutions to obtain, verify and record information that
        identifies each person who opens a Card Account. What this means for
        you: When you open a Card Account, we will ask for your name, address,
        date of birth and other information that will allow us to reasonably
        identify you. We may also ask to see your driver's license or other
        identifying documents at any time.
      </p>
      <p><small>©{new Date().getFullYear()} Continental Finance LLC All rights reserved.</small></p>
      <p className="mb-0">
        <img alt="" src={brandSettings.FooterLogo} />
      </p>
      <p className="text-right mb-0">
        <small>
          {
            (location.pathname+"/") === ("/"+MainPath) ?
            <>
              Version {process.env.REACT_APP_VERSION || "local"}
            </>
            : 
            <></>
          }
       
        </small>
      </p>
    </div>
  );
}
export default AppSiteFooter;