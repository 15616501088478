import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import {  useHistory } from "react-router-dom";
import Approved from '../../flow/global-elements/approved2';
import PendFee from '../resultpage/pendfee';
import PendInfo from '../resultpage/pendinfo';
import Pendfunding from '../resultpage/pendfunding';
import PendInternal from '../resultpage/pendinternal';
import Declined2 from '../../flow/global-elements/declined';
import DeclinedTemporal from '../../flow/global-elements/declinedTemporal';
import '../../../assets/resultPage/resultpage.css';

function Result({basePath}) {
    const history = useHistory();
    const validStep = useStoreState(state => state.displayResult);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const applicationResultOffer = useStoreState(state => state.applicationResultOffer.applicationResultOffer);
    const applicantResult= applicationResultOffer.result === '' ? applicationResult.result.toUpperCase() : applicationResultOffer.result.toUpperCase();
    const pendingInfo =  ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];

    useEffect(() => {
        if(validStep === false){
            window.scrollTo(0, 0);
            history.push("/")
        }
    })

 
   

    const putComponent = () =>{
        if (pendingInfo.includes(applicantResult)) {
            return <DeclinedTemporal />
        }
        
        switch (applicantResult) {
            case "APPROVED": //unsecured              
                return <Approved />
            case "PENDFEE":
                return <PendFee />
            case "PENDFUNDING": //secured
                return <Pendfunding />
            case "DECLINED":
                return <Declined2 /> ;
            case "PENDINFO":
                return <PendInfo />
            case "PENDINTERNAL":
                return <PendInternal />     
            case "SYS_ERROR":
                return (
                    <>
                        <h3 className="text-center mt-5 mb-5">We are currently processing your application and you will receive a decision within 30 days.</h3>
                    </>
                )     
            default:
                return <Declined2 />
        }
    }

    return (
        <div className="resultpage">
            
            {
                putComponent()
            }

        </div>
    );
};

export default Result;
