import React from 'react';
import { useStoreState } from 'easy-peasy';

function PendInternal() {
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);

    const assetsLink = useStoreState(state => state.assetsLink);

    const findRightCardDeclined = assetsLink + "appsites/findrightcard_declined.png";

    const redirectLink = "https://ct.buyright.com/rd/r.php?sid=" + applicationResult.sid + "&pub=" + applicationResult.pub + "&c1=" +
        brandSettings.ProductName.toUpperCase() + "&c2=" + applicationResult.referenceNumber + "&c3=" + applicationResult.c3;

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h4>Reference #: {applicationResult.referenceNumber}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h1>Thank you for applying...</h1>
                    <p>Thank you for applying for the {brandSettings.ProductName} credit card issued by Celtic Bank and serviced by Continental Finance Company.</p>
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-6"><img className="col-lg-12" src={brandSettings.CardImage} alt={brandSettings.ProductName} /></div>
                <div className="col-lg-3"></div>
            </div>

            <div className="row">
                <div className="col-lg-12 text-center">
                    <a href={redirectLink}><img src={findRightCardDeclined} alt="" /></a>
                </div>
            </div>

            <div className="row reminder text-center">
                <h3 className="col-lg-12">Unfortunately we are unable to make an immediate decision on your application. We will notify you by email within 30 days of our decision.</h3>
                <br/>
                <div className="col-lg-12">
                    Please check your email for a decision letter from noreply@your{brandSettings.ProductName}card.com. You may need to review your junk e-mail for this message.
                </div>
                <br />
                <br/>
                <div className="col-lg-12">
                    <small>
                        {applicationResult.bankDisclosure}
                    </small>
                </div>
            </div>
        </div>
    );


}
export default PendInternal;