import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import marketing from "../../../../services/marketing";
import "../../../../assets/terms/dm-terms.css";
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import { useEffect } from "react";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PixelsService from '../../../../services/pixels';

function Terms() {
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const setGrandBanckDeclined = useStoreActions((actions) => actions.siteSettingsModel.setGrandBanckDeclined);
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const text = useStoreState((state) => state.dmText.terms);
  const setApplicationResult = useStoreActions((actions) => actions.applicationResultModel.setApplicationResult);
  const pendingInfo =  ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const history = useHistory();
  const wildcard = new Wildcard();
  const client = new AxiosWrapper();
  const validatorService = new ValidatorService();
  const methods = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const utms = new marketing();
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const closeError = () => setErrorShow(false);
  const [showAdditionalCardHolderForm, setShowAdditionalCardHolderForm] =useState(false);
  const termsPixels = useStoreState(state => state.termsPixels);
  const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const PixelService = new PixelsService();
  const { register, formState: { errors } } = useForm();



  useEffect(() => {
    if (!termsPixels) {
      PixelService.pageView("/Terms").event("google","CS","Terms");
      getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
      setTermsPixel(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const submit = async (data) => {
    setLoading(true);
    if (!validatorService.preValidations(data, methods, info)) {
        await submitApplication();
    }
    setLoading(false);
  };

  async function submitApplication() {
    let applicantApiData = {
      Applicant: {
        FirstName: info.FirstName,
        MiddleInitial: info.MiddleName,
        LastName: info.LastName,
        Suffix: info.Suffix,
        Address1: info.Address1,
        Address2: info.Address2,
        City: info.City,
        State: info.State,
        Zip: info.Zip,
        Email: info.Email,
        PrimaryPhone: info.MobileNo,
        SecondaryPhone: info.SecondaryPhone,
        DOB: info.DOB,
        SSN: info.ssn,
        MonthlyIncome: info.MonthlyIncome,
        IncomeSource: info.incomeSource || "",
        HasCheckingAccount: info.hasCheckingAccount ,
        cashIntent: info.cashIntent,
        ReservationCode: info.ReservationCode ? info.ReservationCode : "",
      },
      Terms: {
        ESignConsent: info.eSignConsent === "1" ? true : false,
        CreditProtection: info.creditProtection === "yes" ? true : false,
        TcAcknowledgement: info.tcAcknowledgement === "1" ? true : false,
        EmailOptIn: true,
        CardColorCode: info.cardColorCode,
        SMSConsent : info.SMSConsent
      },
      AdditionalCardholder: {
        AdditionalCardholder: info.hasAdditionalCardholder === "true" ? true : false,
        AdditionalCardholderFirstName: info.additionalCardholderFirstName,
        AdditionalCardholderMI:info.additionalCardholderMI,
        AdditionalCardholderLastName:info.additionalCardholderLastName,
        AdditionalCardholderSuffix: info.additionalCardholderSuffix,
      },
      SpouseInformation: {
        SpouseHasDifferentAddress: info.spouseHasDifferentAddress || "false",
        SpouseFirstName: info.spouseFirstName || "",
        SpouseMiddleName: info.spouseMiddleInitial || "",
        SpouseLastName: info.spouseLastName || "",
        SpouseSuffix: info.spouseSuffix || "",
        SpouseAddress1: info.spouseAddress1 || "",
        SpouseAddress2: info.spouseAddress2 || "",
        SpouseCity: info.spouseCity || "",
        SpouseState: info.spouseState || "",
        SpouseZip: info.spouseZip || "",
        SpouseEmail: info.spouseEmail || "",
        IsMarried: info.isMarried || "false",
      },
      Tracking: {
        SessionId: sessionStorage.getItem("oldSessionToken"),
        SrcId: "",
        SID: client.findParam("sid") || "",
        PubId: (client.findParam("pub") || client.findParam("pubid")) || Setting.productSettings.PubId,
        SubId: info.subId,
        HID: client.findParam("hid") || "",
        C1: client.findParam("c1") || "",
        C2: client.findParam("c2") || "",
        C3: client.findParam("c3") || "",
        Gclid: client.findParam("gclid") || "",
        GAdsExpirationDate: info.gAdsExpirationDate,
        Fbclid: client.findParam("fbclid") || "",
        FbAdsExpirationDate: info.fbAdsExpirationDate,
        Msclid: client.findParam("msclid") || "",
        MsAdsExpirationDate: info.msAdsExpirationDate,
        TransunionBlackBox: sessionStorage.getItem("blackBox") || "",
      },
      SiteSettings: {
        WebSite: Setting.domain,
        EngineID: 0,
        IsPrequal: false,
        PID: Setting.productSettings.PID,
        ProductCode: info.ProductCode,
        ProductId: Setting.productSettings.ProductId,
        AppRef: Setting.productSettings.ApplicationSourceReference ? Setting.productSettings.ApplicationSourceReference : "",
        Step: info.step,
        TermsId: Setting.mainSettings.TermsId,
        Token: client.findParam("token") || "",
      },
    };

    let response = await client.post("submit-application", applicantApiData);

    if (response) {
      if (response.IsGrandbank === true) {
        setGrandBanckDeclined(true);
      }

      if (response.result != null) {
        utms.updateAppId(response.ref_number || "");
        utms.updateSessionReport("appResult",response.ResultWebPageHash.toUpperCase());
        utms.updateSessionReport("applicantId", response.ref_number);
        setInfo({ index: "step", value: 3 });
        response.ResultWebPageHash = response.result.toLowerCase() === "sys_error" ? "sys_error" : response.ResultWebPageHash;
        response.decline_message = response.decline_message === "" ? "We are unable to verify your identity." : response.decline_message;
        setApplicationResult(response);
        resultStep(true);
        window.scrollTo(0, 0);
        if (response.ResultWebPageHash.toUpperCase() === "APPROVED") {
          history.push("/approved" + window.location.search);
          return;
        }
        if (pendingInfo.includes(response.result.toUpperCase())) {
          history.push("/pendinfo" + window.location.search);
          return;
        }
        history.push("/declined" + window.location.search);
        return;
      }
    } else {
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="row" id="header-text-container" style={{ backgroundColor: Setting.brandSettings.Primary }}>
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)} >
            <div>
              <div 
                className="add-authorized-user-text"
                onClick={() =>
                  setShowAdditionalCardHolderForm(!showAdditionalCardHolderForm)
                }
              >
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                Add Authorized User (Optional)
              </div>

              {/* Authorized User Form */}
              {showAdditionalCardHolderForm && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="smallerText mb-2">
                        {text.block1.disclaimerAdditionalCardholder}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderFirstName">
                          First Name*
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={17}
                            value={info.additionalCardholderFirstName}
                            onInput={(e) =>{
                              if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                    if((e.target.value.split(" ").length - 1) < 2){
                                        setInfo({ index: "additionalCardholderFirstName", value: e.target.value.trimLeft() })}
                                    }
                                }
                              }
                            }
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register("additionalCardholderFirstName")}
                          />
                        </div>
                        {errors.additionalCardholderFirstName && errors.additionalCardholderFirstName.message.map((message) => (
                          <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderMI">
                          Middle Initial
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={1}
                            value={info.additionalCardholderMI}
                            onInput={(e) =>{
                              if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                setInfo({ index: "additionalCardholderMI", value: e.target.value })
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Middle Initial"
                            {...register("additionalCardholderMI")}
                          />
                        </div>
                        {errors.additionalCardholderMI && errors.additionalCardholderMI.message.map((message) => (
                            <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-5 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderLastName">
                          Last Name
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={25}
                            value={info.additionalCardholderLastName}
                            onInput={(e) =>{
                              if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                    if((e.target.value.split(" ").length - 1) < 2){
                                        setInfo({ index: "additionalCardholderLastName", value: e.target.value.trimLeft() })
                                    }
                                }
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("additionalCardholderLastName")}
                          />
                        </div>
                        {errors.additionalCardholderLastName && errors.additionalCardholderLastName.message.map((message) => (
                            <div className="error-message" key={"additionalCardholderLastName-" + message}> Required field. Please enter only letters and hyphens. </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          checked={ info.hasAdditionalCardholder === "true" ? true : false }
                          value={info.hasAdditionalCardholder}
                          onClick={(e) => {
                            setInfo({
                              index: "hasAdditionalCardholder",
                              value: info.hasAdditionalCardholder === "true" ? "false" : "true",
                            });
                          }}
                          className="form-check-input"
                          type="checkbox"
                          {...register("hasAdditionalCardholder")}
                        />
                        <label className="form-check-label" htmlFor="termsPrices">
                          Add this user
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">E-Sign Disclosure</h1>
              </div>
              <Esign />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Pricing and Terms</h1>
              </div>
              <div className="col-12 ">
                <div className="terms-wrapper">
                  <TC data={{ productCode: info.ProductCode }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Credit Protection</h1>
              </div>
              <CreditProtection />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Acknowledgements</h1>
              </div>
              <Acknowledgements data={{ productCode: info.ProductCode }} />
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <button
                onClick={(e) => {utms.trackEvent(30, "CS", "", Setting.productSettings.ProductId, "Flow");PixelService.event("google", "CS", "Accept Offer");}}
                  disabled={isLoading}
                  id="dm-submit-app"
                  style={{
                    backgroundColor: Setting.brandSettings.Secondary,
                    width: "17%",
                  }}
                  className="btn text-small mt-3 rounded-pill"
                  type="submit"
                >
                  {isLoading ? "Submiting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <InvalidStateModal
        showInvalidStateModal={showInvalidStateModal}
        setShowInvalidStateModal={setShowInvalidStateModal}
      />
    </>
  );
}

export default Terms;
