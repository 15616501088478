import React, {  useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form'
import { Controller, useFormContext } from "react-hook-form";
import './checkBox.css';
const CheckBox = ({ ...props }) => {
    const { control} = useFormContext();

    const [yes, setYes] = useState(false);
    const [no, setNo] = useState(false);

    useEffect(() => {
        if (props.checked === true) {
            setYes(true);
        } else if (props.checked === false) { // Check to avoid undefined setting as no
            setNo(true);
        }
    }, [props.checked]);

    return (
        <div className="form-inline cfc1-checkbox">
            <div className="form-check form-check-inline">
                <Controller
                    control={control}
                    name={props.registername}
                    rules={props.validationrules}
                    render={({ field }) => (
                        <>
                        <div className="radio-btn">
                            <Form.Check
                                {...field}
                                {...props}
                                inline
                                type='radio'
                                checked={yes}
                                onClick={() => {
                                    setYes(true); setNo(false);
                                }}
                                value={'true'}
                            />
                            <p  >Yes</p> 
                        </div>
                        <div className="radio-btn">      
                            <Form.Check
                                {...field}
                                {...props}
                                inline
                                type='radio'
                                checked={no}
                                onClick={() => {
                                    setNo(true); setYes(false);
                                }}
                                value={'false'}
                            />
                            <p>No</p>
                       </div>
                        </>
                    )}
                />
                
            </div>
            
        </div>


    )

}
export default CheckBox;
