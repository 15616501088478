import React from 'react';
import { useStoreState } from 'easy-peasy';
import '../../../assets/layout/banner.css';

function Banner() {

    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);

    const bannerbgImageSurge = brandSettings.BannerImage.replace('.png', '') + "-mobile.png";


    const bannerImg = {
        backgroundColor: brandSettings.BannerBgColor,
        backgroundImage: mainSettings.Flow === "PREQUAL" ? 'url("' + brandSettings.BannerImage + '")' : null
    };

    const bannerImgMobile = {
        backgroundColor: brandSettings.BannerBgColor,
        width: "100%",
        height: "auto",
    };
    
    const bannerImgMobileSurge = {
        backgroundColor: brandSettings.BannerBgColor,
        backgroundImage: `url('${bannerbgImageSurge}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    }

    return (
        <div>
            <div className="banner-container banner d-none d-md-block" style={bannerImg}>
                <div className="container">
                    <div className="row">
                        <div className="col-6 banner-content">
                            <div className="row">
                                <h3 className="banner-title">{productSettings.BannerText.Title}</h3>
                                <p className="banner-subtitle"> {productSettings.BannerText.SubTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-container banner d-block d-md-none">
                {mainSettings.Flow === "PREQUAL" && productSettings.ProductId === 1 ? 
                <>
                        <div className="bannerNewFlow" style={bannerImgMobileSurge}>
                            <h3 className="banner-title">{productSettings.BannerText.Title}</h3>
                            <p className="banner-subtitle"> {productSettings.BannerText.SubTitle}</p>                          
                        </div>
                        
                </> :
                <div style={bannerImgMobile}>
                    <h1>{productSettings.BannerText.Title}</h1>
                    {mainSettings.Flow === "PREQUAL" ?
                        <img alt="" src={brandSettings.BannerImage.replace('.png', '') + "-mobile.png"} className="col-12" /> :
                        <div className="card-image-container text-center">
                            <img alt="" src={brandSettings.CardImage} className="col-12" />
                        </div>
                    }                    
                </div>
                }              
            </div>
        </div>
    );
}

export default Banner;
