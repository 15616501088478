import React, {useEffect} from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import UserDataForm from '../../global-elements/userDataForm'
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import Banner from "../../../body/banners/banner";
import '../../../../assets/terms/dm-terms.css';
import marketing from "../../../../services/marketing"
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import Features from "../../global-elements/feature";
import Security from "../../global-elements/security";
import Reputation from "../../global-elements/reputation";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import Trustbox from "../../global-elements/trustbox";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import InvalidState from "../../../dialog/invalidState/InvalidState";
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";

function Landing() {
    const ada = new AudioEyeSupport();
    const Setting = useStoreState((state) => state.siteSettingsModel);
    const setGrandBanckDeclined = useStoreActions((actions) => actions.siteSettingsModel.setGrandBanckDeclined);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const text = useStoreState((state) => state.CFCText.landing);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const resultStep = useStoreActions(actions => actions.setDisplayResult);
    const history = useHistory();
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const [errorShow, setErrorShow] = useState(false);
    const wildcard = new Wildcard();
    const closeError = () => setErrorShow(false);
    const client = new AxiosWrapper();
    const invalidState = useStoreState(state => state.siteSettingsModel.invalidState);
    const setInvalidState = useStoreActions(actions => actions.siteSettingsModel.setInvalidState);
    const [errorMessage, setErrorMessage] = useState(false);
    const validatorService = new ValidatorService();
    const methods = useForm({mode: 'onSubmit'});
    const [isLoading, setLoading] = useState(false);
    const useRecaptcha = useStoreState((state) => state.siteSettingsModel.useRecaptcha);
    const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
    const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
    const utms = new marketing();
    const pendingInfo = ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
    const setInitPixels = useStoreActions(actions => actions.setInitPixels);
    const initPixels = useStoreState(state => state.initPixels);
    const PixelService = new PixelsService();
    

    useEffect(() => {
        if(!initPixels){
            utms.mapper();
            PixelService.initialize().pageView("/landing").event("google", "CFC", "Landing Page");
            ada.setup();
            utms.trackEvent(13, "CFC", "", Setting.productSettings.ProductId, "Flow");
            getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
            setInitPixels(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const submit = async (data) => {
        setLoading(true)
       
        if (!validatorService.preValidations(data, methods, info)) {
            await submitApplication();
        }
        setLoading(false)
    }

    const validateState = async () => {
        
        const response = await client.post("validate-state", {
          FirstName: info.FirstName,
          LastName: info.LastName,
          Address1: info.Address1,
          Address2: info.Address2,
          City: info.City,
          State: info.State,
          Zip: info.Zip,
          Product: brandSettings.ProductName,
          checkNonBusinessState: true,
        });
        
        if (response.Result === "INVALIDSTATE") {
       
          setInfo({ index: "step", value: 3 });
          response.ResultWebPageHash = "INVALIDSTATE";
          response.decline_message = "We do not currently do business in the state that you reside in";
          setApplicationResult(response);
          resultStep(true);
          return "invalid";
        }
        if (response.Result === "MISMATCH") {
          methods.setError("City", {
            type: "string",
            message: [
              "Please Check Your City As It Does Not Match In Our Database.",
            ],
          });
          methods.setFocus("City");
          return false;
        }

         //Validate Spouse Address (If have one)
        if(info.spouseHasDifferentAddress === true){
            const response = await client.post("validate-state", {
                FirstName: info.spouseFirstName,
                LastName: info.spouseLastName,
                Address1: info.spouseAddress1,
                Address2: info.spouseAddress2,
                City: info.spouseCity,
                State: info.spouseState,
                Zip: info.spouseZip,
                Product: brandSettings.ProductName,
                checkNonBusinessState: true,
            });

            if (response.Result === "MISMATCH") {
                methods.setError("spouseCity", {
                type: "string",
                message: [
                    "Please Check Your City As It Does Not Match In Our Database.",
                ],
                });
                methods.setFocus("spouseCity");
                return false;
            }
        }
        return true;
      };

    async function submitApplication(){
    
        const validState = await validateState();
        if(!validState){return}
        if(validState === "invalid"){
            setInvalidState(true);
            return;
        }
        let applicantApiData = {
            Applicant: {
                FirstName: info.FirstName,
                MiddleName: info.MiddleName,
                LastName: info.LastName,
                Suffix: info.Suffix,
                Address1: info.Address1,
                Address2: info.Address2,
                City: info.City,
                State: info.State,
                Zip: info.Zip,
                Email: info.Email,
                PrimaryPhone: info.primaryPhone,
                SecondaryPhone: info.SecondaryPhone,
                DOB: info.dob,
                SSN: info.ssn,
                MonthlyIncome: info.MonthlyIncome,
                IncomeSource: info.incomeSource,
                HasCheckingAccount: info.hasCheckingAccount,
                cashIntent: info.cashIntent === "" ? false : info.cashIntent,
                ReservationCode: info.ReservationCode ? info.ReservationCode : "",
            },
            Terms: {
                ESignConsent: info.eSignConsent === "1" ? true : false,
                CreditProtection: info.creditProtection === "yes" ? true : false,
                TcAcknowledgement: info.tcAcknowledgement === "1" ? true : false,
                EmailOptIn: true,
                CardColorCode: info.cardColorCode,
                SMSConsent : info.SMSConsent
            },
            AdditionalCardholder: {
                AdditionalCardholder: info.hasAdditionalCardholder === "true" ? true : false,
                AdditionalCardholderFirstName: info.additionalCardholderFirstName || "",
                AdditionalCardholderMI: info.additionalCardholderMI || "",
                AdditionalCardholderLastName: info.additionalCardholderLastName || "",
                AdditionalCardholderSuffix: info.additionalCardholderSuffix || "",
            },
            SpouseInformation: {
                SpouseHasDifferentAddress: info.spouseHasDifferentAddress || "false",
                SpouseFirstName: info.spouseFirstName || "",
                SpouseMiddleName: info.spouseMiddleInitial || "",
                SpouseLastName: info.spouseLastName || "",
                SpouseSuffix: info.spouseSuffix || "",
                SpouseAddress1: info.spouseAddress1 || "",
                SpouseAddress2: info.spouseAddress2 || "",
                SpouseCity: info.spouseCity || "",
                SpouseState: info.spouseState || "",
                SpouseZip: info.spouseZip || "",
                SpouseEmail: info.spouseEmail || "",
                IsMarried: info.isMarried || "false",
            },
            Tracking: {
                SessionId: sessionStorage.getItem("oldSessionToken"),
                SrcId: "",
                SID: client.findParam("sid") || "",
                PubId:(client.findParam("pub") || client.findParam("pubid")) || Setting.productSettings.PubId,
                SubId: info.subId,
                HID: client.findParam("hid") || "",
                C1: client.findParam("c1") || "",
                C2: client.findParam("c2") || "",
                C3: client.findParam("c3") || "",
                Gclid:(JSON.parse(sessionStorage.getItem("trafficInfo"))["Provider"] === "Google")? JSON.parse(sessionStorage.getItem("trafficInfo"))["ClickId"] : "",
                GAdsExpirationDate: info.gAdsExpirationDate,
                Fbclid: client.findParam("fbclid") || "",
                FbAdsExpirationDate: info.fbAdsExpirationDate,
                Msclid: client.findParam("msclid") || "",
                MsAdsExpirationDate: info.msAdsExpirationDate,
                TransunionBlackBox: sessionStorage.getItem("blackBox") || ""
            },
            SiteSettings: {
                WebSite: Setting.domain, 
                EngineID: 0,
                IsPrequal: false,
                PID: Setting.productSettings.PID,
                ProductCode: Setting.mainSettings.ProductCode,
                ProductId: Setting.productSettings.ProductId,
                AppRef: Setting.productSettings.ApplicationSourceReference ? Setting.productSettings.ApplicationSourceReference : "",
                Step: info.step,
                TermsId: Setting.mainSettings.TermsId,
                Token: client.findParam("token") || ""
            },
            HasCardColorChoice: Setting.mainSettings.HasCardColorChoice
        }

        let response = await client.post('submit-application', applicantApiData);

        if (response) {
            if (response.IsGrandbank === true) { setGrandBanckDeclined(true); }
      
            if (response.result != null) {
              utms.updateAppId(response.ref_number || "");
              if(response.result){     utms.updateSessionReport("appResult",   response.result.toUpperCase() ); }
              if(response.ref_number){ utms.updateSessionReport("applicantId", response.ref_number); }
             
              setInfo({ index: "step", value: 3 });
              setApplicationResult(response);
              resultStep(true);
              window.scrollTo(0, 0);

              if (response.result.toUpperCase() === "APPROVED") {
                history.push("/approved" + window.location.search);
                return;
              }
              if (pendingInfo.includes(response.result.toUpperCase())) {
                history.push("/pendinfo" + window.location.search);
                return;
              }
              history.push("/declined" + window.location.search);
              return;
            }
          } else {
            setErrorMessage("An unknown error has occured. Please try again.");
            setErrorShow(true);
            setLoading(false);
          }
    }

    return (
        <>
            <Banner />
            <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError}/>
            <InvalidState visible={invalidState} />
            <div className="container mt-5 mb-5">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <div  className="dm-form">
                            <UserDataForm />
                        </div>
                        

                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">E-Sign Disclosure</h1>
                            </div>
                            <Esign />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Pricing and Terms</h1>
                            </div>
                            <div className="col-12 ">
                                {/* TODO: UNCOMMENT THIS WHEN WE HAVE SAMPLES!!!
                                <TC data={{productCode: info.ProductCode}} /> */}
                                <div className="terms-wrapper">
                                    <TC data={{ productCode: Setting.mainSettings.ProductCode}} />
                                </div>                                
                            </div>                            
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Credit Protection</h1>
                            </div>
                            <CreditProtection />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Acknowledgements</h1>
                            </div>
                            <Acknowledgements data={{productCode: Setting.mainSettings.ProductCode}} />
                        </div>
                        <div className="row">
                            <div className="captcha-container">
                                {useRecaptcha && Setting.productSettings.CaptchaKey !== null ? (
                                    <div className="form-group displayFlexCenter">
                                    <Recaptcha
                                        setRecaptchaDisabled={setRecaptchaDisabled}
                                        ProductName={brandSettings.ProductName}
                                    />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <button 
                                onClick={() => {PixelService.event("google", "CFC", "Submit Application");utms.trackEvent(15, "CFC", "", Setting.productSettings.ProductId, "Flow");}}
                                disabled={isLoading || ((useRecaptcha && Setting.productSettings.CaptchaKey !== null) &&recaptchaDisabled)} id="dm-submit-app" style={{backgroundColor : Setting.brandSettings.Tertiary, width : "22%"}} className="btn text-small mt-3 rounded-pill" type="submit">
                                {isLoading ? 'Submitting...' : 'Submit Application'}
                                </button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className="row pt-1 pb-1" id="header-text-container" style={{ backgroundColor:  Setting.brandSettings.Secondary }}>
                <div className="col-12 text-center">
                    <h1 id="offer-header-text" className="">{wildcard.update().proccess(text.block1.title)}</h1>
                </div>
            </div>
            <div className="container mb-3">
                <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="card-image-container text-center pt-3">
                            <img alt="" src={Setting.brandSettings.CardImage} className="col-lg-12" />
                        </div>
                        
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                        <Features />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <Security />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <Reputation />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Trustbox />
                    </div>
                </div>
            </div>
           
        </>
        
    );
}

export default Landing;
