import React from 'react';
import { useStoreState } from 'easy-peasy';

function SurgeMicroComboTB() {
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    return (
        <div className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5cefd6f2951de70001a64d1d" data-style-height="40px" data-style-width="100%" data-theme="light">
            <a href={productSettings.trustpilotUrl} target="_blank" rel="noopener noreferrer">Trustpilot</a>
        </div>
    );
}

export default SurgeMicroComboTB;