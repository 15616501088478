import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ErrorModal = ({size, show, message, closeError}) => {
  return (
    <Modal
        size={size}
        show={show}
        onHide={closeError}
        backdrop="static"
        centered
      >
        <Modal.Body>
            <h4>Error</h4>
            <p>
                {message}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button size='sm' onClick={closeError}>Close</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ErrorModal