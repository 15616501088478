import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ValidatorService from "../../../../services/validator";
import Stepers from "../components/stepers";
import ReactGA from "react-ga4";
import FacebookPixel from "react-fbq";

function Step3() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );

  const text = useStoreState((state) => state.prequalText.step3);
  const {
    setError,
    handleSubmit,
    register,
    unregister,
    formState: { errors },
  } = useForm({ shouldFocusError: true, mode: "onSubmit" });

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const validatorService = new ValidatorService();
  const [timer, setTimer] = useState(null);
  const siteSetting = useStoreState((state) => state.siteSettingsModel);
  const [cursor, setCursorPosition] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0);
    //Google Pixels
    if (
      siteSetting.Pixels.find(
        (Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG
      )
    ) {
      ReactGA.send({ hitType: "pageview", page: "/step3" });
      ReactGA.event({category: "tracking", action: "step3",});
    }

    //Facebook Pixels
    if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")) {
      FacebookPixel.initialize({
        id: siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")[
          "Tag"
        ],
      });
      FacebookPixel.pageView();
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeDelay(change) {
    change = change.replace(/[$,]+/g, "");
    setInfo({
      index: "annualIncome",
      value: change,
    });
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        const calculatedMonthlyIncome = calculateMonthlyIncome(change);
        setInfo({
          index: "MonthlyIncome",
          value: calculatedMonthlyIncome.replace(/[$,]+/g, ""),
        });
      }, 800)
    );
  }

  const annualIncomeMask = (annualIncome) => {
    let nf = new Intl.NumberFormat("en-US");
    if (!annualIncome || annualIncome === "") {
      return "$";
    } else {
      return "$" + nf.format(parseFloat(annualIncome).toFixed(2));
    }
  };

  const calculateMonthlyIncome = (annualIncome) => {
    let nf = new Intl.NumberFormat("en-US");
    return "$" + nf.format(parseFloat(annualIncome / 12).toFixed(2));
  };

  useEffect(() => {
    //unregister controls to avoid validations
    let additionalCardholderInputs = [
      "additionalCardholderFirstName",
      "additionalCardholderMI",
      "additionalCardholderLastName",
      "additionalCardholderSuffix",
    ];

    if (info.hasAdditionalCardholder === "false") {
      additionalCardholderInputs.forEach((input) => {
        unregister(input);
        setInfo({ index: input, value: "" });
      });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.hasAdditionalCardholder]);

  function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    const result = validatorService.validateFieldWithData(
      "monthlyIncome",
      info.MonthlyIncome
    );
    if (result.errorMessages.length > 0) {
      setError("annualIncome", {
        type: "string",
        message: result.errorMessages,
      });
      foundErrors = true;
    }

    setIsLoading(false);
    if (!foundErrors) {
      history.push("/step4" + window.location.search);
    }
  }

  return (
    <div className="container appFormsBackground">
      <h6 className="displayFlexCenter">
        <strong>STEP 3/5</strong>
      </h6>
      <div className="steperContainer">
        <Stepers step={3} totalSteps={5} />
      </div>
      <div className="row">
        <div className="col-12">
          <h6>
            <strong>{text.block1.title}</strong>
          </h6>
        </div>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="incomeSource">Primary Source of Income</label>
              <div className="input-container">
                <select
                  className="form-control"
                  id="tmi-source"
                  onInput={(e) =>
                    setInfo({ index: "incomeSource", value: e.target.value })
                  }
                  value={info.incomeSource}
                  {...register("incomeSource")}
                >
                  <option value=""></option>
                  <option value="Employed Full-time">Employed Full-time</option>
                  <option value="Employed Part-time">Employed Part-time</option>
                  <option value="Self Employed">Self Employed</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {errors.incomeSource &&
                errors.incomeSource.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label htmlFor="annualIncome"> Your Annual Income </label>
              <div className="input-container">
                <input
                  value={annualIncomeMask(info.annualIncome)}
                  onKeyDown={(e) => {
                    setCursorPosition(e.target.selectionStart)
                  }}
                  onKeyUp={(e) => {
                      if(e.nativeEvent.key === "Backspace" ){
                          e.target.setSelectionRange(cursor, cursor);
                      }
                  }}
                  onInput={(e) => {
                    e.preventDefault()
                                                
                    setCursorPosition(e.target.selectionStart)
                    
                    let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$","").replaceAll(",","") : e.nativeEvent.data;
                  
                    if (/[0-9.]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                        if(e.target.value.replaceAll("$","").replaceAll(",","") <= 999999){
                          changeDelay(e.target.value)
                        }
                    }
                  } }
                  type="text"
                  className="form-control"
                  placeholder="Annual Income"
                  {...register("annualIncome")}
                />
              </div>
              <div className="smallerText">
                {text.form.disclaimerAnnualIncome}
              </div>
              {errors.annualIncome &&
                errors.annualIncome.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}

              {info.annualIncome?.length > 7 && (
                <div className="redSmallText mb-2">
                  Please ensure that total annual income is correct!
                </div>
              )}
            </div>

            <div className="form-group">
              <label>
                Do you have an active checking account or debit card?
              </label>
              <div className="radioGroup">
                <div className="radio-btn">
                  <input
                    type="radio"
                    checked={info.hasCheckingAccount === "true"}
                   
                    value={"true"}
                    {...register("hasCheckingAccount")}
                    onClick={(e) =>
                      setInfo({
                        index: "hasCheckingAccount",
                        value: e.target.value,
                      })
                    }
                  />
                  <p>Yes</p>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    checked={info.hasCheckingAccount === "false"}
                    onClick={(e) =>
                      setInfo({
                        index: "hasCheckingAccount",
                        value: e.target.value,
                      })
                    }
                    value={"false"}
                    {...register("hasCheckingAccount")}
                  />
                  <p>No</p>
                </div>
              </div>

              {errors.hasCheckingAccount &&
                errors.hasCheckingAccount.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>

            <div className="form-group">
              <label>Would you like an additional card?</label>
              <div className="radioGroup">
                <div className="radio-btn">
                  <input
                    type="radio"
                    checked={info.hasAdditionalCardholder === "true"}
                    value={"true"}
                    {...register("hasAdditionalCardholder")}
                    onClick={(e) =>
                      setInfo({
                        index: "hasAdditionalCardholder",
                        value: e.target.value,
                      })
                    }
                  />
                  <p>Yes</p>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    checked={info.hasAdditionalCardholder === "false"}
                    onClick={(e) => {
                      setInfo({
                        index: "hasAdditionalCardholder",
                        value: e.target.value,
                      });
                    }}
                    value={"false"}
                    {...register("hasAdditionalCardholder")}
                  />
                  <p>No</p>
                </div>
              </div>
              {errors.hasAdditionalCardholder &&
                errors.hasAdditionalCardholder.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}

              {info.hasAdditionalCardholder === "true" && (
                <div className="smallerText">
                  {text.form.disclaimerAdditionalCardholder}
                </div>
              )}
            </div>

            {info.hasAdditionalCardholder === "true" && (
              <>
                <div className="form-group">
                  <label htmlFor="additionalCardholderFirstName">
                    First Name
                  </label>
                  <div className="input-container">
                    <input
                      maxLength={17}
                      value={info.additionalCardholderFirstName}
                      onInput={(e) =>
                        {if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                          if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                              if((e.target.value.split(" ").length - 1) < 2){
                                  setInfo({ index: "additionalCardholderFirstName", value: e.target.value.trimLeft() })}
                              }
                          }}
                      }
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      {...register("additionalCardholderFirstName")}
                    />
                  </div>
                  {errors.additionalCardholderFirstName &&
                    errors.additionalCardholderFirstName.message.map(
                      (message) => (
                        <div className="error-message"> {message} </div>
                      )
                    )}
                </div>

                <div className="form-group">
                  <label htmlFor="additionalCardholderMI">
                    Middle Initial{" "}
                  </label>
                  <div className="input-container">
                    <input
                      maxLength={1}
                      value={info.additionalCardholderMI}
                      onInput={(e) =>
                       {if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                        setInfo({ index: "additionalCardholderMI", value: e.target.value })}}
                      }
                      type="text"
                      className="form-control"
                      placeholder="Middle Initial"
                      {...register("additionalCardholderMI")}
                    />
                  </div>
                  {errors.additionalCardholderMI &&
                    errors.additionalCardholderMI.message.map((message) => (
                      <div className="error-message"> {message} </div>
                    ))}
                </div>

                <div className="form-group">
                  <label htmlFor="additionalCardholderLastName">
                    {" "}
                    Last Name{" "}
                  </label>
                  <div className="input-container">
                    <input
                      maxLength={25}
                      value={info.additionalCardholderLastName}
                      onInput={(e) =>
                        {
                          if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                            if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                if((e.target.value.split(" ").length - 1) < 2){
                                    setInfo({ index: "additionalCardholderLastName", value: e.target.value.trimLeft() })}
                                }
                            }
                        }
                      }
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      {...register("additionalCardholderLastName")}
                    />
                  </div>
                  {errors.additionalCardholderLastName &&
                    errors.additionalCardholderLastName.message.map(
                      (message) => (
                        <div className="error-message"> {message} </div>
                      )
                    )}
                </div>

                <div className="form-group">
                  <label htmlFor="additionalCardholderSuffix">Suffix</label>
                  <select
                    className="form-control"
                    id="additionalCardholderSuffix"
                    {...register("additionalCardholderSuffix")}
                  >
                    <option value=""></option>
                    <option value="JR">JR</option>
                    <option value="SR">SR</option>
                    <option value="II">II</option>
                    <option value="III">III</option>
                    <option value="IV">IV</option>
                  </select>
                </div>
                {errors.additionalCardholderSuffix &&
                  errors.additionalCardholderSuffix.message.map((message) => (
                    <div className="error-message"> {message} </div>
                  ))}
              </>
            )}
          </div>
        </div>

        <div className="buttonGroup">
          <div className="buttonContainer">
            <Button
              bsPrefix="grey continueButton"
              id="btn-back"
              className="btn"
              onClick={() => {
                history.push("/step2" + window.location.search);
              }}
            >
              Back
            </Button>
          </div>

          <div className="buttonContainer">
            <Button
              bsPrefix="mastercardOrangeBg continueButton"
              disabled={isLoading}
              id="btn-see-offers"
              className="btn"
              type="submit"
            >
              {isLoading ? "Submitting..." : "Continue"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step3;
