import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Redirect, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import api from '../services/axios'
import PrequalFlow from '../components/body/flows/prequalflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Timeout from '../components/systempages/timeout/Timeout';
import Error404 from '../components/systempages/error404/Error404';
import PrequalTerms from '../components/body/forms/prequal/prequalterms';
import Flinks from '../components/flow/flinks/flink';
import LayoutDM from '../components/flow/dm/layout';
import LayoutCFC from '../components/flow/cfc/layout';
import LayoutCROSSSELL from '../components/flow/crosssell/layout';
import NewDesignPrequal from '../components/flow/prequal/layout';
import LayoutSTANDALONE from '../components/flow/standalone/layout';
import TestBumper from "../components/body/forms/components/TestBumper";

import Result from '../components/body/resultpage/result';
import GrandBankPage from '../components/flow/global-elements/grandbank/grandbankPage';

function Routing() {
  const setAssetsLink = useStoreActions(actions => actions.setAssetsLink);
  const setAssetsImageLink = useStoreActions(actions => actions.setAssetsImageLink);
  const history = useHistory();
  const client = new api();
  const getSiteSettings = useStoreActions(actions => actions.siteSettingsModel.getSiteSettings);
  const hostname = useStoreState(state => state.siteSettingsModel.domain);;
  const isLoadingSettings = useStoreState(state => state.siteSettingsModel.isLoadingSettings);
  const mainSetting = useStoreState(state => state.siteSettingsModel.mainSettings);
  const setLoadingState = useStoreActions(actions => actions.siteSettingsModel.setIsLoadingSettings);
  const showHeader = useStoreState(state => state.siteSettingsModel.showHeader);
  const termsStep = useStoreState(state => state.displayPrequalTerms);
  const resultStep = useStoreState(state => state.displayResult);
  //Version Release//
  useEffect(() => {
    console.log("Build Version : " + (process.env.REACT_APP_VERSION || "local"))
  }, [])
  //---------------//

  useEffect(() => {

        getSiteSettings(window.location.href).then((data) => {
            if(data === "redirection"){return;}
            if(!data){history.push("/404"); return;}
            setAssetsLink(data.WebAssetsBaseUrlSettings.Cdn);
            setAssetsImageLink(data.WebAssetsBaseUrlSettings.CdnImage.replace("applicationweb/", ""));

      if (data.MainSettings.IsActive === false) {
        history.push("/maintenance")
      }
      setLoadingState(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='newDesign'>
      {isLoadingSettings ? <div className="pre-loader solid"></div> : null}
      {isLoadingSettings ? <div className="pre-loader-img"><FontAwesomeIcon icon={faSpinner} spin /></div> : null}
      {hostname.includes("de-") &&
        history.location.pathname === "/" &&
        (hostname.includes("dev") ||
          hostname.includes("test") ||
          hostname.includes("qa")) && <TestBumper />
      }
      {!isLoadingSettings && showHeader ? <Header /> : null}
      {!isLoadingSettings ?
        <>
          {/* Prequal Routes */}
          {
            (mainSetting.Flow === "PREQUAL" && !hostname.includes("surgecredit") && client.findParam("newDesign") === false) ?
              <>
                <Switch>
                  <Route path="/" component={() => <PrequalFlow />} exact />
                  <Route path="/terms" component={() => (termsStep) ? <PrequalTerms /> : <Redirect to={"/" + window.location.search} />} exact />
                  <Route path={["/approved", "/declined", "/pendinfo"]} component={() => (resultStep) ? <Result /> : <Redirect to={"/" + window.location.search} />} exact />
                  <Route path="/offer" component={() => <PrequalFlow />} exact />
                  <Route path="/timeout" component={() => <Timeout />} exact />
                  <Route path="/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/" + window.location.search} />} exact />
                  <Route path="/link-account" component={() => (resultStep) ? <Flinks /> : <Redirect to={"/" + window.location.search} />} exact />
                  <Route component={() => <Error404 />} />
                </Switch>
              </> : <></>
          }
          {
            (mainSetting.Flow === "PREQUAL" && (hostname.includes("surgecredit") || client.findParam("newDesign"))) ?
              <>
                <Switch>

                  <Route path="/declined" component={() => <Result />} exact />
                  <Route path="/offer" component={() => <PrequalFlow />} exact />
                  <Route path="/timeout" component={() => <Timeout />} exact />
                  <Route path="/" component={() => <NewDesignPrequal />} />
                  <Route path="/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/" + window.location.search} />} exact />
                  <Route component={() => <Error404 />} />

                </Switch>
              </> : <></>
          }
          {/* DM Routes */}
          {
            (mainSetting.Flow === "DM") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutDM />} />
                </Switch>
              </> : <></>
          }
          {/* CFC Routes */}
          {
            (mainSetting.Flow === "CFC") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutCFC />} />
                </Switch>
              </> : <></>
          }
          {/* CrossSell Routes */}
          {
            (mainSetting.Flow === "CS") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutCROSSSELL />} />
                </Switch>
              </> : <></>
          }
          {/* Standalone Routes */}
          {
            (mainSetting.Flow === "STANDALONE") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutSTANDALONE />} />
                </Switch>
              </> : <></>
          }
          {/* Maintenance and Error Pages */}
        </>
        : null}
      {!isLoadingSettings ? <Footer /> : null}
    </div>

  );

}

export default Routing;