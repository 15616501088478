import React, {useState, useEffect} from 'react';
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import '../../assets/layout/header.css';
import marketing from '../../services/marketing';

function Header() {

    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const applicant = useStoreState(state => state.applicantModel.applicant)    
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const routingSettings = useStoreState(state => state.routingSettingsModel.routingSettings);
    
    const assetsLink = useStoreState(state => state.assetsImageLink);
    const utm = new marketing();
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const [pCode, setPCode] = useState(mainSettings.ProductCode);

    useEffect(() => {
       
        if (applicant.ProductCode || applicant.ProductCode !== "") {  
            
            localStorage.setItem("TCProductCode", applicant.product_code)
            setPCode(applicant.ProductCode);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicant.ProductCode])

    useEffect(() => {
        if (applicationResult.product_code || (typeof applicationResult.product_code !== 'undefined' && mainSettings.Flow !== "STANDALONE") || applicationResult.product_code !== "") {
            localStorage.setItem("TCProductCode", applicationResult.product_code)
            setPCode(applicationResult.product_code);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationResult.product_code])

    useEffect(() => {
        if(!routingSettings.TermsHeaderCDN){
            localStorage.setItem("TCProductCode", pCode)
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[routingSettings.TermsHeaderCDN])


    return (
        <div>
            <div className="header-container container d-none d-lg-block">
               

                <img id="cfc-logo" alt="" src={assetsLink+"appsites/cfc-logo.png"}></img>
               
                {mainSettings.Flow !== "CLI" ? <img id="product-logo" alt="" src={brandSettings.HeaderLogo}></img> : null}
                <ul>
                    {routingSettings.PrivacyHeader ?
                        <li id="li-privacy" className="header-text">
                            <a onClick={ () => { utm.updateSessionReport("privacyLink") }} href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">PRIVACY</a>
                        </li> : null
                    }
                    {mainSettings.Flow !== "CLI" ?
                        <li id="li-masterCard">
                            <img alt="" src="https://images.continentalfinance.net/applicationweb/Common/mastercard-logo.svg"></img>
                        </li> : null
                    }
                    <li id="li-secure-site" className="header-text">
                        <FontAwesomeIcon icon={faLock} /> &nbsp;
                        THIS IS A SECURE SITE
                    </li>
                    {mainSettings.Flow === "DM" ?
                        routingSettings.TermsHeader &&
                            <li id="li-terms" className="header-text">
                                {routingSettings.TermsHeaderCDN ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                window.location.href.indexOf('/declined') > -1 ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                window.location.href.indexOf('/pendinfo') > -1 ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                <a href="/tc" onClick={(e) => { utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)}} target="_blank" rel="noopener noreferrer">TERMS</a>}
                            </li>
                         :
                        routingSettings.TermsHeader &&
                            <li id="li-terms" className="header-text">
                                {routingSettings.TermsHeaderCDN ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                <a href="/tc" onClick={(e) => { utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)}} target="_blank" rel="noopener noreferrer">TERMS</a>}
                            </li>
                        
                    }
                </ul>
            </div>
            <div className="header-container container d-lg-none sm">
                <div className="row justify-content-between">
                    <div className="col-6 linkText">
                            {mainSettings.Flow === "CLI" ?
                                <img id="cfc-logo" alt="" src={assetsLink+"appsites/cfc-logo.png"} className="my-2"></img> :
                                <img style={{height : (brandSettings.ProductName === "Revel") ? "inherit" : ""}} id="product-logo-sm" alt="" src={brandSettings.HeaderLogo} className="my-2 productLogoSm"/>
                            }
                            
                            <div><FontAwesomeIcon icon={faLock} /> THIS IS A SECURE SITE</div>
                    </div>

                    <div className="col-auto">
                        {mainSettings.Flow !== "CLI" ?
                                <img alt="" src="https://images.continentalfinance.net/applicationweb/Common/mastercard-logo.svg" className="mastercardImg my-2"/> : null
                        }

                        <div className={brandSettings.ProductName === "Revel" ? "row align-items-end mx-1" : "row mx-1"} style={{ height : (brandSettings.ProductName === "Revel") ? "44%" : ""}}>
                            {routingSettings.PrivacyHeader ?
                                <div className="col-auto linkText px-1">
                                    <a href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">PRIVACY</a>
                                </div> : null
                            }
                            
                            {mainSettings.Flow === "DM" ?
                            routingSettings.TermsHeader &&
                                <div className="col-auto linkText px-1">
                                    {routingSettings.TermsHeaderCDN ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                    window.location.href.indexOf('/declined') > -1 ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                    window.location.href.indexOf('/pendinfo') > -1 ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                    <a href="/tc" onClick={(e) => { utm.updateSessionReport("termsButton"); console.log("working"); localStorage.setItem("TCProductCode", pCode)}} target="_blank" rel="noopener noreferrer">TERMS</a>}
                                </div> :
                                routingSettings.TermsHeader &&
                                <div className="col-auto linkText px-1">
                                    {routingSettings.TermsHeaderCDN ? <a href={productSettings.TermsUrl} target="_blank" rel="noopener noreferrer">TERMS</a> :
                                    <a href="/tc" onClick={(e) => { utm.updateSessionReport("termsButton"); console.log("working"); localStorage.setItem("TCProductCode", pCode)}} target="_blank" rel="noopener noreferrer">TERMS</a>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Header;