import React, { useState, useEffect, useRef } from 'react';
import { useStoreState} from 'easy-peasy';
import { AiOutlineExclamation } from 'react-icons/ai';
import { useFormContext } from "react-hook-form";
import '../../../assets/terms/credit-protection.css';
import { errorAlert } from '../forms/validationRules';
import marketing from "../../../services/marketing";

function CreditProtection(props) {
    const { setValue,register } = useFormContext();    
    const cpLink = useStoreState(state => state.siteSettingsModel.productSettings);
    const assetsBaseUrl = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const firstRender = useRef(true);
    const [creditProtection, setCreditProtection] = useState("");
    const validations = useStoreState(state => state.siteSettingsModel.validationRules);
    const utm = new marketing();

    const handleClick = (val) => {
        setValue("creditProtection", val, { shouldValidate: true });
        setCreditProtection(val);
    }
    useEffect(() => {
        firstRender.current = false;
    }, []);

    useEffect(() => {
        let cpValidation = validations.filter(o => o.ValidationType==="creditProtection")[0];
        let cpCheckbox  = {required: cpValidation.IsRequired, shouldUnregister: true }

        register("creditProtection", cpCheckbox)
        !firstRender && setValue("creditProtection", creditProtection, { shouldValidate: true });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditProtection])

    return (
        <div className="container-fluid">
            <div className="row creditprotection">
                <div className="col-lg-2 text-center whitebg">
                    <div className="credit-protection-img">
                        <img alt="" src={assetsBaseUrl.CdnImage + "Common/creditprot.gif"} />                        
                    </div>                    
                </div>
                <div className="col-lg-10 credit-protection-content">
                    {props.valid ?
                    <div className='p-2 text-white mb-2 ml-1' style={{backgroundColor : "red"}}> 
                        <AiOutlineExclamation color={errorAlert.color} size={errorAlert.size} ></ AiOutlineExclamation> 
                        <span>Please select one of the following options</span>
                    </div>  
                    : <></>}
                    <div className="form-check check-fields">
                        <input  onClick={() => { utm.updateSessionReport("continentalCreditProtection") }} className="form-check-input" type="checkbox" value="yes" id="credit-protection-yes"  checked={(creditProtection === "yes" ? true : false)} onChange={(event) => { handleClick(event.target.value) }}/>
                        <label className="form-check-label" htmlFor="credit-protection-yes">
                            <b>Yes</b>, I want to purchase Optional CONTINENTAL CREDIT PROTECTION (the “Program”). My decision to purchase or
                            not to purchase the Program will not affect any application for credit or the terms of any existing account I have
                            with Celtic Bank. I understand that:
                        </label>
                    </div>
                    <ul>
                        <li>The monthly fee is $0.99 for each $100 of the Outstanding Account Balance on the date that my monthly billing cycle closes.</li>
                        <li>
                            There are conditions, terms, exclusions, and requirements set forth in the <a onClick={() => { utm.updateSessionReport("creditProtectionLink") }} href={cpLink.OnlineCreditProtectionUrl} target="_blank" rel="noopener noreferrer" >CONTINENTAL CREDIT PROTECTION Addendum </a>
                            that may prevent me from receiving Program Benefits. <a href='/terms#credit-protection-disclosures' onClick={(e) => { e.preventDefault(); document.getElementById("credit-protection-disclosures").focus()}}>Important Disclosures</a> are stated below.
					    </li>
                        <li>I will not be able to make purchases or obtain cash advances while receiving Program Benefits.</li>
                        <li>I may cancel at any time and if I cancel within 30 days of purchase, I will receive a full refund.</li>
                    </ul>
                    <p>
                        I certify that I have read Important Disclosures below.
                        </p>
                    <div className="form-check check-fields">
                        
                        <input className="form-check-input" type="checkbox" value="no" id="credit-protection-no" checked={(creditProtection === "no" ? true : false)} onChange={(event) => { handleClick(event.target.value) }} />
                        <label className="form-check-label" htmlFor="credit-protection-no" >
                            <b>No thank you</b>, I am not interested in the CONTINENTAL CREDIT PROTECTION Program.
                    </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditProtection;