import React, {useEffect} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ReactGA from 'react-ga4';

function PendInfo() {
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const applicant = useStoreState(state => state.applicantModel.applicant);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const Pixels = useStoreState(state => state.siteSettingsModel.Pixels);
    const webAssetsBaseUrlSettings = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);

    useEffect(() => {
        
        if(alResult === false){        
            //getGACode(Pixels);
            if(Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
                ReactGA.send({ hitType: "pageview", page: "/pendinfo" });
                ReactGA.event({category: "tracking", action: "pendinfo",})
            }
            setAlResult(true)
        }
        
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h4>Reference #: {applicationResult.ref_number}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h1>Thank you for applying...</h1>
                    <p><b>Dear {applicant.FirstName} {applicant.LastName}:</b></p>
                    <p>Thank you for applying for the {brandSettings.ProductName} credit card issued by Celtic Bank and serviced by Continental Finance Company.</p>
                </div>
                <div className="col-lg-6"><img className="col-lg-12" src={brandSettings.ProductName === "Surge" ? webAssetsBaseUrlSettings.CdnImage+"Surge/card-silver.png" : brandSettings.CardImage} alt={brandSettings.ProductName} /></div>
                <div className="col-lg-6 result-message">
                    <p>
                        <small>
                            Unfortunately upon review of your application, we need additional information from you to complete processing of
                            your application. Please review the information below.
                        </small>
                    </p>
                </div>
            </div>
            <div className="row reminder">
                <h4>In order to continue processing your credit card application, please send us the information requested in each of the three categories listed below:</h4>
                <div>
                    <small>
                        <ol>
                            <li>
                                <b>A copy of your Social Security Card</b>
                            </li>
                            <li>
                                <b> A copy of one of the following <u>non-expired</u> items:</b>.
                                <ol className="sublist">
                                    <li>State issued driver’s license</li>
                                    <li>U.S. passport</li>
                                    <li>State issued identification card</li>
                                    <li>Active duty military identification card</li>
                                </ol>
                            </li>
                            <li>
                                <b>A copy of one of the following items which show your current <u>physical street address:</u></b>
                                <ol className="sublist">
                                    <li>Electric, gas, water, or cable bill(s)</li>
                                    <li>Student identification card</li>
                                    <li>Bank statement</li>
                                    <li>Vehicle registration card</li>
                                    <li>Voter registration card</li>
                                    <li>Reserve military identification or retired military identification</li>
                                    <li>Telephone, cellular, or pager bill(s)</li>
                                    <li>Recent pay stub</li>
                                </ol>
                            </li>
                        </ol>
                    </small>
                </div>
                <div>
                    <small>
                        Upon receipt of the requested information, we will complete the processing of your credit card application. If we do not receive
                        the requested information within thirty (30) days from the date of this email, no further consideration can be given to your application.
                    </small>
                </div>
                <br />
                <div>
                    <small>
                        Please upload your documents at <a href={'https://verify.your'+brandSettings.ProductName.toLowerCase()+'card.com'}>https://verify.your{brandSettings.ProductName.toLowerCase()}card.com</a>. Or, you can mail your documents
                        via USPS to Continental Finance Company, PO Box 3220, Buffalo, NY 14240-3220. Please be certain to include the reference number noted above.
                    </small>
                </div>
                <br />
                <div>
                    <small>
                        We reserve the right to decline a credit card application if we are unable to verify the information you provide or if, upon final review, we
                        determine that you do not meet our credit criteria. 
                     </small>
                    <small>
                        {applicationResult.bankDisclosure}
                    </small>
                </div>
            </div>
        </div>
    );


}
export default PendInfo;