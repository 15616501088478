import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { masking, unmasking, IncomeMask } from "../../../../services/masking";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import { useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/flows/crosssell/crosssell.css";
import PixelsService from '../../../../services/pixels';
import marketing from "../../../../services/marketing";
import AudioEyeSupport from "../../../../services/audioEyeSupport";

function Landing() {

  const ada = new AudioEyeSupport();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.crossSellText.landing);
  const setInfoStore = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const storeApplicantData = useStoreActions((actions) => actions.applicantModel.storeApplicantData);
  const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const history = useHistory();
  const PixelService = new PixelsService();
  const wildcard = new Wildcard();
  const axios = new AxiosWrapper();
  const utms = new marketing();
  const { handleSubmit, register, formState: { errors }, setError, clearErrors } = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const [cursor, setCursorPosition] = useState(1)
  let [info, setInfo] = useState({
    reservationNumber: "",
    ssn: "",
    Email : "",
    MonthlyIncome : "0",
    productName: Setting.brandSettings.ProductName,
    campaignId: axios.findParam("campaignId") || "",
  });
  const setInitPixels = useStoreActions(actions => actions.setInitPixels);
  const initPixels = useStoreState(state => state.initPixels);

  
  useEffect(() => {
    if(!initPixels){
      utms.mapper();
      ada.setup();
      utms.trackEvent(26, "CS", "", Setting.productSettings.ProductId, "Flow");
      PixelService.initialize().pageView("/landing").event("google", "tracking", "landing");
      getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
      setInitPixels(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  useEffect(() => {
    
    if (axios.findParam("cfcechash")) {
      axios.get("get-dm-reservation-by-hash/" + axios.findParam("cfcechash")).then((response) => {
        if (typeof response === "undefined") {
          return;
        }

        changeReservationNumber(response)
        return;
      }).catch((err) => {
        return;
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const submit = async (data) => {
    setLoading(true);

    axios.post("/validate-cs-reservation", {...info, grossMonthlyIncome: info.MonthlyIncome, }).then((response) => {
      if (typeof response.Success === undefined || response.Success === false || String(response.ReservationCode) !== info.reservationNumber) {
        setError("reservationNumber",  {type : "custom", message : response.ErrorItem.Message || "Invalid data entered"});
        setLoading(false);
        return;
      }
      response.SSN = info.ssn;
      response.DOB = moment(response.BirthDate, "YYYYMMDD", true).format("YYYY-MM-DD");
      const applicantData = {...response, MonthlyIncome: info.MonthlyIncome, Email: info.Email,};

      storeApplicantData(applicantData);
      setInfoStore({ index: "ssn", value: info.ssn });
      setdisplayTerms(true)
      window.scrollTo(0, 0);
      history.push("/terms" + window.location.search);
      setLoading(false);
      return;
    }).catch((err) => {
      setError("reservationNumber");
      setLoading(false);
      return;
    });
  };

  const changeReservationNumber = newValue => {
    clearErrors("notfound")
    const eventMock = {nativeEvent: {inputType: ""}, target: { value: newValue } };
    const regExp = new RegExp("(D[0-9]+$|[0-9]+$|^$|D$)");
    if (regExp.test(newValue)) {
      setInfo({...info, reservationNumber: "D" + unmasking(eventMock, "DXXXXXXXXXXX", info.reservationNumber)});
    }
  }

  return (
    <>
      <div className="row" id="header-text-container" style={{ backgroundColor: Setting.brandSettings.Primary }}>
        <div className="col-lg-5 col-xs-6 card-image-product-container">
          <img src={Setting.brandSettings.CardImage} alt={`${Setting.brandSettings.ProductName} Card`} className="card-image"/>
        </div>
        <div className="col-lg-5 col-xs-6 product-text">
          <h1 id="" className="mt-2 offer-header-text">
            {wildcard.update().proccess(text.block1.title)}
          </h1>
          <div>{text.block1.descriptionFirstLine}</div>
          <div>
            {wildcard.update().proccess(text.block1.descriptionSecondLine)}
          </div>
        </div>
      </div>
      <div className="gray-background">
        <div className="container pt-5 pb-5">
          <form onSubmit={handleSubmit(submit)}>
            <div className="row">
              <div className="col-lg-5 col-xs-6 reservation-form">
                <div className="form-group">
                  <label className="text-center w-100" htmlFor="reservationNumber">
                    {text.block1.reservationNumber}
                  </label>
                  <div className="input-container">
                    <input required value={masking(info.reservationNumber.replace("D", ""),"DXXXXXXXXXXX")} 
                      onInput={(e) => changeReservationNumber(e.target.value)}
                      type="text"
                      className="form-control text-center"
                      placeholder="DXXXXXXXXXX"
                      {...register("reservationNumber")}
                    />
                  </div>
                  {errors.reservationNumber && (
                    <div className="error-message text-center">
                    {errors.reservationNumber.message}
                  </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-center w-100" htmlFor="ssn">
                    {text.block1.securityNumber}
                  </label>
                  <div className="input-container">
                    <input
                      required
                      value={masking(info.ssn, "XXX-XX-XXXX")}
                      onInput={(e) => {
                        let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","") : e.nativeEvent.data;
                        if (/[0-9]/.test(value)  || e.nativeEvent.inputType === "deleteContentBackward") {
                            setInfo({...info,  ssn:  unmasking(e,"XXX-XX-XXXX", info.ssn) })
                          }
                        }
                      }
                      type="text"
                      className="form-control text-center"
                      placeholder="XXX-XX-XXXX"
                      {...register("ssn")}
                    />
                  </div>
                </div>

                {/* Email Input */}
                <div className="form-group text-center">
                  <label htmlFor="email" className="text-center">
                    Email Address
                  </label>
                  <div className="input-container">
                    <input
                     maxLength={100}
                     value={info.Email} 
                     onInput={(e) => {
                         if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                             if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                 if((e.target.value.split("@").length - 1) < 2){
                                     setInfo({...info,  Email: e.target.value})
                                 }
                             }
                         }
                     }} 
                     type="text" className="form-control" placeholder="" {...register("Email")}
                    />
                  </div>
                  {errors.email && errors.email.message.map((message) => (
                      <div className="error-message"> {message} </div>
                  ))}
                </div>

                {/* Monthly Income Input */}
                <div className="form-group text-center">
                  <label htmlFor="Monthly Income" className="text-center">
                    {" "}
                    Your Monthly Income{" "}
                  </label>
                  <div className="input-container">
                    <input
                     value={IncomeMask(info.MonthlyIncome)} 
                     onInput={(e) => {
                         e.preventDefault()
                         setCursorPosition(e.target.selectionStart)
                        
                         let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$","").replaceAll(",","") : e.nativeEvent.data;
                       
                         if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                             if(e.target.value.replaceAll("$","").replaceAll(",","") <= 99999){
                                 let value = e.target.value.replaceAll("$","").replaceAll(",","");
                                 value = (!isNaN(value)) ? parseFloat(value).toFixed(0) : value;
                                 setInfo({...info,  "MonthlyIncome" : (isNaN(value) ? "" : value) })
                             }
                         }
                     }} 
                     onKeyDown={(e) => {
                         setCursorPosition(e.target.selectionStart)
                     }}
                     onKeyUp={(e) => {
                         if(e.nativeEvent.key === "Backspace" ){
                             e.target.setSelectionRange(cursor, cursor);
                         }
                     }}
                     type="text" className="form-control" placeholder="$ 0.00" {...register("MonthlyIncome")} 
                    />
                  </div>
                </div>

                <div className="form-group">
                  <button
                    onClick={() => {utms.trackEvent(27, "CS", "", Setting.productSettings.ProductId, "Flow"); PixelService.event("google", "CS", "Confirm Reservation Button");}}
                    disabled={isLoading}
                    style={{ backgroundColor: Setting.brandSettings.Secondary }}
                    className="btn pt-2 pb-2 w-100 mt-3"
                    type="submit"
                  >
                    {isLoading ? "CONFIRMING..." : "CONFIRM RESERVATION"}
                  </button>
                </div>
              </div>
              <div className="col-lg-1 d-xs-none d-flex justify-content-center">
                <span className="border-left"></span>
              </div>
              <div className="col-lg-6 justify-content-center descriptions-container">
                <div className="limit-block">
                  <div className="limit-title">{text.block2.title}</div>
                  <div className="limit-text">
                    {wildcard.update().proccess(text.block2.description)}
                  </div>
                </div>
                <div className="lifestyle-img">
                  <img src="https://imagestest.continentalfinance.net/applicationweb/Common/cross-sell-lifestyle.png" alt="crossell"></img>
                </div>
                <div className="secure-block">
                  <div style={{ color: "#008cba" }} className="secure-title">
                    <FontAwesomeIcon icon={faLock} /> &nbsp;{" "}
                    {text.secureSite.title}
                  </div>
                  <div className="secure-text-highlight">
                    {text.secureSite.firstLine}
                  </div>
                  <div className="">{text.secureSite.secondLine}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Landing;
