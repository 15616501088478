import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

export default function Timeout() {
  let lastActivity = new Date();
  const history = useHistory()
  const setTimeout = useStoreActions(action => action.setTimeout);
  //const maxWait = 1000;


  const maxWait = 1200 * 1000;

  setInterval(() => {
    if (new Date().valueOf() - lastActivity.valueOf() > maxWait) {
      setTimeout(true)
      history.push( "/timeout" + window.location.search);
    }
  }, 1000);

  document.addEventListener("mousemove", () => {
    lastActivity = new Date();
  });

  return null;
}
