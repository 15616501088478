import React from "react";
import Banner from "../../../flow/add-ons/banner";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import FacebookPixel from "react-fbq";
import Wildcard from "../../../../services/wildcard";
import marketing from "../../../../services/marketing";
import AxiosWrapper from "../../../../services/axios";


function Landing() {
  const siteSetting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.prequalText.landing);
  const wildcard = new Wildcard();
  const initPixels = useStoreState((state) => state.initPixels);
  const setInitPixels = useStoreActions((actions) => actions.setInitPixels);
  const utms = new marketing();
  const applicantApiClient = new AxiosWrapper();
  const setIdList = useStoreActions(actions => actions.siteSettingsModel.setIdList);
  const idList = useStoreState(state => state.siteSettingsModel.idList);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (initPixels === false) {
      //Google Pixels
      utms.mapper();
      //utms.trackEvent(1, "Prequal", "", productSettings.ProductId, "Flow");
      saveIcommPostbackqueue("", "Landed", 1);
      utms.pushnami(siteSetting.brandSettings.ProductName);
      if (
        siteSetting.Pixels.find(
          (Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG
        )
      ) {
        ReactGA.initialize(siteSetting.Pixels.find((Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG)["Tag"]);
        ReactGA.send({ hitType: "pageview", page: "/landing" });
        ReactGA.event({ category: "tracking", action: "landing" });
      }

      //Facebook Pixels
      if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")) {
        FacebookPixel.initialize({
          id: siteSetting.Pixels.find((Pixel) => Pixel.Type === "Facebook")[
            "Tag"
          ],
        });
        FacebookPixel.pageView();
      }

      //Tiktok Pixels
      if (siteSetting.Pixels.find((Pixel) => Pixel.Type === "Tiktok")) {
      }

      setInitPixels(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveIcommPostbackqueue = (refNo, siteEvent, step) => {
    let utms = new marketing();
    utms.trackEvent(step, "Prequal", refNo, siteSetting.productSettings.ProductId, "Flow");
    if (siteSetting.productSettings.TrafficSource.toLowerCase().match(new RegExp(/icomm/g))) {
        utms.postBackQueue({
            idList: idList,
            applicantId: refNo,
            productId: siteSetting.productSettings.ProductId,
            siteEvent: siteEvent,
            hid: applicantApiClient.findParam("hid") || "",
            productCode: siteSetting.mainSettings.ProductCode,
            pubId: (applicantApiClient.findParam("pub") || applicantApiClient.findParam("pubid")) || ""
        }).then((payload) => {
            if (idList === "") {
                setIdList(payload);
            }
            else {
                setIdList(idList + "," + payload);
            }
        })
    }
}

  return (
    <>
      <Banner />
      <div className="container appFormsBackground">
        <div className="row">
          <div className="col-12 col-md-6 desktopCol">
            <h2 className="desktopTitle">{text.block1.title}</h2>
            <ul className="redDot featureDesc">
              {text.block1.list.map((text, i) => {
                return <li key={i}>{text}</li>;
              })}
            </ul>
            <Link
              className="btn btnDesktop"
              to={"step1" + window.location.search}
              style={{
                backgroundColor: siteSetting.brandSettings.Secondary,
                color: "white",
              }}
            >
              {text.block1.start}
            </Link>
            <div className="d-block d-md-none mt-3">
              <h2 className="desktopTitle">
                {wildcard.proccess(text.desktopText.title)}
              </h2>
              <p className="p-0 featureDesc">{wildcard.proccess(text.desktopText.text)}</p>
            </div>
            <div className="d-none d-md-block mt-5">
              <h4 className="specialText blue my-3">
                {" "}
                <FontAwesomeIcon icon={faLock} />
                &nbsp; This is a secure site.
              </h4>
              <p className="mb-3 p-0 featureDesc"><strong>Ways we protect you and your information.</strong></p>
              <p className="p-0">{text.block4.body}</p>
            </div>
          </div>
          <div className="col-12 col-md-6 desktopCol">
            <div className="d-none d-md-block mb-5">
              <h2 className="desktopTitle">
                {wildcard.proccess(text.desktopText.title)}
              </h2>
              <p className="p-0 featureDesc">{wildcard.proccess(text.desktopText.text)}</p>
            </div>
            <h2 className="desktopTitle">
              {wildcard.proccess(text.block3.title)}
            </h2>
            {siteSetting.productSettings.Features.map((i, j) => {
              return (
                <div className="featureItem col-12 p-0" key={j}>
                  <div className="feature-bullet">
                    <span
                      style={{ color: siteSetting.brandSettings.Secondary }}
                    >
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                  </div>
                  <div className="feature-title">
                    <h4>{i.Title}</h4>
                  </div>
                  <div className="featureDesc">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: i.Description.replace(
                          "%fees and terms and conditions%",
                          '<a href="' +
                            siteSetting.productSettings.TermsUrl +
                            '" target="_blank">fees and terms and conditions</a>'
                        ),
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <a
              className="btn btnDesktop"
              href={siteSetting.productSettings.TermsUrl}
              target="_blank"
              style={{
                color: "white",
                backgroundColor: siteSetting.brandSettings.Primary,
                marginTop: "5%",
                marginBottom: "5%",
              }}
              rel="noopener noreferrer"
            >
              {text.block4.Terms}
            </a>
            <div className="d-block d-md-none">
              <h4 className="specialText blue">
                {" "}
                <FontAwesomeIcon icon={faLock} />
                &nbsp; This is a secure site.
              </h4>
              <p>{text.block4.body}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
