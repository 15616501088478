import React from 'react';
import Alert from 'react-bootstrap/Alert'
import '../../../../../assets/ErrorAlert.css'

export const ErrorAlert = (props) => {

  return(
  <>
        <Alert variant="warning" show={props.showAlert} className="alert">
          <p className="alert-message">
            Some fields are incorrect or missing. Please correct and resubmit.
          </p>
        </Alert>
  </>
) };
