import moment from "moment";

/*******KEEP FOR NOW SO PROJECT COMPILES********/
export const validationRules = {
   
}

export const formatDOB = (dob, setDOB) => {
    dob = new Date(dob);
    setDOB(moment(dob).format('YYYY-MM-DD'));
}

export const formatSSN = (ssn, setSSN) => {
    if(ssn.length>8){
        ssn = ssn.replaceAll('-', '');
        setSSN(ssn);
    }
   
}

export const formatPhone = (phone, setPhone) => {
    if (phone.length > 13) {
        phone = phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
       
        
    }
    phone = phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    setPhone(phone);
}

export const requireRecaptcha = (validations) => {
    const [rules] = validations.filter((validationRule) => validationRule.ValidationType === 'recaptcha');
    return rules.IsRequired;
}
export const getValidation = (validationType, validations) => {
    let validationsForField = {};
    validationsForField.shouldUnregister = true;
    const [rules] = validations.filter((validationRule) => validationRule.ValidationType === validationType);

    //check if required
    validationsForField.required = rules.IsRequired;

    //check for specific values
    if (rules.AcceptedValues) {
        const acceptedValuesArr = JSON.parse(rules.AcceptedValues);
        if (acceptedValuesArr === 1) {
            validationsForField.validate = (value) => {
                return value
            };
        } else {
            validationsForField.validate = (value) => {
                if (value === '') return true;
                const [validAnswer] = acceptedValuesArr.filter((acceptedValue) => acceptedValue === value);
                return validAnswer === value
            };
        }
    };

    //field only contains numbers
    if (rules.AllDigits) {
        validationsForField.validate = (value) => {
            const regex = new RegExp('^[0-9]+$');
            return regex.test(value);
        };
    }
    //first character is not a number
    if (rules.FirstCharNotNum) {
        validationsForField.validate = (value) => {
            if (!rules.IsRequired && value === '') return true
            const regex = new RegExp('^[A-Za-z]');
            return regex.test(value);
        };
    }
    //max length 
    if (rules.MaxLengthChar !== 0) {
        validationsForField.maxLength = rules.MaxLengthChar;
    }
    //date of birth and minAge
    if (rules.MinAge !== 0 && rules.MinYearDOB != null) {
        validationsForField.validate = (value) => {
            if(!moment(value).isValid()) return false;
            const date = new Date();
            const today = moment(date.now);
            const dob = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
            const age = today.diff(dob, 'years');
            if (age < rules.MinAge) {
                return false
            }
            else {
                const minDOB = moment(rules.MinYearDOB).format("YYYY-MM-DD");
                if (dob > minDOB) return true; else return false;
            }
        }
    }
    //minLengthChar 
    if (rules.MinLengthChar !== 0) {
        validationsForField.minLength = rules.MinLengthChar;
    }
    //regex check 
    if (rules.Regex !== null) {
        // validationsForField.pattern = "/" + rules.Regex + "/";
        let newRegex = new RegExp(rules.Regex);
        validationsForField.pattern = newRegex;
    }
    //check for cross sell and DM pages
    if (rules.RequiredCrossSellDM) validationsForField.required = rules.RequiredCrossSellDM;
    return validationsForField
}

export const errorAlert = {
    color: "#ee4b2b",
    size: "1.5em"
}
