
export default {
  landing: {
    block1: {
      title: "TWO IS BETTER THAN ONE",
      descriptionFirstLine: "Get a Second Credit Card!",
      descriptionSecondLine:
        "You’ve been pre approved* to receive the {ProductName}® Mastercard",
      reservationNumber: "Enter your Reservation Number",
      securityNumber: "And your Social Security Number",
    },
    block2: {
      title: "Your Initial Credit Limit Doubles",
      description:
        "After making your first six monthly minimum payments on time, your initial credit limit will double. Shopping, dining, even travel are all in your future with your new {ProductName}® Mastercard.",
    },
    secureSite: {
      title: "This is a secure site.",
      firstLine: "Ways we protect you and your information:",
      secondLine:
        "We use 128-bit encryption technology to protect your personal information when you’re using our site or apps. You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and you see a lock symbol in your web browser.",
    },
  },
  terms: {
    block1: {},
    block2: {},
  },
};
