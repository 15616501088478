import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import ValidatorService from "../../../../services/validator";
import Stepers from "../components/stepers";
import marketing from "../../../../services/marketing";
import AxiosWrapper from "../../../../services/axios";
import moment from "moment";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import ReactGA from 'react-ga4';
import FacebookPixel from 'react-fbq';

function Step5() {
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const productSettings = useStoreState(
    (state) => state.siteSettingsModel.productSettings
  );
  const mainSettings = useStoreState(
    (state) => state.siteSettingsModel.mainSettings
  );

  const setApplicationResult = useStoreActions(
    (actions) => actions.applicationResultModel.setApplicationResult
  );
  const setGrandBanckDeclined = useStoreActions(
    (actions) => actions.siteSettingsModel.setGrandBanckDeclined
  );
  const setApplicantData = useStoreActions(actions => actions.applicantModel.setApplicant);
  const pendingInfo = ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
  const declinedResults = ["DECLINEDCBR", "DECLINEDINFO", "DECLINEDPENDINFO", "LCLCURRPFD", "LCLCURRPFEE"];
  const setIdList = useStoreActions(
    (actions) => actions.siteSettingsModel.setIdList
  );
 
  const setDisplayHeaderTerms = useStoreActions(
    (actions) => actions.setDisplayHeaderTerms
  );
  const useRecaptcha = useStoreState(
    (state) => state.siteSettingsModel.useRecaptcha
  );
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const idList = useStoreState((state) => state.siteSettingsModel.idList);
  const text = useStoreState((state) => state.prequalText.step5);


  const {
    setError,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ shouldFocusError: true, mode: "onSubmit" });

  const [isLoading, setIsLoading] = useState(false);
  const [backendError, setBackendError] = useState("");
  // eslint-disable-next-line  no-unused-vars
  const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
  const history = useHistory();
  const siteSetting = useStoreState((state) => state.siteSettingsModel);
  const validatorService = new ValidatorService();
  let utms = new marketing();
  const applicantApiClient = new AxiosWrapper();
  const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
  const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);

  useEffect(() => { 
    window.scrollTo(0, 0);
    //Google Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
      ReactGA.send({ hitType: "pageview", page: "/step5" });
      ReactGA.event({category: "tracking", action: "step5",});
    }

    //Facebook Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
      FacebookPixel.initialize({id : siteSetting.Pixels.find(Pixel => Pixel.Type === "Facebook")["Tag"]})
      FacebookPixel.pageView();
    }
  
    //Tiktok Pixels
    if(siteSetting.Pixels.find(Pixel => Pixel.Type === "Tiktok")) {     
     
    }
  
    //Mntn Pixels
   // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  // Maybe move to a separate file
  const saveIcommPostbackqueue = (refNo, siteEvent, step) => {
    let utms = new marketing();
    utms.trackEvent(step, "Prequal", refNo, productSettings.ProductId, "Flow");
    if (
      productSettings.TrafficSource.toLowerCase().match(new RegExp(/icomm/g))
    ) {
      utms
        .postBackQueue({
          idList: idList,
          applicantId: refNo,
          productId: productSettings.ProductId,
          siteEvent: siteEvent,
          hid: applicantApiClient.findParam("hid") || "",
          productCode: mainSettings.ProductCode,
          pubId: (applicantApiClient.findParam("pub") || applicantApiClient.findParam("pubid")) || "",
        })
        .then((payload) => {
          if (idList === "") {
            setIdList(payload);
          } else {
            setIdList(idList + "," + payload);
          }
        });
    }
  };

  const submitData = async () => {
    let applicantApiData = {
      Applicant: {
        FirstName: info.FirstName,
        MiddleName: info.MiddleName,
        LastName: info.LastName,
        Suffix: info.Suffix,
        Address1: info.Address1,
        Address2: info.Address2,
        City: info.City,
        State: info.state,
        Zip: info.Zip,
        Email: info.Email,
        PrimaryPhone: info.primaryPhone,
        SecondaryPhone: info.SecondaryPhone,
        DOB: info.dob,
        SSN: info.ssn,
        MonthlyIncome: info.MonthlyIncome,
        IncomeSource: info.incomeSource,
        HasCheckingAccount: info.hasCheckingAccount,
        cashIntent: info.cashIntent === "" ? false : info.cashIntent,
        ReservationCode: info.ReservationCode ? info.ReservationCode : "",
      },
      Terms: {
        ESignConsent: true,
        CreditProtection: false,
        TcAcknowledgement: true,
        EmailOptIn: true,
        CardColorCode: "",
      },
      AdditionalCardholder: {
        AdditionalCardholder:
        info.hasAdditionalCardholder === "true" ? true : false,
        AdditionalCardholderFirstName: info.additionalCardholderFirstName || "",
        AdditionalCardholderMI: info.additionalCardholderMI || "",
        AdditionalCardholderLastName: info.additionalCardholderLastName || "",
        AdditionalCardholderSuffix: info.additionalCardholderSuffix || "",
      },
      SpouseInformation: {
        SpouseHasDifferentAddress: info.spouseHasDifferentAddress || "false",
        SpouseFirstName: info.spouseFirstName || "",
        SpouseMiddleName: info.spouseMiddleInitial || "",
        SpouseLastName: info.spouseLastName || "",
        SpouseSuffix: info.spouseSuffix || "",
        SpouseAddress1: info.spouseAddress1 || "",
        SpouseAddress2: info.spouseAddress2 || "",
        SpouseCity: info.spouseCity || "",
        SpouseState: info.spouseState || "",
        SpouseZip: info.spouseZip || "",
        SpouseEmail: info.spouseEmail || "",
        IsMarried: info.isMarried || "false",
      },
      Tracking: {
        SessionId: sessionStorage.getItem("oldSessionToken"),
        SrcId: "",
        SID: applicantApiClient.findParam("sid") || "",
        PubId:(applicantApiClient.findParam("pub") || applicantApiClient.findParam("pubid")) || siteSetting.productSettings.PubId,
        SubId: info.subId,
        HID: applicantApiClient.findParam("hid") || "",
        C1: applicantApiClient.findParam("c1") || "",
        C2: applicantApiClient.findParam("c2") || "",
        C3: applicantApiClient.findParam("c3") || "",
        Gclid: applicantApiClient.findParam("gclid") || "",
        GAdsExpirationDate: info.gAdsExpirationDate,
        Fbclid: applicantApiClient.findParam("fbclid") || "",
        FbAdsExpirationDate: info.fbAdsExpirationDate,
        Msclid: applicantApiClient.findParam("msclid") || "",
        MsAdsExpirationDate: info.msAdsExpirationDate,
        TransunionBlackBox: sessionStorage.getItem("blackBox") || "",
      },
      SiteSettings: {
        WebSite: siteSetting.domain,
        EngineID: 0,
        IsPrequal: true,
        PID: siteSetting.productSettings.PID,
        ProductCode: siteSetting.mainSettings.ProductCode,
        ProductId: siteSetting.productSettings.ProductId,
        AppRef: siteSetting.productSettings.ApplicationSourceReference
          ? siteSetting.productSettings.ApplicationSourceReference
          : "",
        Step: info.step,
        TermsId: siteSetting.mainSettings.TermsId,
        Token: applicantApiClient.findParam("token") || "",
      },
      HasCardColorChoice: siteSetting.mainSettings.HasCardColorChoice
    };

    let cardOffersResponse = await applicantApiClient.post(
      "submit-application",
      applicantApiData
    );

    if (cardOffersResponse) {
      if(cardOffersResponse.IsGrandbank === true){
          setGrandBanckDeclined(true)
      }

      if (cardOffersResponse.result != null) {               
          mainSettings.ProductCode = cardOffersResponse.product_code;
          cardOffersResponse.ResultWebPageHash = (cardOffersResponse.result.toLowerCase() === "sys_error") ? "sys_error" : cardOffersResponse.ResultWebPageHash;
          cardOffersResponse.decline_message = (cardOffersResponse.decline_message === "") ? "We are unable to verify your identity." : cardOffersResponse.decline_message;
          setApplicationResult(cardOffersResponse);
          const { ResultWebPageHash } = cardOffersResponse;
          if(cardOffersResponse.result.toUpperCase() === 'FROZEN'){
              cardOffersResponse.decline_message = "We were unable to obtain a copy of your credit report as an identifier is required for access.  In order to continue processing your application you must send us your Experian identifier to the address listed above. We must receive this information within 30 days from the date of this letter. If we do not receive it by that date, we will regrettably be unable to give further consideration to your credit request.";
          }
         
          let Traffic = JSON.parse(sessionStorage.getItem("trafficInfo"));
          if(Traffic.Provider !== "Organic" && Traffic.Provider !== "Facebook" && Traffic.Provider !== "Google" && Traffic.Provider !== "Microsoft"){
              applicantApiClient.post('insert-click-tracking', {
                  source  : Traffic.Provider,
                  clickId : Traffic.ClickId,
                  
                  website : "https://" + window.location.hostname,
                  applicantId : cardOffersResponse.ref_number,
                  expirationDate : moment().format("YYYY-MM-DD"),
                  medium : Traffic.UtmMedium,
                  campaign : Traffic.UtmCampaign,
                  sessionId : sessionStorage.getItem("oldSessionToken"),
                  productId : productSettings.ProductId

              });
          }
          utms.updateAppId(cardOffersResponse.ref_number || "");
          utms.updateSessionReport("appResult", ResultWebPageHash.toUpperCase())
          utms.updateSessionReport("applicantId", cardOffersResponse.ref_number)
          
          saveIcommPostbackqueue(cardOffersResponse.ref_number, "Submitted", 2); 
         
          if (pendingInfo.includes(cardOffersResponse.result.toUpperCase()) || declinedResults.includes(cardOffersResponse.result.toUpperCase()) || cardOffersResponse.result.toUpperCase() === 'DENIED' || cardOffersResponse.result.toUpperCase() === 'DECLINED' || cardOffersResponse.result.toUpperCase() === 'SYS_ERROR' ||  cardOffersResponse.result.toUpperCase() === 'FROZEN') {
              
              if(pendingInfo.includes(cardOffersResponse.result.toUpperCase())){
                  setApplicantData({ index: "FirstName", value: applicantApiData.Applicant.FirstName } );
                  setApplicantData({ index: "LastName", value: applicantApiData.Applicant.LastName } );
                  
                  setApplicationResult({index: "ref_number", value: cardOffersResponse.ref_number } );
                  
              }
            
              setdisplayResult(true)
              window.scrollTo(0, 0);
              if (pendingInfo.includes(cardOffersResponse.result.toUpperCase())) {
                  history.push("/pendinfo"+window.location.search)
              }else{
                  history.push("/declined"+window.location.search)
              }
              
              return;
          }
          else if (cardOffersResponse.result.toUpperCase() === 'APPROVED') {
              setdisplayTerms(true);
              
              window.scrollTo(0, 0);
              history.push("/terms"+window.location.search)
              return;
          }
      }
      else {
        setError(cardOffersResponse.validationResult.FieldName);
        setBackendError(
          `Error in ${cardOffersResponse.validationResult.FieldName}`
        );
      }

      setDisplayHeaderTerms(false);
  }else {
      setBackendError("An unknown error has occured. Please try again.");
      setIsLoading(false);
    }
  };
  
  async function submit(fieldValueObject) {
    setIsLoading(true);
    let foundErrors = false;
    Object.keys(fieldValueObject).forEach((fieldName) => {
      const result = validatorService.validateFieldWithData(
        fieldName,
        fieldValueObject[fieldName]
      );
      if (result.errorMessages.length > 0) {
        setError(fieldName, { type: "string", message: result.errorMessages });
        foundErrors = true;
      }
    });
    if (!foundErrors) {
      await submitData();
    }
  }

  return (
    <div className="container appFormsBackground">
      <h6 className="displayFlexCenter">
        <strong>STEP 5/5</strong>
      </h6>
      <div className="steperContainer">
        <Stepers step={5} totalSteps={5} />
      </div>
      <div className="row">
        <div className="col-12">
          <h6>
            <strong>{text.block1.title}</strong>
          </h6>
        </div>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="authorizationContainer">
                <div className="authorizationCheckboxContainer">
                  <input
                    type="checkbox"
                    className="authorizationCheckbox"
                    checked={info.authPrequal === "1"}
                    value={info.authPrequal}
                    {...register("authPrequal")}
                    onClick={(e) => {
                      if (info.authPrequal === "1") {
                        setInfo({
                          index: "authPrequal",
                          value: "0",
                        });
                      } else {
                        setInfo({
                          index: "authPrequal",
                          value: "1",
                        });
                      }
                    }}
                  />
                </div>
                <p className="authorizationText">
                  I Authorize Pre-qualification. I have read and agree to the{" "}
                  <a
                    href={productSettings.ESignUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Electronic Communications Agreement
                  </a>
                  ,{" "}
                  <a
                    href="https://surge.creditcard/privacy.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href={"/userTerms" + window.location.search}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Agreement
                  </a>
                  .
                </p>
              </div>

              {errors.authPrequal &&
                errors.authPrequal.message.map((message) => (
                  <div className="error-message"> {message} </div>
                ))}
            </div>
            <div className="prequalAuthDisclaimerText">
              {text.form.prequalAuthDisclaimer}
            </div>
          </div>
        </div>

        <div className="form-group">
          {useRecaptcha ? 
            <div className="form-group displayFlexCenter">
              <Recaptcha
                setRecaptchaDisabled={setRecaptchaDisabled}
                ProductName={brandSettings.ProductName}
              />
            </div>
           : 
            <></>
          }
        </div>
        <div className="form-group">
          <Button
            bsPrefix="mastercardOrangeBg seeMyOffersButton"
            disabled={isLoading || info.authPrequal !== "1" || useRecaptcha}
            id="btn-see-offers"
            className="btn"
            type="submit"
          >
            {isLoading ? "Submitting..." : "See My Offers"}
          </Button>
          {backendError !== "" && (
            <div className="error-message"> {backendError} </div>
          )}
        </div>
        <div className="buttonGroup">
          <button
            id="btn-back"
            className="btnbackLastStep"
            onClick={() => {
              history.push("/step4" + window.location.search);
            }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step5;
