import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useFormContext } from "react-hook-form";

import '../../../assets/terms/e-disclosure.css';

function Esign() {

    const { register,  formState: { errors } } = useFormContext(); 
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const info = useStoreState((state) => state.applicantModel.applicant);  
    const Setting = useStoreState((state) => state.siteSettingsModel); 
    const activeCheck = useStoreState((state) => state.siteSettingsModel.activateChecks); 

  return (
    <div id="e-sign" className="col-12">
        <div id="content-1">
            <p><b>Electronic Communications Disclosure (this "Disclosure")</b></p>
            <p>
                We need your permission before we can provide you with electronic versions of legally required notices and documents associated with
                your account or application. Examples of the types of documents that may be provided electronically include but are not limited to, your
                Cardholder Agreement, account statements, and similar legally required notices associated with your credit card application or account.
            </p>
            <p>
                Providing your consent means that we may provide electronic versions of legally required documents in accordance with your preferences.
            </p>
            <p><b>Method of Delivery</b></p>
            <p>
                Electronic documents will be provided to you and may be delivered either via the email address you provided or within your secure online
                or mobile session. Please save or print your electronic documents for future reference.
            </p>
            <p><b>System Requirements</b></p>
            <p>
                Your access device (defined as the electronic device you use to access your account and view electronic documents) must meet the following
                requirements:
            </p>
            <p>
                <ol>
                    <li>Supported operating system capable of displaying .pdf files</li>
                    <li>Internet access</li>
                    <li>Storage space to save your electronic files</li>
                    <li>A printer if you wish to print your electronic documents</li>
                </ol>
            </p>
            <p>
                The aforementioned system requirements apply to all access devices you may use to access your account and view electronic documents. If
                you change access devices (or use multiple access devices), it is your responsibility to ensure that the other access devices meet these
                system requirements and that you are still able to access and view your electronic documents.
            </p>
            <p><b>Changes to System Requirements</b></p>
            <p>
                We will notify you if the terms of this Disclosure change in such a way that may prevent you from accessing or viewing your electronic documents.
            </p>
            <p><b>Withdrawal of Consent</b></p>
            <p>
                Contact us as described below if you wish to withdraw your consent to this Disclosure. If you do not accept this Disclosure, or you otherwise
                withdraw your consent, you will receive paper versions of the legally required documents associated with your account.
            </p>
            <p>
                At our option, we may treat the provision of an invalid e-mail address, or the subsequent malfunction of a previously valid e-mail address,
                as a withdrawal of your consent to receive communications by electronic delivery.
            </p>
            <p><b>Need Help or Paper Copies</b></p>
            <p>
                You have the right to receive paper copies of electronically presented documents. Contact us if you have problems viewing your electronic
                documents, to request paper copies, manage your delivery preferences, or withdraw consent to this Disclosure.
            </p>
            <p>1-866-449-4514</p>
            <p>P.O. Box 3220, Buffalo, NY 14240-3220</p>
        </div>
        <div id="content-2">
                {
                    activeCheck && (
                        <div>
                             <ul>
                            <li><input checked={info.eSignConsent === "1" ? true : false} value={info.eSignConsent} onClick={() => { setInfo({ index: "eSignConsent", value: info.eSignConsent === "1" ? "0" : "1" });}} type="checkbox" {...register("eSignConsent")} />&nbsp;&nbsp;<span className="d-md-none">I agree to the e-sign disclosure and consent</span></li>
                            <li><label className="d-none d-md-block">I agree to the e-sign disclosure and consent</label></li>
                        </ul>                
                        {errors.eSignConsent && errors.eSignConsent.message.map((message) => (
                            <div className="error-message" key={"eSignConsent-" + message}> You must agree before continue. </div>
                        ))}
                        </div>
                    )
                }
            <div className='col-lg-6 col-md-7 col-sm-12 col-xs-12'>
                <ul>
                    <li><span className="icon"><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;<a href={Setting.productSettings.ESignUrl} target="_blank" rel="noopener noreferrer" className="d-md-none print-text">Electronic Communications Disclosure</a></span></li>
                    <li><a href={Setting.productSettings.ESignUrl} target="_blank" rel="noopener noreferrer"  className="d-none d-md-block print-text">Electronic Communications Disclosure</a></li>
                </ul>                 
            </div>
        </div>
    </div>
  )
}

export default Esign       