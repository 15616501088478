import React from 'react';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';


function Security() {

    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);

    return (
        <div id="add-on-security" className="add-ons-section">
            <div className="col-lg-12">
                <h2><b>Security People Can Rely On</b></h2>
            </div>
            <div className="col-lg-12">
                <h4>
                    <FontAwesomeIcon icon={faLock} />&nbsp;
                    This is a secure site.
                </h4>
            </div>
            <div className="col-lg-12">
                <p><b>Ways we protect you and your information:</b></p>
                <p>
                    We use 128-bit encryption technology to protect your personal information when you’re using our site or apps.
                    You’ll know your information is fully encrypted when the URL of the page you’re on starts with “https://” and
                    you see a lock symbol in your web browser.
                </p>
                <p>
                    <a href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">See Privacy Policy</a>
                </p>
            </div>
        </div>
    );
}

export default Security;