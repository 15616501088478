import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import './../../../assets/layout/abandon.css';

const InvalidState = (props) => {
    const info = useStoreState(state => state.siteSettingsModel.brandSettings);
    const MainPath = useStoreState(state => state.siteSettingsModel.MainPath);
    const resultStep = useStoreActions(actions => actions.setDisplayResult);

    return (
        <Modal
            show={props.visible} 
            backdrop="static"
            id="invalid-state-modal"
            style={{top : "31%"}}
        >
            <Modal.Header
            >
                <div
                    className='close-icon'
                >
                   
                </div>
                <div
                    className='d-flex flex-column justify-content-center align-items-center invalidState'
                >
                  Thank you for your interest in {info.ProductName} Mastercard!
                </div>
            </Modal.Header>
            <Modal.Body
                className='modal-body'
            >
                <div
                    className='modal-body-text-container'
                >
                    <div className='row'><span >This offer is not available for residents of Puerto Rico, U.S. Territories, Colorado, Illinois, Massachusetts and West Virginia</span></div>
                    <div className='row'><Link to={MainPath+"declined"+window.location.search} onClick={ () => {window.scrollTo(0, 0); resultStep(true);}} className='btn btn-secondary mt-3 mx-auto'>Close</Link></div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default InvalidState;
