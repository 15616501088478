import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Security from '../../add-ons/security';
import Timeout from '../../add-ons/timeout';
import Reputation from '../../add-ons/reputation';
import ReflexMicroComboTB from '../../add-ons/trustbox/reflex/microcombo';
import ReflexSliderTB from '../../add-ons/trustbox/reflex/slider';
import SurgeMicroComboTB from '../../add-ons/trustbox/surge/microcombo';
import SurgeSliderTB from '../../add-ons/trustbox/surge/slider';
import Features from '../../add-ons/features';
import { Button, Modal, Container, Row } from 'react-bootstrap';
import {IncomeMask} from '../../../../services/masking'
import ErrorModal from '../components/ErrorModal';
import { Controller, useFormContext } from "react-hook-form";
import { getValidation, formatDOB, formatSSN, requireRecaptcha } from '../validationRules'
import MaskedInput from 'react-text-mask';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CheckBox from '../components/checkBox/CheckBox';
import { ErrorAlert } from '../components/errorAlert/ErrorAlert';
import AxiosWrapper from '../../../../services/axios';
import ReactGA from 'react-ga4';
import Recaptcha from '../components/recaptcha/Recaptcha';
import marketing from "../../../../services/marketing";
import moment from "moment";
import InvalidStateModal from '../../../../components/dialog/invalidState/InvalidState';
import '../../../../assets/prequal/prequal.css';
import { useHistory } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import { masking, unmasking } from '../../../../services/masking';
import AudioEyeSupport from '../../../../services/audioEyeSupport';
import PixelService from '../../../../services/pixels';
import ConcentSms from '../../../flow/global-elements/smsConcent';


function PrequalForm({ basePath }) {
    //form validation
    const ada = new AudioEyeSupport();
    const text = useStoreState((state) => state.dmText.terms);
    const [cursor, setCursorPosition] = useState(1)
    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const formFields = useStoreState(state => state.siteSettingsModel.formFields);
    const Pixels = useStoreState(state => state.siteSettingsModel.Pixels);
    const useRecaptcha = useStoreState(state => state.siteSettingsModel.useRecaptcha);
    const setIdList = useStoreActions(actions => actions.siteSettingsModel.setIdList);
    const setInvalidState = useStoreActions(actions => actions.siteSettingsModel.setInvalidState);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const invalidState = useStoreState(state => state.siteSettingsModel.invalidState);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const statesList = useStoreState(state => state.siteSettingsModel.statesList);
    const setShowOnDeclined = useStoreActions(actions => actions.siteSettingsModel.setShowOnDeclined);
    const setGrandBanckDeclined = useStoreActions(actions => actions.siteSettingsModel.setGrandBanckDeclined);
    const validations = useStoreState(state => state.siteSettingsModel.validationRules);
    const applicantData = useStoreState(state => state.applicantModel.applicant);
    const setInitPixels = useStoreActions(actions => actions.setInitPixels);
    const initPixels = useStoreState(state => state.initPixels);
    const setDisplayHeaderTerms = useStoreActions(actions => actions.setDisplayHeaderTerms)
    const setApplicantData = useStoreActions(actions => actions.applicantModel.setApplicant);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const idList = useStoreState(state => state.siteSettingsModel.idList);
    const domain = useStoreState(state => state.siteSettingsModel.domain);
    const applicantApiClient = new AxiosWrapper();
    const applicantResponse = useStoreState(state => state.applicantResponse);
    const PixelsService = new PixelService();
    
    const history = useHistory();
  
    const { setValue, getValues, watch, setFocus, setError, control, handleSubmit, register, formState: { errors }, formState, clearErrors} = useFormContext();
    const city = watch("city", "");
    const state = watch("state", "");
    const zip = watch("zip", "");
    const additionalCardholderVal = watch('additionalCardholder');
    const [spouseFormDisplay, setSpouseFormDisplay] = useState(false);
    const [spouseInfoFormDisplay, setSpouseInfoFormDisplay] = useState(false);
    const [spouseAddressFormDisplay, setSpouseAddressFormDisplay] = useState(false);
    const [addtlCHFormDisplay, setAddtlCHFormDisplay] = useState(false);
     // eslint-disable-next-line no-unused-vars
    const [selectedState, setSelectedState] = useState(applicantData.state);
    const [selectedSpouseState, setSelectedSpouseState] = useState("");
    const [isMarried, setIsMarried] = useState("");
    const [spouseHasDifferentAddress, setSpouseHasDifferentAddress] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [monthlyIncome, setMonthlyIncome] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [disableField, setDisableField] = useState(false);

    const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
    const [recaptcha] = useState(() => {
        return requireRecaptcha(validations);
    });
    const [dob, setDOB] = useState("");
    const [primaryPhone, setPhone1] = useState();
    const [phone2, setPhone2] = useState("");
    const [ssn, setSSN] = useState("");   
    const [tempMonthlyIncome, setTempMonthlyIncome] = useState("");
    const closeError = () => setErrorShow(false)
    const [submitting, setSubmitting] = useState(false)
     /**Only run validation after initial mount on SSN and Monthly Income**/
    const didMountSSN = useRef(false);
    const primaryPhoneRef = useRef();
    const didMountMonthlyIncome = useRef(false);

    // Input error messages
    const textInputErrorMsg = "This field is missing or invalid. Please fill it correctly.";
    const selectInputErrorMsg = "This field is missing. Please select an option.";
    const formCheckInputErrorMsg = "This option is missing. Please check it."
    const checkboxInputErrorMsg = "This field is missing. Please select an option.";
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const utms = new marketing();
    const pendingInfo = ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL", "CVILOW"];
    const declinedResults = ["DECLINEDCBR", "DECLINEDINFO", "DECLINEDPENDINFO", "LCLCURRPFD", "LCLCURRPFEE"];
    const flinkClient = new AxiosWrapper(process.env.REACT_APP_DE_BASE_URL);

    useEffect(() => {

        setValue("firstName", applicantApiClient.findParam("firstname") || "");
        setValue("middleName", applicantApiClient.findParam("middleinitial") || "");
        setValue("suffix", applicantApiClient.findParam("suffix") || "");
        setValue("lastName", applicantApiClient.findParam("lastname") || "");
        setValue("address1", applicantApiClient.findParam("address1") || "");
        setValue("address2", applicantApiClient.findParam("address2") || "");
        setValue("state", applicantApiClient.findParam("state") || "");
        setValue("city", applicantApiClient.findParam("city") || "");
        setValue("email", applicantApiClient.findParam("email") || "");
        setValue("zip", applicantApiClient.findParam("zip") || "");
        if( applicantApiClient.findParam("hascheckingaccount")) {
            
            setValue("hasCheckingAccount", true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(flinkClient.findParam("fhash")){
            flinkClient.post("api/flinks/RetrieveFlinksStatus", { receiptId : flinkClient.findParam("fhash") }).then((result) => {
                if(result){
                    setApplicationResult(result);
                    setGrandBanckDeclined(result.IsGrandbank === true ? true : false)
                    setdisplayTerms(true);
                    if(result.result.toUpperCase() === "APPROVED"){ history.push("/terms" + window.location.search); return; }
                    setdisplayResult(true)
                    history.push("/declined" + window.location.search)
                    return;
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getFieldData = (fieldName) => formFields.find(field => field.fieldName === fieldName);
    const checkIfFieldIsActive = (fieldName) => getFieldData(fieldName)?.isActive;
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);


    useEffect(() => {
        
        if(!initPixels){
            var scr = document.createElement("script");
            scr.type = "text/javascript";
            scr.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
            document.head.appendChild(scr);
            utms.mapper();
            ada.setup();
            saveIcommPostbackqueue("", "Landed", 1);
            utms.pushnami(brandSettings.ProductName);
           
           
            PixelsService.initialize().pageView("/landing").event("google", "tracking", "landing");

            getRoutingSettings({ Url: domain, Route: window.location.pathname});
            
            setInitPixels(true)
            setAlResult(false)
           
        }
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

 

    useEffect(()=>{
        if (applicantResponse) {
            const applicantData = applicantResponse.applicantResponse;

            setValue('firstName', applicantData.FirstName);
            setValue('middleName', applicantData.MiddleName);
            setValue('lastName', applicantData.LastName);
            setValue('suffix', applicantData.Suffix);
            setValue('address1', applicantData.Address1);
            setValue('address2', applicantData.Address2);
            setValue('city', applicantData.City);
            setValue('state', applicantData.State);
            setValue('zip', applicantData.Zip);
            setValue('email', applicantData.Email);
            setValue('primaryPhone', applicantData.PrimaryPhone);
            setValue('secondaryPhone', applicantData.SecondaryPhone);

            // Format from 
            if (applicantData.DOB) {
                const date = new Date(applicantData.DOB);
                setDOB(moment(date).format('MM-DD-YYYY'));
                setValue('dob', moment(date).format('MM-DD-YYYY'));
            }
            
            setValue('ssn', applicantData.SSN);
            setMonthlyIncome(applicantData.MonthlyIncome);
            setValue('incomeSource', applicantData.IncomeSource);

            setValue('hasCheckingAccount', applicantData.HasCheckingAccount);
            setValue('cashIntent', applicantData.CashIntent);
            setValue('reservationCode', applicantData.ReservationCode);

            setValue('additionalCardholder', applicantData.AdditionalCardholder);
            setValue("hasAdditionalCardholder", String(applicantData.AdditionalCardholder));

            setValue('additionalCardholderFirstName', applicantData.AdditionalCardholderFirstName);
            setValue('additionalCardholderMI', applicantData.AdditionalCardholderMI);
            setValue('additionalCardholderLastName', applicantData.AdditionalCardholderLastName);
            setValue('additionalCardholderSuffix', applicantData.AdditionalCardholderSuffix);
            
            if (applicantData.SpouseFirstName) {
                setSpouseFormDisplay(true);
                setSpouseInfoFormDisplay(true);
                if (applicantData.SpouseAddress1) {
                    setSpouseAddressFormDisplay(true);
                }
                setValue('spouseHasDifferentAddress', applicantData.SpouseHasDifferentAddress);
                setValue('spouseFirstName', applicantData.SpouseFirstName);
                setValue('spouseLastName', applicantData.SpouseLastName);
                setValue('spouseSuffix', applicantData.SpouseSuffix);
                setValue('spouseAddress1', applicantData.SpouseAddress1);
                setValue('spouseAddress2', applicantData.SpouseAddress2);
                setValue('spouseCity', applicantData.SpouseCity);
                setValue('spouseState', applicantData.SpouseState);
                setValue('spouseZip', applicantData.SpouseZip);
                setValue('spouseEmail', applicantData.SpouseEmail);
            }

            setValue('isMarried', applicantData.IsMarried);
            setValue('over18', applicantData.Over18);
            setValue('authPrequal', applicantData.AuthPrequal);
            setValue('emailOptIn', applicantData.EmailOptIn);
            setValue('eSignConsent', applicantData.ESignConsent);
                clearErrors('dob');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[applicantResponse])

    useEffect(() => {
        setAddtlCHFormDisplay(additionalCardholderVal);
    }, [additionalCardholderVal])

    useEffect(() => {
        if (!recaptcha) {
            setRecaptchaDisabled(false)
        } else {
            setRecaptchaDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recaptcha]);

    useEffect(() => {
        setValue("state", selectedState, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState]);
    useEffect(() => {
        setValue("spouseState", selectedSpouseState, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSpouseState]);

    useEffect(() => {
        setValue("dob", dob, { shouldValidate: false });
        if (dob !== "") {
            if (!moment(dob).isValid()) {
                setError("dob");
            } else {
                const [rules] = validations.filter((validationRule) => validationRule.ValidationType === 'dob');
                const date = new Date();
                const today = moment(date.now);
                const bd = moment(dob, "YYYY-MM-DD").format("YYYY-MM-DD");
                const age = today.diff(bd, 'years');
                let ageToEval = rules.MinAge;
                if (age < ageToEval) {
                    setError("dob");
                } else {
                    clearErrors("dob");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dob]);

    useEffect(() => {
        clearErrors("city");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, zip]);

    useEffect(() => {
        if (didMountSSN.current) {
            setValue("ssn", ssn, { shouldValidate: true });
        } else {
            didMountSSN.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssn]);

    useEffect(() => {
        setValue("primaryPhone", primaryPhone, { shouldValidate: false });
        clearErrors("primaryPhone");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryPhone]);

    useEffect(() => {
        setValue("secondaryPhone", phone2, { shouldValidate: false });
        clearErrors("secondaryPhone")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone2]);

    useEffect(() => {
        if (didMountMonthlyIncome.current) {
            if (monthlyIncome) {
                // eslint-disable-next-line no-sparse-arrays
                const monthlyIncomeNo = monthlyIncome.split([,]).filter(char => char !== '$' && char !== ',').join('');
                setValue("monthlyIncome", monthlyIncomeNo, { shouldValidate: false });
                if (monthlyIncomeNo > 0) {
                    clearErrors("monthlyIncome")
                } else {
                    setError('monthlyIncome', { type: 'custom', message: 'invalid monthly income. The amount must greater than 0' });
                }
            } else {
                setError('monthlyIncome', { type: 'custom', message: 'invalid monthly income' });
            }
        } else {
            didMountMonthlyIncome.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthlyIncome]);

    useEffect(() => {
        setValue("isMarried", isMarried, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMarried]);

    useEffect(() => {
        setValue("spouseHasDifferentAddress", spouseHasDifferentAddress, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spouseHasDifferentAddress]);

    useEffect(() => {
        //register("state", getValidation("state", validations));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (Object.entries(errors).length === 0) ? setShowAlert(false) : setShowAlert(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    const btnSubmitAppStyle = {
        backgroundColor: brandSettings.Primary
    };

    useEffect(() => {
       
        setSpouseHasDifferentAddress("");
        if (state === "WI") {
            setSpouseFormDisplay(true);
        }
        else {
            setSpouseFormDisplay(false);
            setSpouseInfoFormDisplay(false);
            setSpouseAddressFormDisplay(false);
        }
    },[state])

    const displaySpouseInfoForm = (val) => {
        if (val.toUpperCase() === "TRUE") {
            setSpouseInfoFormDisplay(true);
            setIsMarried(val);
        }
        else {
            setIsMarried(val);
            setSpouseInfoFormDisplay(false);
            setSpouseHasDifferentAddress(false);
        }
    };

    const displaySpouseAddressForm = (val) => {
        if (val.toUpperCase() === "TRUE") {
            setSpouseAddressFormDisplay(true);
            setSpouseHasDifferentAddress(val);
        }
        else {
            setSpouseHasDifferentAddress(val);
            setSpouseAddressFormDisplay(false);
        }
    };

    const handleSpouseStateChange = (val) => {
        setSelectedSpouseState(val);
    }

    

    const displayAddtCHForm = (val) => {
        if (val.toUpperCase() === "TRUE") {
            setAddtlCHFormDisplay(true);
            
            setValue("hasAdditionalCardholder", val, { shouldValidate: true });
        }
        else {
            setAddtlCHFormDisplay(false);
           
            setValue("hasAdditionalCardholder", val, { shouldValidate: true });
        }
    };

    const saveIcommPostbackqueue = (refNo, siteEvent, step) => {
        let utms = new marketing();
        utms.trackEvent(step, "Prequal", refNo, productSettings.ProductId, "Flow");
        if (productSettings.TrafficSource.toLowerCase().match(new RegExp(/icomm/g))) {
            utms.postBackQueue({
                idList: idList,
                applicantId: refNo,
                productId: productSettings.ProductId,
                siteEvent: siteEvent,
                hid: applicantApiClient.findParam("hid") || "",
                productCode: mainSettings.ProductCode,
                pubId: (applicantApiClient.findParam("pub") || applicantApiClient.findParam("pubid")) || ""
            }).then((payload) => {
                if (idList === "") {
                    setIdList(payload);
                }
                else {
                    setIdList(idList + "," + payload);
                }
            })
        }
    }

    const getFieldMaxLength = (name) => {
        let filtered = validations.filter( o => o.ValidationType === name);
        return parseInt(filtered[0].MaxLengthChar);
    }


    const delay = ms => new Promise(res => setTimeout(res, ms));
    const appSubmit = async (data) => {

       

        try {

            if(data.monthlyIncome.replace("$","").replace(",","") === "" || data.monthlyIncome.replace("$","").replace(",","") === "0"){
                setError('monthlyIncome', { type: 'custom', message: 'invalid monthly income. The amount must greater than 0' });
                return
            }

            let utms = new marketing()
            if(Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
                ReactGA.event({category: "tracking", action: "ClickSeeMyCardOffersButton",});
            }
            
            PixelsService.event("Pinterest","custom","ClickSeeMyCardOffersButton");
            setLoading(true);
            setSubmitting(true);
            setDisableField(true);
            data["checkNonBusinessState"] = true;
            const response = await applicantApiClient.post('validate-state', {
                FirstName: data.firstName,
                LastName: data.lastName,
                Address1: data.address1,
                Address2: data.address2,
                City: data.city,
                State: data.state,
                Zip: data.zip,
                Product: brandSettings.ProductName,
                checkNonBusinessState: true
            });
            if (response.Result === 'INVALIDSTATE' ) {
                setInvalidState(true)
               
                setShowOnDeclined(false)
                const cardOffersResponse = {};
                cardOffersResponse.result = "denied";
                cardOffersResponse.ResultWebPageHash = "invalid_state"
                cardOffersResponse.decline_message = "We do not currently do business in the state that you reside in";
                setApplicationResult(cardOffersResponse);
      
                return;
            }
            if (
                response.Result === 'MISMATCH'
                || response.Result === 'PHONEDUPE'
                || response.Result === 'EMAILDUPE'
            ){
                if (response.Result === "MISMATCH") {
                    setError("city");
                    setFocus("city");
                } else {
                    setErrorMessage(response.Message);
                    setErrorShow(true);
                }
                setLoading(false);
                setSubmitting(false)
                setDisableField(false);
            }
            else {

                let EFname = data.additionalCardholderFirstName || "";
                let Fname = data.firstName;

                if( EFname.toLowerCase() === Fname.toLowerCase()){
                    setError('additionalCardholderFirstName')
                    setFocus('additionalCardholderFirstName')
                    setLoading(false);
                setSubmitting(false)
                setDisableField(false);
                    return;
                }
                setApplicantData({ index: "Email", value: data.email });

                let applicantApiData = {
                    Applicant: {
                        FirstName: data.firstName,
                        MiddleName: data.middleName,
                        LastName: data.lastName,
                        Suffix: data.suffix,
                        Address1: data.address1,
                        Address2: data.address2,
                        City: data.city,
                        State: data.state,
                        Zip: data.zip,
                        Email: data.email,
                        PrimaryPhone: primaryPhone,
                        SecondaryPhone: phone2,
                        DOB: data.dob,
                        SSN: data.ssn,
                        MonthlyIncome: data.monthlyIncome.replace("$","").replace(",",""),
                        IncomeSource: data.incomeSource,
                        HasCheckingAccount: data.hasCheckingAccount,
                        cashIntent: data.cashIntent || false,
                        ReservationCode: data.reservationCode ? data.reservationCode : "",
                    },
                    Terms: {
                        ESignConsent: true,
                        CreditProtection: false,
                        TcAcknowledgement: 0,
                        EmailOptIn: true,
                        CardColorCode: applicantData.cardColorCode,
                        SMSConsent : applicantData.SMSConsent
                    },
                    AdditionalCardholder: {
                        AdditionalCardholder: data.hasAdditionalCardholder || "false",
                        AdditionalCardholderFirstName: data.additionalCardholderFirstName || "",
                        AdditionalCardholderMI: data.additionalCardholderMI || "",
                        AdditionalCardholderLastName: data.additionalCardholderLastName || "",
                        AdditionalCardholderSuffix: data.additionalCardholderSuffix || "",
                    },
                    SpouseInformation: {
                        SpouseHasDifferentAddress: data.spouseHasDifferentAddress || "false",
                        SpouseFirstName: data.spouseFirstName || "",
                        SpouseMiddleName: data.spouseMiddleName || "",
                        SpouseLastName: data.spouseLastName || "",
                        SpouseSuffix: data.spouseSuffix || "",
                        SpouseAddress1: data.spouseAddress1 || "",
                        SpouseAddress2: data.spouseAddress2 || "",
                        SpouseCity: data.spouseCity || "",
                        SpouseState: data.spouseState || "",
                        SpouseZip: data.spouseZip || "",
                        SpouseEmail: data.spouseEmail || "",
                        IsMarried: data.isMarried || "false",
                    },
                    Tracking: {
                        SessionId: sessionStorage.getItem("oldSessionToken"),
                        SrcId: "",
                        SID: applicantApiClient.findParam("sid") || "",
                        PubId:(applicantApiClient.findParam("pub") || applicantApiClient.findParam("pubid")) || productSettings.PubId,
                        SubId: applicantData.subId,
                        HID: applicantApiClient.findParam("hid") || "",
                        C1: applicantApiClient.findParam("c1") || "",
                        C2: applicantApiClient.findParam("c2") || "",
                        C3: applicantApiClient.findParam("c3") || "",
                        Gclid:(JSON.parse(sessionStorage.getItem("trafficInfo"))["Provider"] === "Google")? JSON.parse(sessionStorage.getItem("trafficInfo"))["ClickId"] : "",
                        GAdsExpirationDate: applicantData.gAdsExpirationDate,
                        Fbclid: applicantApiClient.findParam("fbclid") || "",
                        FbAdsExpirationDate: applicantData.fbAdsExpirationDate,
                        Msclid: applicantApiClient.findParam("msclid") || "",
                        MsAdsExpirationDate: applicantData.msAdsExpirationDate,
                        TransunionBlackBox: sessionStorage.getItem("blackBox") || ""
                    },
                    SiteSettings: {
                        WebSite: domain,
                        EngineID: 0,
                        IsPrequal: applicantData.isPrequal,
                        PID: productSettings.PID,
                        ProductCode: '',
                        ProductId: productSettings.ProductId,
                        AppRef: productSettings.ApplicationSourceReference ? productSettings.ApplicationSourceReference : "",
                        Step: applicantData.step,
                        TermsId: mainSettings.TermsId,
                        Token: applicantApiClient.findParam("token") || "",
                        Browser: navigator.userAgent.toLowerCase()
                    },
                    HasCardColorChoice: mainSettings.HasCardColorChoice
                }

               
                
                
                let cardOffersResponse = await applicantApiClient.post('submit-application', applicantApiData);
                
                if (cardOffersResponse) {
                    if(cardOffersResponse.IsGrandbank === true){
                        setGrandBanckDeclined(true)
                    }

                    if (cardOffersResponse.result != null) {
                        mainSettings.ProductCode = cardOffersResponse.product_code;
                        cardOffersResponse.ResultWebPageHash = (cardOffersResponse.result.toLowerCase() === "sys_error") ? "sys_error" : cardOffersResponse.ResultWebPageHash;
                        cardOffersResponse.decline_message = (cardOffersResponse.decline_message === "") ? "We are unable to verify your identity." : cardOffersResponse.decline_message;
                        setApplicationResult(cardOffersResponse);
                        const { ResultWebPageHash } = cardOffersResponse;
                        if(cardOffersResponse.result.toUpperCase() === 'FROZEN'){
                            cardOffersResponse.decline_message = "We were unable to obtain a copy of your credit report as an identifier is required for access.  In order to continue processing your application you must send us your Experian identifier to the address listed above. We must receive this information within 30 days from the date of this letter. If we do not receive it by that date, we will regrettably be unable to give further consideration to your credit request.";
                        }
                       
                        let Traffic = JSON.parse(sessionStorage.getItem("trafficInfo"));
                        if(Traffic.Provider !== "Organic" && Traffic.Provider !== "Facebook" && Traffic.Provider !== "Google" && Traffic.Provider !== "Microsoft"){
                            applicantApiClient.post('insert-click-tracking', {
                                source  : Traffic.Provider,
                                clickId : Traffic.ClickId,
                                
                                website : "https://" + window.location.hostname,
                                applicantId : cardOffersResponse.ref_number,
                                expirationDate : moment().format("YYYY-MM-DD"),
                                medium : Traffic.UtmMedium,
                                campaign : Traffic.UtmCampaign,
                                sessionId : sessionStorage.getItem("oldSessionToken"),
                                productId : productSettings.ProductId

                            });
                        }
                        utms.updateAppId(cardOffersResponse.ref_number || "");
                        utms.updateSessionReport("appResult", ResultWebPageHash.toUpperCase())
                        utms.updateSessionReport("applicantId", cardOffersResponse.ref_number)
                        
                        saveIcommPostbackqueue(cardOffersResponse.ref_number, "Submitted", 2); 
                        if((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION" && applicantApiClient.findParam("flinkbackdoor")) || ["PENDINGFLINKS", "DECLINEFLINKS"].includes(cardOffersResponse.failed_metric.toUpperCase())){
                            setdisplayResult(true)
                            window.scrollTo(0, 0);
                            history.push("/link-account"+window.location.search)
                            return;
                        }
                       
                        
                        if (pendingInfo.includes(cardOffersResponse.result.toUpperCase()) || declinedResults.includes(cardOffersResponse.result.toUpperCase()) || cardOffersResponse.result.toUpperCase() === 'DENIED' || cardOffersResponse.result.toUpperCase() === 'DECLINED' || cardOffersResponse.result.toUpperCase() === 'SYS_ERROR' ||  cardOffersResponse.result.toUpperCase() === 'FROZEN') {
                            if(cardOffersResponse.result.toLowerCase() === "statenotvalidforbusiness"){
                                setShowOnDeclined(false)
                                setInvalidState(true)
                                await delay(7000);
                                cardOffersResponse.decline_message = cardOffersResponse.reason;
                                setApplicationResult(cardOffersResponse);
                            }
                            if(pendingInfo.includes(cardOffersResponse.result.toUpperCase())){
                                setApplicantData({ index: "FirstName", value: applicantApiData.Applicant.FirstName } );
                                setApplicantData({ index: "LastName", value: applicantApiData.Applicant.LastName } );
                                
                                setApplicationResult({index: "ref_number", value: cardOffersResponse.ref_number } );
                                
                            }
                          
                            setdisplayResult(true)
                            window.scrollTo(0, 0);
                            if (pendingInfo.includes(cardOffersResponse.result.toUpperCase())) {
                                history.push("/pendinfo"+window.location.search)
                            }
                            else{
                                history.push("/declined"+window.location.search)
                            }
                            
                            return;
                        }
                        else if (cardOffersResponse.result.toUpperCase() === 'APPROVED') {
                            setdisplayTerms(true);
                            
                            window.scrollTo(0, 0);
                            history.push("/terms"+window.location.search)
                            return;
                        }
                    }
                    else {
                        setError(cardOffersResponse.validationResult.FieldName);
                        if (cardOffersResponse.validationResult.FieldName === "dob") {
                            dobRef.current.scrollIntoView({ behavior: 'smooth' });
                        } else {
                            setFocus(cardOffersResponse.validationResult.FieldName);
                        }
                    }

                    setDisplayHeaderTerms(false);
                }
                else {
                    setErrorMessage("An unknown error has occured. Please try again.");
                    setErrorShow(true);
                    setLoading(false);
                    setSubmitting(false)
                    setDisableField(false);
                }
            }

        } catch (error) {
            console.log(error);
            setErrorMessage('An unknown error has occured. Please try again.');
            setErrorShow(true);
            setLoading(false);
            setSubmitting(false)
            setDisableField(false);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const handleCalculatePopupOk = () => {
        setShowPopup(false);
        setMonthlyIncome(prev => prev = tempMonthlyIncome.replace("$","").replace(",",""));
    }

    const calculateMonthlyIncome = (annualIncome) => {
        let nf = new Intl.NumberFormat('en-US');
        setTempMonthlyIncome("$" + nf.format(parseFloat(annualIncome / 12).toFixed(0)));
    };

    const dobRef = useRef();

    // Double Parameter Sign Fix
    useEffect(() => {
        if (window.location.href.includes("??")){
            const formattedUrl = window.location.href.replace("??", '?');
            window.location.assign(formattedUrl);
        }
    }, []);
    
    return (
        <>
        <form
            style={{ paddingTop: "30px" }}
            className="userform shaded"
            onSubmit={handleSubmit(appSubmit, errors => {
                const firstError = Object.keys(errors).reduce((field, a) => {
                    return !!errors[field] ? field : a;
                  }, null);
                  if (firstError && firstError !== "dob"  && firstError !== "primaryPhone") {
                    setLoading(false);
                    setSubmitting(false)
                    setFocus(firstError);
                  }
                  if (firstError === "dob") {
                    setError('dob', { type: 'custom', message: 'The date must be valid and must be older than 20' });
                    dobRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                  if (firstError === "primaryPhone") {
                    setError('primaryPhone');
                    primaryPhoneRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                errors && setShowAlert(true);
            })}>
           
           
               <InvalidStateModal visible={invalidState} /> 
            
            {
                (Pixels.find(Pixel => Pixel.Provider === "Amazon") && Pixels.find(Pixel => Pixel.Type === "landing")) ?
                <img height='1' width='1' border='0' alt='' src={Pixels.find(Pixel => Pixel.Type === "landing")["Tag"]} /> : <></>
            }
            
            <div className="container">
                <ErrorModal size='sm' show={errorShow} message={errorMessage} closeError={closeError} />
                <div className="row">
                    <div className="col-lg-6">
                        <div className="input-fields">
                            <div className="form-group">
                                <label htmlFor="firstname">First Name</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.firstName} maxLength={getFieldMaxLength("firstName")} onChange={ e => setApplicantData({index : "firstName", value : e.target.value})} type="text" className="form-control" id="firstname" placeholder="First Name" {...register("firstName", getValidation("firstName", validations))} />
                                </div>
                                { errors.firstName ? errors.firstName && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="middlename">Middle Initial</label>
                                <div className="input-container">
                                    <input disabled={disableField} defaultValue={applicantData.middleName} maxLength={getFieldMaxLength("middleName")} onChange={ e => setApplicantData({index : "middleName", value : e.target.value})} type="text" className="form-control" id="middlename" placeholder="Middle initial" {...register("middleName", getValidation("middleName", validations))} />
                                </div>
                                { errors.middleName ? errors.middleName && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.lastName} maxLength={getFieldMaxLength("lastName")} onChange={ e => setApplicantData({index : "lastName", value : e.target.value})} type="text" className="form-control" id="lastname" placeholder="Last Name" {...register("lastName", getValidation("lastName", validations))} />
                                </div>
                                { errors.lastName ? errors.lastName && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="suffix">Suffix</label>
                                <div className="input-container">
                                    <select disabled={disableField} defaultValue={applicantData.suffix} onChange={ e => setApplicantData({index : "suffix", value : e.target.value})} className="form-control" id="suffix" {...register("suffix", getValidation("suffix", validations))}>
                                        <option value=""></option>
                                        <option value="JR">JR</option>
                                        <option value="SR">SR</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                        <option value="IV">IV</option>
                                    </select>
                                </div>
                                { errors.suffix ? errors.suffix && <span className='error-message'> { selectInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="address1">Home Address (no P.O. boxes)</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.address1} maxLength={getFieldMaxLength("address1")} onChange={ e => setApplicantData({index : "address1", value : e.target.value})} type="text" className="form-control" id="address1" placeholder="Address Line 1"  {...register("address1", getValidation("address1", validations))} />
                                </div>
                                { errors.address1 ? errors.address1 && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="address2">Address 2</label>
                                <div className="input-container">
                                    <input disabled={disableField} defaultValue={applicantData.address2} maxLength={getFieldMaxLength("address2")} onChange={ e => setApplicantData({index : "address2", value : e.target.value})} type="text" className="form-control" id="address2" placeholder="Address Line 2"  {...register("address2", getValidation("address2", validations))} />
                                </div>
                                { errors.address2 ? errors.address2 && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.city} maxLength={getFieldMaxLength("city")} onChange={ e => setApplicantData({index : "city", value : e.target.value})}  type="text" className="form-control" id="city" placeholder="City" {...register("city", getValidation("city", validations))} />
                                    {/* {errors.city && <AiOutlineExclamation color={errorAlert.color} size={errorAlert.size} />} */}
                                </div>
                                { errors.city ? errors.city && <span className='error-message'> Please Check Your City/State/Zip As it Does Not Match In Our Database. </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <div className="input-container" >
                                    <select disabled={disableField} defaultValue={selectedState} 
                                        onChange={ (e) => {
                                            setApplicantData({index : "state", value : e.target.value});
                                            } 
                                        } 
                                        className="form-control" id="state" {...register("state")}>
                                        <option value=""></option>
                                        {statesList.map((state) => {
                                            return (<option value={state.Code} key={state.Code}>{state.Name}</option>)
                                        })}
                                    </select>
                                </div>
                                { errors.state ? errors.state && <span className='error-message'> { selectInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="zip">Zip Code</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.zip} maxLength={getFieldMaxLength("zip")} onChange={ e => setApplicantData({index : "zip", value : e.target.value})} type="text" className="form-control" id="zip" placeholder="Zip Code" {...register("zip", getValidation("zip", validations))} />
                                </div>
                                { errors.zip ? errors.zip && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <div className="input-container" >
                                    <input disabled={disableField} defaultValue={applicantData.email} maxLength={getFieldMaxLength("email")} onChange={ e => setApplicantData({index : "email", value : e.target.value})} type="email" className="form-control" id="email" placeholder="someone@domain.com" {...register("email", getValidation("email", validations))} />
                                </div>
                                { errors.email ? errors.email && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="ssn">Social Security Number</label>
                                <div className="input-container" >
                                    <Controller
                                        name="ssn"
                                        control={control}
                                        
                                        rules={getValidation("ssn", validations)}
                                        render={({ field }) => 
                                        <MaskedInput
                                           
                                            disabled={disableField}
                                            {...field}
                                            mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            guide={false}
                                            type="text" 
                                            className="form-control" 
                                            id="ssn" 
                                            placeholder="999-99-9999" 
                                            onChange={event => { formatSSN(event.target.value, setSSN) }} 
                                        />}
                                    />
                                </div>
                                { errors.ssn ? errors.ssn && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="tmi">Your Total Monthly Income <span className="text-danger">{monthlyIncome.replaceAll("$","").replaceAll(",","") >= 12000 ? "Please Ensure that total Monthly Income is Correct!" : ""}</span></label>
                                <div className="input-container" >
                                <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip className="mytooltip"  id="monthly-tooltip" >{text.block1.tooltipIncome}</Tooltip>}>
                                        <input 
                                            value={IncomeMask(monthlyIncome)} 
                                            onInput={(e) => {
                                                e.preventDefault()
                                                
                                                setCursorPosition(e.target.selectionStart)
                                               
                                                let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$","").replaceAll(",","") : e.nativeEvent.data;
                                              
                                                if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                    if(e.target.value.replaceAll("$","").replaceAll(",","") <= 99999){
                                                       
                                                        let final = parseInt(e.target.value.replaceAll("$","").replaceAll(",",""))
                                                        final = (isNaN(final)) ? "" : final;
                                                        setMonthlyIncome(""+final)
                                                    }
                                                }
                                            }} 
                                            onKeyDown={(e) => {
                                                setCursorPosition(e.target.selectionStart)
                                            }}
                                            onKeyUp={(e) => {
                                                if(e.nativeEvent.key === "Backspace" ){
                                                    e.target.setSelectionRange(cursor, cursor);
                                                }
                                            }}
                                            type="text" className="form-control" placeholder="$ 0.00" {...register("monthlyIncome", getValidation("monthlyIncome", validations))} 
                                        />
                                    </OverlayTrigger>
                                    
                                </div>
                                { errors.monthlyIncome ? errors.monthlyIncome && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <Button id="btn-income-calculator" className="btn btn-secondary" style={{backgroundColor: "gray", borderColor: "gray"}} onClick={() =>{ setShowPopup(true); setTempMonthlyIncome("$0.00")}}>Calculate Your Monthly Income</Button>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tmi-source">Primary Source of Monthly Income</label>
                                <div className="input-container" >
                                    <Controller
                                        name="incomeSource"
                                        control={control}
                                        render={
                                            ({ field }) => 
                                            <select disabled={disableField} {...field} className="form-control" id="tmi-source" aria-describedby="tmi-source-help" reg="true" {...register("incomeSource", getValidation("incomeSource", validations))}>
                                                <option value="">Please select one of the following</option>
                                                <option value="Employed Full-time">Employed Full-time</option>
                                                <option value="Employed Part-time">Employed Part-time</option>
                                                <option value="Self Employed">Self Employed</option>
                                                <option value="Unemployed">Unemployed</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        }
                                    />
                                </div>
                                { errors.incomeSource ? errors.incomeSource && <span className='error-message'> { selectInputErrorMsg } </span> : '' }
                                <small id="tmi-source-help" className="form-text text-muted">
                                    Alimony, child support, or separate maintenance
                                    income need not be revealed if you do not wish to have it considered as a basis for repaying this
                                    obligation.
                                </small>
                            </div>
                            <div className="form-group"  ref={primaryPhoneRef} >
                                <label className="mb-0" htmlFor="phone">Primary phone number</label><br />
                                <small style={{fontSize : "12px"}}>Mobile Preferred, 10-digits</small>
                                <div className="input-container" >
                                    <input 
                                        value={masking(primaryPhone, "(XXX) XXX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setPhone1( unmasking(e, "(XXX) XXX-XXXX", primaryPhone))
                                            }
                                        }} 
                                        onKeyDown={(e) => {
                                            setCursorPosition(e.target.selectionStart)
                                        }}
                                        onKeyUp={(e) => {
                                            if(e.nativeEvent.key === "Backspace" ){
                                                e.target.setSelectionRange(cursor, cursor);
                                            }
                                        }}
                                        type="text" className="form-control" placeholder="(999) 999-9999" {...register("primaryPhone")} 
                                    />
                                    
                                </div>
                                { errors.primaryPhone ? errors.primaryPhone && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone2">Secondary phone number</label>
                                <div className="input-container" >
                                <input 
                                        value={masking(phone2, "(XXX) XXX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setPhone2( unmasking(e, "(XXX) XXX-XXXX", phone2))
                                            }
                                        }} 
                                        onKeyDown={(e) => {
                                            setCursorPosition(e.target.selectionStart)
                                        }}
                                        onKeyUp={(e) => {
                                           
                                            if(e.nativeEvent.key === "Backspace" ){
                                                e.target.setSelectionRange(cursor -1, cursor -1);
                                            }

                                        }}
                                        type="text" className="form-control" placeholder="(999) 999-9999" {...register("secondaryPhone")} 
                                    />
                                   
                                </div>
                                { errors.secondaryPhone ? errors.secondaryPhone && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                            </div>
                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <ConcentSms />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth (MM/DD/YYYY)</label>
                                <div ref={dobRef} className="input-container" >
                                    <Controller
                                        required
                                        name="dob"
                                        control={control}
                                        rules={getValidation("dob", validations)}
                                        render={({ field: { onChange } }) => <MaskedInput
                                           id="dob"
                                            mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            guide={false}
                                            defaultValue={dob}
                                            disabled={disableField}
                                            onChange={event => formatDOB(event.target.value, setDOB)}
                                            type="text" className="form-control" placeholder="MM/DD/YYYY" />}
                                    />
                                </div>
                                { errors.dob ? errors.dob && <span className='error-message'> Please enter a valid date of birth. You must be at least 18 years of age or older to apply.  </span> : '' }
                                
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-12">
                                    {spouseFormDisplay && <div id="spouse-info">
                                        <p>We are required under Wisconsin law to provide your spouse with information pertaining to the terms and conditions of this credit card offer.</p>
                                        <div className="check-fields"><label><b>Are you married?*</b></label></div>
                                        <div className="form-check form-check-inline">
                                            <div className="input-container">
                                                <CheckBox
                                                    disabled={disableField}
                                                    id="is-married"
                                                    name="is-married"
                                                    onChange={event => displaySpouseInfoForm(event.target.value)}
                                                    registername={"isMarried"}
                                                    checked={getValues('isMarried')}
                                                    validationrules={getValidation("isMarried", validations)}
                                                />
                                            </div>
                                            { errors.isMarried ? errors.isMarried && <span className='error-message'> { checkboxInputErrorMsg }  </span> : '' }
                                        </div>
                                        {spouseInfoFormDisplay && <div id="spouse-info-form" >
                                            <label>
                                                Spouse's Information -
                                                <small className="spouse-info-green-text">&nbsp;If a physical address is not provided, the notification to your spouse will be sent to the primary physical address on this application.</small>
                                            </label>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="spouse-fname">First Name</label>
                                                    <div className="input-container" >
                                                        <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseFirstName")} className="form-control" id="spouse-fname" placeholder="First Name" {...register("spouseFirstName", getValidation("spouseFirstName", validations))} />
                                                    </div>
                                                    { errors.spouseFirstName ? errors.spouseFirstName && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="spouse-mname">MI</label>
                                                    <div>
                                                        <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseMiddleName")} className="form-control" id="spouse-mname" placeholder="MI"  {...register("spouseMiddleName", getValidation("spouseMiddleName", validations))} />
                                                    </div>
                                                    { errors.spouseMiddleName ? errors.spouseMiddleName && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="spouse-lname">Last Name</label>
                                                    <div className="input-container">
                                                        <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseLastName")} className="form-control" id="spouse-lname" placeholder="Last Name" {...register("spouseLastName", getValidation("spouseLastName", validations))} />
                                                    </div>
                                                    { errors.spouseLastName ? errors.spouseLastName && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="spouse-suffix">Suffix</label>
                                                    <select disabled={disableField} className="form-control" id="spouse-suffix" {...register("spouseSuffix", getValidation("spouseSuffix", validations))}>
                                                        <option value=""></option>
                                                        <option value="JR">JR</option>
                                                        <option value="SR">SR</option>
                                                        <option value="II">II</option>
                                                        <option value="III">III</option>
                                                        <option value="IV">IV</option>
                                                    </select>
                                                </div>
                                                { errors.spouseSuffix ? errors.spouseSuffix && <span className='error-message'> { selectInputErrorMsg } </span> : '' }
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="spouse-email">Email Address</label>
                                                <div className="input-container" >
                                                    <input disabled={disableField} type="email" maxLength={getFieldMaxLength("spouseEmail")} className="form-control" id="spouse-email" placeholder="someone@domain.com" {...register("spouseEmail", getValidation("spouseEmail", validations))} />
                                                </div>
                                                { errors.spouseEmail ? errors.spouseEmail && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                            </div>
                                            <div className="check-fields">
                                                <label>
                                                    <b>Does your spouse have a different address?</b>
                                                </label>
                                                <div className="input-container" >
                                                    <CheckBox
                                                        disabled={disableField}
                                                        id="spouse-has-different-address-yes"
                                                        name="spouse-has-different-address"
                                                        onChange={event => displaySpouseAddressForm(event.target.value)}
                                                        registername={"spouseHasDifferentAddress"}
                                                        validationrules={getValidation("spouseHasDifferentAddress", validations)}
                                                        checked={getValues('spouseHasDifferentAddress')}
                                                    />
                                                </div>
                                                { errors.spouseHasDifferentAddress ? errors.spouseHasDifferentAddress && <span className='error-message'> { checkboxInputErrorMsg } </span> : '' }
                                            </div>

                                            {spouseAddressFormDisplay && <div id="spouse-address-form" >
                                                <div className="form-group">
                                                    <label htmlFor="spouse-address">Address <small className="spouse-info-green-text">(no P.O. boxes)</small></label>
                                                    <div className="input-container" >
                                                        <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseAddress1")} className="form-control" id="spouse-address" placeholder="Address" {...register("spouseAddress1", getValidation("spouseAddress1", validations))} />
                                                    </div>
                                                    { errors.spouseAddress1 ? errors.spouseAddress1 && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="spouse-city">City</label>
                                                        <div className="input-container" >
                                                            <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseCity")} className="form-control" id="spouse-city" placeholder="City"  {...register("spouseCity", getValidation("spouseCity", validations))} />
                                                        </div>
                                                        { errors.spouseCity ? errors.spouseCity && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label htmlFor="state">State</label>
                                                        <div className="input-container" >
                                                            <select disabled={disableField} className="form-control" id="spouse-state" value={selectedSpouseState} {...register("spouseState", getValidation("spouseState", validations))} onChange={event => handleSpouseStateChange(event.target.value)}>
                                                                <option value=""></option>
                                                                {statesList.map((state) => {
                                                                    return (<option value={state.Code} key={state.Code}>{state.Name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                        { errors.spouseState ? errors.spouseState && <span className='error-message'> { selectInputErrorMsg }  </span> : '' }
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label htmlFor="spouse-zip">Zip</label>
                                                        <div className="input-container" >
                                                            <input disabled={disableField} type="text" maxLength={getFieldMaxLength("spouseZip")} className="form-control" id="spouseZip" placeholder="Zip"  {...register("spouseZip", getValidation("spouseZip", validations))} />
                                                        </div>
                                                        { errors.spouseZip ? errors.spouseZip && <span className='error-message'> { textInputErrorMsg } </span> : '' }
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                            <div className="check-fields"><label>Do you have an active checking account or debit card?</label></div>
                            <div className="input-container">
                                <CheckBox
                                    disabled={disableField}
                                    checked={getValues('hasCheckingAccount')}
                                    name="active-checking"
                                    id="active-checking"
                                    registername={"hasCheckingAccount"}
                                    validationrules={getValidation("hasCheckingAccount", validations)}
                                />
                            </div>
                            { errors.hasCheckingAccount ? errors.hasCheckingAccount && <span className='error-message'> { checkboxInputErrorMsg } </span> : '' }    
                            { checkIfFieldIsActive("cashIntent") ? (
                                <>
                                    <div className="check-fields"><label>Do you intend to use this card for cash advances?</label></div>
                                    <div className="input-container">
                                        <CheckBox
                                            disabled={disableField}
                                            name="cash-advances"
                                            id="cash-advances"
                                            registername={"cashIntent"}
                                            checked={getValues('cashIntent') === true}
                                            validationrules={getValidation("cashIntent", validations)}
                                        />
                                    </div>
                                    { errors.cashIntent ? errors.cashIntent && <span className='error-message'> { checkboxInputErrorMsg } </span> : '' }
                                </>
                                ) : <></>}                                 
                            <div className="check-fields"><label>Would you like an additional card?</label></div>
                            <div className="input-container">
                                <CheckBox
                                    disabled={disableField}
                                    name="additional-cardholder"
                                    id={`additional-cardholder`}
                                    onChange={event => { displayAddtCHForm(event.target.value); }}
                                    registername={"hasAdditionalCardholder"}
                                    validationrules={getValidation("hasAdditionalCardholder", validations)}
                                    checked={getValues('additionalCardholder')}
                                />
                            </div>
                            { errors.hasAdditionalCardholder ? errors.hasAdditionalCardholder && <span className='error-message'> { checkboxInputErrorMsg }  </span> : '' }
                            {addtlCHFormDisplay && <div>
                                <div>
                                    <p><small style={{color : "#005dab"}}>(Additional Fee of $30.00) Cannot be the same name as the primary cardholder.</small></p>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="addtl-ch-firstname">First Name</label>
                                        <div className="input-container" >
                                            <input disabled={disableField} type="text" className="form-control" maxLength={getFieldMaxLength("additionalCardholderFirstName")} id="addtl-ch-firstname" placeholder="First Name" {...register("additionalCardholderFirstName", getValidation("additionalCardholderFirstName", validations))} />
                                        </div>
                                        { errors.additionalCardholderFirstName ? errors.additionalCardholderFirstName && <span className='error-message'> This field is invalid. Please fill it correctly. </span> : '' }
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="addtl-ch-middlename">MI</label>
                                        <div>
                                            <input disabled={disableField} type="text" className="form-control" maxLength={getFieldMaxLength("additionalCardholderMI")} id="addtl-ch-middlename" placeholder="MI" {...register("additionalCardholderMI", getValidation("additionalCardholderMI", validations))} />
                                        </div>
                                        { errors.additionalCardholderMI ? errors.additionalCardholderMI && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="addtl-ch-lastname">Last Name</label>
                                        <div className="input-container" >
                                            <input disabled={disableField} type="text" className="form-control" maxLength={getFieldMaxLength("additionalCardholderLastName")} id="addtl-ch-lastname" placeholder="Last Name"{...register("additionalCardholderLastName", getValidation("additionalCardholderLastName", validations))} />
                                        </div>
                                        { errors.additionalCardholderLastName ? errors.additionalCardholderLastName && <span className='error-message'> { textInputErrorMsg }  </span> : '' }
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="addtl-ch-suffix">Suffix</label>
                                        <select disabled={disableField} className="form-control" id="addtl-ch-suffix" {...register("additionalCardholderSuffix", getValidation("additionalCardholderSuffix", validations))}>
                                            <option value=""></option>
                                            <option value="JR">JR</option>
                                            <option value="SR">SR</option>
                                            <option value="II">II</option>
                                            <option value="III">III</option>
                                            <option value="IV">IV</option>
                                        </select>
                                    </div>
                                    { errors.additionalCardholderSuffix ? errors.additionalCardholderSuffix && <span className='error-message'> { selectInputErrorMsg } </span> : '' }
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        {errors.additionalCardholderFirstName ? errors.additionalCardholderFirstName && <span className='error-message'> The Additional cardholder's name must be different than the applicant. </span> : ""}
                                    </div>
                                </div>
                            </div>}
                            { checkIfFieldIsActive("over18") ? (
                                <div className="form-check check-fields">
                                    <div className="input-container">
                                        <input disabled={disableField} className="form-check-input" type="checkbox" value="" id="ofAge" {...register("over18", { required: true, shouldUnregister: true })} />
                                        <label className="form-check-label" htmlFor="ofAge">
                                            I certify that I am a U.S resident over the age of 18 (over the age of 19 in AL).
                                        </label>
                                    </div>
                                    { errors.over18 ? errors.over18 && <span className='error-message'> { formCheckInputErrorMsg }  </span> : '' }
                                </div>
                            ) : <></>}
                            <div className="form-check check-fields">
                                <div className="input-container">
                                    <input disabled={disableField} className="form-check-input" type="checkbox" value="" id="authPrequal" {...register("authPrequal", { required: true, shouldUnregister: true })} />
                                    <label className="form-check-label" htmlFor="authPrequal">
                                        I Authorize Pre-qualification.
                                    </label>
                                </div>
                                { errors.authPrequal ? errors.authPrequal && <span className='error-message'> { formCheckInputErrorMsg }  </span> : '' }
                            </div>
                            <div className="form-check check-fields">
                                <div className="input-container">
                                    <input disabled={disableField} className="form-check-input" type="checkbox" value="" id="email-optin-bulldog" {...register("emailOptIn", getValidation("emailOptIn", validations))} />
                                    <label className="form-check-label" htmlFor="email-optin-bulldog">
                                        I consent and understand that {brandSettings.BankName} and Continental Finance Company will use any information I provide 
                                        to apply for a credit product to enable physical and digital communications from selected affiliated and 
                                        non-affiliated marketing partners in compliance with
                                        the <a rel="noopener noreferrer" href={productSettings.PrivacyUrl} target="_blank">Privacy Notice</a>. My consent 
                                        is not required for approval of this application. If I receive any email communications from affiliated or non-affiliated 
                                        marketing partners, I understand that I can unsubscribe at any time by clicking the unsubscribe link in that email message.
                                    </label>
                                </div>
                                { errors.emailOptIn ? errors.emailOptIn && <span className='error-message'> { formCheckInputErrorMsg }  </span> : '' }
                            </div>
                            <div className="form-check check-fields">
                                <div className="input-container">
                                    <input disabled={disableField} className="form-check-input" type="checkbox" value="" id="optin-e-disclosure"  {...register("eSignConsent", getValidation("eSignConsent", validations))} />
                                    <label className="form-check-label" htmlFor="optin-e-disclosure">
                                        By clicking this box you agree that you have read and consent to <a onClick={() => { utms.updateSessionReport("disclosureLink") }} rel="noopener noreferrer" href={productSettings.ESignUrl} target="_blank">electronic disclosures</a>.
                                    </label>
                                </div>
                                { errors.eSignConsent ? errors.eSignConsent && <span className='error-message'> { formCheckInputErrorMsg }  </span> : '' }
                            </div>
                            <p className="check-fields">
                                <small>
                                    By clicking 'See My Card Offers' button below, you (i) acknowledge that you are providing 'written instructions' and authorize Celtic Bank to obtain information from your personal credit profile from one or more
                                    credit reporting agencies in order to conduct a pre-qualification for credit and (ii) you authorize Celtic Bank to present you with other offers of credit and credit related products. Pre-qualification does not
                                    guarantee account approval and you must submit a full application for review in order to apply for any pre-qualified offer(s) of credit.
                                </small>
                            </p>
                            {
                                recaptcha && useRecaptcha ? 
                                <div className="form-group ">
                                    <Recaptcha
                                        setRecaptchaDisabled={setRecaptchaDisabled}
                                        ProductName={brandSettings.ProductName}
                                    />
                                </div> : <></>
                            }
                            {/* Alert */}
                            {showAlert && <ErrorAlert showAlert={showAlert} />}
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    style={btnSubmitAppStyle}
                                    disabled={recaptchaDisabled || showAlert || submitting}
                                    id="btn-see-offers"
                                    className="btn"
                                    type='submit'
                                    onClick={() => { 
                                        utms.updateSessionReport("seeMyOffer") 
                                        
                                    }}
                                >
                                    {isLoading ? 'Submitting...' : 'See My Card Offers'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 add-ons">
                        <div className="add-ons-section d-lg-none">
                            <Features />
                        </div>
                        <Security />
                        <Timeout/>
                        <Reputation />
                        {brandSettings.ProductName.toUpperCase() === "REFLEX" ?
                            <div className="col-lg-12"><ReflexMicroComboTB /><ReflexSliderTB /></div> : <div className="col-lg-12"><SurgeMicroComboTB /><SurgeSliderTB /></div>
                        }
                        <div className="add-ons-section d-none d-lg-block">
                            <Features />
                        </div>
                    </div>
                    {/* Modal */}
                    <Modal id="cfc-income-modal" show={showPopup} backdrop="static" centered>
                        <Modal.Body id="padding-bottom">
                            <Container>
                                <Row>
                                    <p id="modal-text">Please enter your total annual income. We'll convert it to monthly income. Only numbers are allowed.</p>
                                </Row>
                                <Row>
                                    <label id="modal-text" htmlFor="annual-income-input">Your Total Annual Income:</label>
                                    <input 
                                        id="modal-input-field" 
                                        onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault(); }  }} 
                                        maxLength={6} 
                                        onChange={(e) => {
                                            e.preventDefault()
                                            if (/[0-9]/.test(e.target.value) ) {
                                                if(e.target.value.replaceAll("$","").replaceAll(",","") <= 1199988){
                                                    calculateMonthlyIncome(e.target.value)
                                                }
                                            }
                                            calculateMonthlyIncome(e.target.value)
                                        }} 
                                        type="text" 
                                        placeholder="Annual Salary" />
                                </Row>
                                <Row>
                                    <p id="modal-text">
                                        Alimony, child support, or separate maintenance income need not be revealed
                                        if you do not wish to have it considered as a basis for repaying this obligation
                                    </p>
                                </Row>
                                <Row id="centered-content">
                                    <p id="modal-text bold no-line-height">Your monthly income is: </p>
                                </Row>
                                <Row id="centered-content">
                                    <p id="modal-text bold no-line-height">{tempMonthlyIncome ? tempMonthlyIncome : '0.00'}</p>
                                </Row>
                                <Row id="centered-content">
                                    <Button id="btn-popup-small" variant="secondary" onClick={() => handleCalculatePopupOk()}>Ok</Button>
                                    <Button id="btn-popup-long" variant="secondary" onClick={() => handlePopupClose()}>Close this window</Button>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </form>
    </>
    )
}

export default PrequalForm;