import moment from "moment";
import { useStoreState } from "easy-peasy";

const IS_REQUIRED_RULE = "IsRequired";
const MAX_LENGTH_RULE = "MaxLengthChar";
const MIN_LENGTH_RULE = "MinLengthChar";
const REGEX_RULE = "Regex";
const FIRST_CHAR_NOT_NUM_RULE = "FirstCharNotNum";
const ACCEPTED_VALUES_RULE = "AcceptedValues";
const ALL_DIGITS_RULE = "AllDigits";
const MIN_YEAR_DOB_RULE = "MinYearDOB";
const MIN_AGE_RULE = "MinAge";


const AgeWildcard = [ {} ];

// Validator Service for Rules
export default class Services {
  constructor() {
    this.validationRules = useStoreState((state) => state.siteSettingsModel.validationRules);
    this.info = useStoreState((state) => state.applicantModel.applicant);
  }

  validateField = (fieldName, value) => {
    const validationResult = this.validateFieldWithData(fieldName, value);
    if (validationResult.failed.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  /**
    * Validation.
    *

    * Search the field in ValidationRules.ValidationType === fieldName; if not found return false.
    * For each property: depending the property the value must match the criteria, if not return false
    * If the end of the algorithm reached withouth interruptions then return true
    *
    * @param {String}  fieldName   name of the field that should be looked upon the validation rules.
    * @param {String}  value   value of the field that will be validated against the rules of the field.
    *
    * @return {Object} We return information of the validation with failed, errorMessages for the field and succeeded rules
    */
  validateFieldWithData = (fieldName, value) => {
    let validationResult = {
      failed: [],
      errorMessages: [],
      succeeded: [],
    };
    
    const rules = this.validationRules.find(
      (field) => field.ValidationType.toLowerCase() === fieldName.toLowerCase()
    );
    if (rules !== undefined) {

      Object.keys(rules).forEach((ruleName) => {
        
        const hasValue = !(value === undefined || value === null || value === "" || value === "$0");
          // eslint-disable-next-line  default-case
          switch (ruleName) {
            case IS_REQUIRED_RULE:
              if (rules[IS_REQUIRED_RULE]) {          
                if (!hasValue) {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push( (fieldName === "additionalCardholderFirstName") ? "Required field. Please enter only letters and hyphens." : "This field is missing or invalid. Please fill it correctly.");
                } else {
                  validationResult.succeeded.push(ruleName);
                }
              }
              break;

            case MAX_LENGTH_RULE:
              if (rules[MAX_LENGTH_RULE] !== 0) {              
                if (hasValue && value.length > rules[ruleName]) {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(
                    `Characters can not be more than ${rules[MAX_LENGTH_RULE]}`
                  );
                } else {
                  validationResult.succeeded.push(ruleName);
                }
              }
              break;

            case MIN_LENGTH_RULE:
              if (rules[MIN_LENGTH_RULE] !== 0) {           
                if (hasValue && value.length < rules[ruleName]) {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(
                    `Characters can not be less than ${rules[MIN_LENGTH_RULE]}`
                  );
                } else {
                  validationResult.succeeded.push(ruleName);
                }
              }
              break;

            case REGEX_RULE:
              if (
                rules[REGEX_RULE] && hasValue
                //!validationResult.failed.includes(IS_REQUIRED_RULE)
              ) {             
                const regexToTest = new RegExp(rules.Regex);
                if (hasValue && regexToTest.test(value)) {
                  validationResult.succeeded.push(ruleName);
                } else {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(`Invalid value`);
                }
              }
              break;

            case FIRST_CHAR_NOT_NUM_RULE:
              if (
                rules[FIRST_CHAR_NOT_NUM_RULE] && hasValue
                //!validationResult.failed.includes(IS_REQUIRED_RULE)
              ) {              
                const regexToTestFirstCharNotNum = new RegExp("^[A-Za-z]");
                if (hasValue && regexToTestFirstCharNotNum.test(value)) {
                  validationResult.succeeded.push(ruleName);
                } else {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(
                    `First character can not be a number`
                  );
                }
              }
              break;

            case ACCEPTED_VALUES_RULE:
              if (rules[ACCEPTED_VALUES_RULE]) {              
                // Sometimes AcceptedValues is an Array in String and sometimes is just a string with a single value to compare
                try {
                  const acceptedValuesArr = JSON.parse(rules.AcceptedValues);
                  const [validAnswer] = acceptedValuesArr.filter(
                    (acceptedValue) => acceptedValue === value
                  );
                  if (validAnswer === value) {
                    validationResult.succeeded.push(ruleName);
                  } else if (validAnswer !== value && rules[IS_REQUIRED_RULE]) {
                    validationResult.failed.push(ruleName);
                    validationResult.errorMessages.push(`Invalid value`);
                  }
                } catch {
                  if (value === rules[ACCEPTED_VALUES_RULE]) {
                    validationResult.succeeded.push(ruleName);
                  } else {
                    validationResult.failed.push(ruleName);
                    validationResult.errorMessages.push(`Invalid value`);
                  }
                }
              }
              break;

            case ALL_DIGITS_RULE:
              if (rules[ALL_DIGITS_RULE]) {             
                const regexToTestAllDigits = new RegExp("^[0-9]+$");
                if (regexToTestAllDigits.test(value)) {
                  validationResult.succeeded.push(ruleName);
                } else {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(`Field must be all digits`);
                }
              }
              break;

            case MIN_AGE_RULE:
              if (rules[MIN_AGE_RULE] !== 0) {         
                if (!(moment(value, "YYYY-MM-DD", true).isValid())) {
                  validationResult.failed.push(ruleName);
                  break;
                }
                const date = new Date();
                const today = moment(date.now);
                const dob = moment(value, "YYYY-MM-DD", true).format("YYYY-MM-DD");
                
                const age = today.diff(dob, "years");
                if (age < rules[MIN_AGE_RULE]) {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(
                    `Please select an age older than ${(AgeWildcard.find(option => option.state === this.info.state) !== undefined) ? (AgeWildcard.find(option => option.state === this.info.state).minAge) : rules[MIN_AGE_RULE]}`
                  );
                } else {
                  validationResult.succeeded.push(ruleName);
                }
              }
              break;

            case MIN_YEAR_DOB_RULE:
              if (rules[MIN_YEAR_DOB_RULE] !== null) {         
                if (!(moment(value, "YYYY-MM-DD", true).isValid())) {
                  validationResult.failed.push(ruleName);
                }
                const dateOfBirth = moment(value, "YYYY-MM-DD", true).format(
                  "YYYY-MM-DD"
                );   
                const minDOB = moment(rules[MIN_YEAR_DOB_RULE]).format(
                  "YYYY-MM-DD"
                );
                if (dateOfBirth > minDOB && moment(value, "YYYY-MM-DD", true).isValid()) {
                  validationResult.succeeded.push(ruleName);
                } else {
                  validationResult.failed.push(ruleName);
                  validationResult.errorMessages.push(
                    `Please select a date higher than ${minDOB} or a valid date`
                  );
                }
              }
              break;
          }
      });

    } else {
      console.log(`Field ${fieldName} Not Found in Rules`);
    }

    return validationResult;
  };

  preValidations = (data = {}, methods, info = {}) => {
    let foundErrors = false;
    Object.keys(data).forEach((fieldName) => {
      const result = this.validateFieldWithData(fieldName,info[fieldName]);

      if(["Address1", "Address2", "spouseAddress1"].includes(fieldName)){
        if(data[fieldName] !== "" && !isNaN(Number(data[fieldName]))){
            methods.setError(fieldName)
            methods.setFocus(fieldName)
            foundErrors = true;
        }
      }

      if( fieldName === "additionalCardholderFirstName" && data.additionalCardholderFirstName && info.FirstName){
        if(data.additionalCardholderFirstName === info.FirstName){
          result.errorMessages.push("The Additional cardholder's name must be different than the applicant.")
          foundErrors = true;
        }
      }

      if(fieldName === "spouseHasDifferentAddress" && data[fieldName] === "true"){
        if(data["spouseAddress1"].toLowerCase() === info.Address1.toLowerCase()){
          methods.setError("spouseAddress1Duplicated")
          methods.setFocus("spouseAddress1")
          foundErrors = true;
        }
      }
      
      if (result.errorMessages.length > 0) {
        methods.setError(fieldName, {type: "string", message: result.errorMessages,});
        methods.setFocus(fieldName);
        foundErrors = true;
      }
    });

    return foundErrors;
  }
}
