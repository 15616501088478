import React from 'react';
import { useStoreState } from 'easy-peasy';
import marketing from "../../../services/marketing";

function CreditProtectionDisclosures() {

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const addendumLink = useStoreState(state => state.siteSettingsModel.productSettings.OnlineCreditProtectionUrl);
    const utm = new marketing();

    return (
        <div>            
            <div id="credit-protection-disclosures" tabIndex={0}>
                <p>
                    CONTINENTAL CREDIT PROTECTION is an OPTIONAL Program you can purchase to protect your {brandSettings.ProductName} Mastercard® credit card.
                    Whether or not you purchase CONTINENTAL CREDIT PROTECTION will not affect your application for credit or the terms of any
                    existing credit agreement you have with Celtic Bank.
                </p>
                <p>
                    Your cost is $0.99 for each $100 of the Outstanding Account Balance on the date that your monthly billing cycle closed. For example,
                    if your Outstanding Account Balance is $200, the Program fee for that month will be $1.98 and will be automatically billed to your
                    account on that same month’s billing statement.
                </p>
                <p>
                    Please see the CONTINENTAL CREDIT PROTECTION Addendum <a onClick={ () => {utm.updateSessionReport("creditProtectionLink") }} href={addendumLink || ""} target="_blank" rel="noopener noreferrer">here</a> and upon purchase we will
                    send you the CONTINENTAL CREDIT PROTECTION Addendum that fully describes the Program’s features and benefits including the
                    conditions, terms, exclusions, and restrictions that could prevent you from receiving Benefits. While receiving Benefits, you will
                    be unable to make any new charges or obtain cash advances on your credit card.
                </p>
                <p>
                    You may cancel the Program at any time and if you cancel within the first thirty (30) days, any fees charged will be refunded. We
                    may cancel the Program at any time.
                </p>
                <p>
                    Refer to the Definitions below and the table outlining certain Specific Conditions, Terms, Exclusions, and Restrictions.
                </p>
                <div>
                    <div><b>Definitions:</b></div>
                    <div>
                        <ol>
                            <li>Account – Your {brandSettings.ProductName} Mastercard® credit card account.</li>
                            <li>
                                Benefit Activation Period – The period of time from the date Your Benefit Activation Form and required documentation as
                                outlined in the table below, is received, reviewed and approved by CONTINENTAL CREDIT PROTECTION until Benefits under
                                the particular Benefit Activation are exhausted or terminated. When you submit a Benefit Activation Form for the Program
                                and that form is accepted, CONTINENTAL CREDIT PROTECTION provides a Benefit that goes back to the date that the Covered
                                Event began.
                            </li>
                            <li>Benefit Activation Form – The form you are required to complete and submit to activate Benefits under the Program.</li>
                            <li>Covered Event – Loss of Life, Job Loss, Disability and Hospitalization.</li>
                            <li>Minimum Payment Due – The amount due on your Account as noted on Your Monthly Billing Statement under Minimum Payment Due</li>
                            <li>You or Your – The primary cardholder on the Account.</li>
                        </ol>
                    </div>
                </div>
                <h6>SPECIFIC CONDITIONS, TERMS, EXCLUSIONS &amp; REQUIREMENTS</h6>
                <div className="terms-table">
                    <div className="terms-table-row">
                        <div className="left">
                            <b>Loss of Life</b>
                        </div>
                        <div className="right">
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">
                            Definition:
                        </div>
                        <div className="right">
                            You lose Your Life
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">
                            Exclusions – When this Covered Event Will NOT give rise to a Benefit:
                        </div>
                        <div className="right">
                            There is no Benefit if You engage in one of the following acts that causes Your Death:
                            (a) suicide; (b) intentional self-inflicted injury; or (c) criminal acts.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Protection Amount:</div>
                        <div className="right">The Outstanding Account Balance on the date of death, as determined by Your death certificate.</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Required Documents:</div>
                        <div className="right">Completed Benefit Activation Form and a copy of Your death certificate.</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left"><b>Job Loss</b></div>
                        <div className="right"></div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left"></div>
                        <div className="right">
                            (a) You become unexpectedly unemployed due to involuntary termination of Your employment; (b) You are involuntarily
                            laid off or your employment is suspended; (c) You have job loss because there is an authorized, unionized strike or
                            labor dispute by a chartered or previously organized trade or labor union; or (d) You have job loss because of a lockout
                            or discharge of employees or temporary closing in response to organized employee activity.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Exclusions – When this Covered Event will NOT give rise to a Benefit:</div>
                        <div className="right">There is no Benefit if
                            <div>
                                <ul>
                                    <li>You engage in (a) willful misconduct; or (b) criminal misconduct resulting in your termination from employment.</li>
                                    <li>You (a) voluntarily resign from your job; (b) retire; or (c) your employment contract expires.</li>
                                    <li>
                                        You are terminated from employment in which you are (a) an independent contractor; (b) self-employed; (c)
                                        a business owner (in part or in whole); (d) a sole proprietor; or (e) an employee of an immediate family
                                        member.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Protection Amount:</div>
                        <div className="right">
                            The monthly Minimum Payment Due on Your Account starting on the date of the Covered Event, minus any
                            delinquent amounts, including any monthly Minimum Payments that were due prior to the date of the Covered Event
                            and late fees that were incurred prior to the date of the Covered Event.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Waiting Period:</div>
                        <div className="right">
                            You must be unemployed for 30 consecutive days before a Benefit may be activated. The Program will start providing
                            you with a Benefit on the 31st day that you are unemployed, and the Benefit will date back to when the Covered Event began.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Maximum Benefit Activation
											Period:</div>
                        <div className="right">Twelve (12) continuous
											months or until You return to work, whichever comes first.</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Required Documentation:</div>
                        <div className="right">
                            <ul>
                                <li>
                                    Completed Benefit Activation Form and a copy of Your unemployment check stub or proof that You have
                                    registered with Your state unemployment office or a recognized employment agency within 30 days after
                                    the last day of work; or
                                </li>
                                <li>
                                    In the event of a strike, lockout or labor dispute, evidence of involuntary unemployment, which may
                                    be a statement signed by an officer of Your union. IN ADDITION, You must continue to provide written
                                    proof of Your Job Loss on a monthly basis in order to receive a Benefit for up to twelve (12) continuous
                                    months or until you return to work, whichever comes first.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left"><b>Disability</b></div>
                        <div className="right"></div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Definition:</div>
                        <div className="right">
                            You become and remain unable to perform the major duties of Your occupation as a direct result of unintentional
                            bodily injury, sickness, or disease; You are not working for wages or profit during the Benefit Activation Period
                            of the Disability; AND You are under the continuous care of a licensed physician (other than Yourself).
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Exclusions – When this Covered Event Will NOT give rise to a Benefit:</div>
                        <div className="right">There is no Benefit if Your Disability is caused by one of the following:
							<ul>
                                <li>Normal pregnancy or childbirth, including Caesarian Section; or</li>
                                <li>Intentional self-inflicted injury.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Protection Amount:</div>
                        <div className="right">
                            The monthly Minimum Payment Due on Your Account starting on the date of the Covered Event, minus any delinquent
                            amounts, including any monthly Minimum Payments that were due prior to the date of the Covered Event and late fees
                            that were incurred prior to the date of the Covered Event.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Waiting Period:</div>
                        <div className="right">
                            Your Disability must exist for 30 consecutive days before the Benefits for this Covered Event may be activated.
                            The Program will start providing you with Benefits on the 31st day from the Covered Event, and the Benefits will
                            date back to when the Covered Event began.
						</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Maximum Benefit Activation Period:</div>
                        <div className="right">
                            Twelve (12) continuous months or until Your Disability ceases, whichever comes first.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Required Documents:</div>
                        <div className="right">
                            <ul>
                                <li>
                                    Completed Benefit Activation Form and the date and the cause of Your Disability signed by a licensed doctor
                                    of medicine or osteopathy (other than Yourself).
                                </li>
                                <li>
                                    Satisfactory written proof of Your continuing Disability on a monthly basis or as required by us in
                                    order to continue to receive benefits.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left"><b>Hospitalization</b></div>
                        <div className="right"></div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Definition:</div>
                        <div className="right">You are admitted to a hospital for at least a one night stay.</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Exclusions – When this Covered Event will NOT give rise to a Benefit:</div>
                        <div className="right">None</div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Protection Amount:</div>
                        <div className="right">
                            The monthly Minimum Payment Due on Your Account starting on the date of the Covered Event, minus any delinquent
                            amounts, including any monthly Minimum Payments that were due prior to the date of the Covered Event and late fees
                            that were incurred prior to the date of the Covered Event.
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Maximum Benefit Activation Period:</div>
                        <div className="right">
                            One month Benefit (the monthly Minimum Payment Due as of the monthly billing cycle that includes the date of your
                            hospital stay).
                        </div>
                    </div>
                    <div className="terms-table-row">
                        <div className="left">Required Documents:</div>
                        <div className="right">Completed Benefit Activation Form and a copy of Your hospital discharge papers.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditProtectionDisclosures;