import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { faPlay, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import PixelsService from '../../../../services/pixels';
import AxiosWrapper from '../../../../services/axios';
import GrandBankFooter from './grandBankFooter';


function GrandBankEntryPoint() {
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const GBToltip = useStoreState(state => state.GBText.tooltip)
    const PixelService = new PixelsService();
    const setting = useStoreState(state => state.siteSettingsModel);
    const client = new AxiosWrapper();

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
    },[])

    function clickRedirect(){
        let id = applicationResult.ref_number;

        client.post("update-gb-redirect-status/"+id+"/true", {"interstitialPage" : false, "applicant" : id, "isRedirected" : true});
    }

    return (
        <div className="row mb-3 baseOffer grandBrank p-0">
           <div className="col-xs-12 col-md-5 col-lg-5 p-4">
                <img className='w-100' src='/Grand-Bank-Art.png' alt='Grand Bank' />
           </div>
           <div className="col-xs-12 col-md-7 col-lg-7 p-4">
                <div className='row'>
                    <div className='col-12'>
                        <p className='GBheaderText' style={{fontSize : "17pt", fontWeight : "600 !important"}}>
                            Grand360 checking/savings plus much more
                            makes it easy to manage your money and reach
                            your financial goals.
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ul style={{padding: "0"}}>
                            <li className='mb-3'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay}/></span>
                                <label className='GBtextList'>Get up to $150 in Cover Me Now</label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.upTo}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                                
                            </li>
                            <li className='mb-3'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay}/></span>
                                <label className='GBtextList'>Get paid up to 2 days early </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.paidEarly}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-3'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay}/></span>
                                <label className='GBtextList'>No credit check </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.noCheck}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-3'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay}/></span>
                                <label className='GBtextList'>Simulator and Credit Score Monitoring</label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.freeMonitoring}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-3'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay}/></span>
                                <label className='GBtextList'>Financial Wellness Tool </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.wellnessTool}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='row mb-0'>
                    <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-3'>
                        <form action={applicationResult.GrandbankUrl} method="POST" target='_blank'>
                            <input type="hidden" value={applicationResult.GBAuthId} name="authid"/>
                            <button type='submit' className='btn btn-round btn-block tempClass' onClick={() => {PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect()}} style={{backgroundColor : "#f04d4e"}}>Let’s Go!</button>
                        </form>
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
                        <Link to={ "grandbank"+window.location.search} onClick={() => window.scrollTo(0, 0)} className='btn btn-round btn-block' style={{backgroundColor : "#375e7b"}}>Learn More</Link>
                    </div>
                </div>
                
           </div>
           <div className='col-12'>
                <div className='row mb-1'>
                    <div className='col-12 mb-1 pr-5 pl-5'>
                        <p className='GBFooterText text-left'>By clicking Let’s Go!, you authorize the sharing of your personal information with Grand Bank.</p>
                        <p className='GBFooterText text-left' >
                            Receive all the benefits of Grand360 for just $5 a month.
                        </p>
                    </div>
                </div>
                <div className='row mb-0'>
                    <div className='col-12' style={{backgroundColor : "#375e7b"}}>
                        <p className='text-center mb-1 mt-1' style={{color: "white", fontWeight : "500", fontSize: "14pt"}}>
                            No minimum deposit | Account access 24/7 | 40,000 surcharge free ATMs nationwide
                        </p>
                    </div>
                </div>
                
           </div>
           <GrandBankFooter />
        </div>
    );

}

export default GrandBankEntryPoint;