import React from 'react';
import "../../../../assets/prequal/stepers.css";

const Stepers = (props) => {

    return (
        <>
        <div className="steperBorder">
            <div className="bar" style={{width: `${(props.step * 100)/ props.totalSteps}%`}}>
            </div>
        </div>
        </>
    );
}

export default Stepers;