import React from 'react';
import { Route, Switch } from "react-router-dom";
import Landing from "./sections/landing";
import Step1 from "./sections/step1";
import Step2 from "./sections/step2";
import Step4 from "./sections/step4";
import Step3 from "./sections/step3";
import Step5 from "./sections/step5";
import Approval from "../global-elements/approved2";
import Step6Terms from "./sections/step6terms";
import UserTerms from "./sections/userTerms";
import '../../../assets/prequal/newDesign.css';
import { useStoreState } from "easy-peasy";
import { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import FacebookPixel from 'react-fbq';
import marketing from '../../../services/marketing';
import Declined from '../global-elements/declined';
import DeclinedTemporal from "../global-elements/declinedTemporal";
import ReactGA from "react-ga4";

function Layout() {

    const Settings = useStoreState(state => state.siteSettingsModel);
    const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);

    const utm = new marketing();

    useEffect(() => {
        document.querySelector('title').textContent = `${brandSettings.ProductName} - Mastercard`;
        //Google Pixel
        if (Settings.Pixels.find((Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
            ReactGA.initialize(Settings.Pixels.find((Pixel) => Pixel.Type === process.env.REACT_APP_GAFLAG)["Tag"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        //Facebook Pixel
        if(Settings.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
            FacebookPixel.initialize({id : Settings.Pixels.find(Pixel => Pixel.Type === "Facebook")["Tag"]}) 
        }
        //Tiktok Pixel
        if(Settings.Pixels.find(Pixel => Pixel.Type === "Tiktok")) { 
            TiktokPixel.init(Settings.Pixels.find(Pixel => Pixel.Type === "Tiktok")["Tag"]);
        }
        //MNTN Pixel
        if(Settings.Pixels.find(Pixel => Pixel.Provider === "Mntn")) { 
            utm.mntnLocation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Switch>
            {/* Landing page No form */}
            <Route path="/" component={() => <Landing />} exact />

            {/* Step1 of the form (First name, middle name, Last Name, Suffix, Email) */}
            <Route path="/step1" component={() => <Step1 />} exact />

            {/* Step2 of the form (Home Address, Address 2, City, State, Zip Code, Primary Phone Number) */}
            <Route path="/step2" component={() => <Step2 />} exact />

            {/* Step3 of the form (Primary Source of income, Your annual income, Checking account, additional card) */}
            <Route path="/step3" component={() => <Step3 />} exact />

            {/* Step4 of the form (Social Security Number, Date of Birth) */}
            <Route path="/step4" component={() => <Step4 />} exact />

            {/*  Submit Form and Accept Agreements */}
            <Route path="/step5" component={() => <Step5 />} exact />

            {/*  Approval Screen */}
            <Route path="/approved" component={() => <Approval />} exact />
            
            {/* Select card and show terms */}
            <Route path="/terms" component={() => <Step6Terms />} exact />

            {/* User Agreement Only-Read Screen */}
            <Route path="/userTerms" component={() => <UserTerms />} exact />

            {/* Declined Page depending if GrandBank is active*/}
            <Route path="/declined" component={() => <Declined />} exact />

            {/* Declined Page depending if is a pendinfo result*/}
            <Route path="/pendinfo" component={() => <DeclinedTemporal />} exact />
        </Switch>
    );
}

export default Layout;
