import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ReservationNumberMask from '../components/ReservationNumberMask';
import SsnLastFourMask from '../components/SsnLastFourMask';
import { errorAlert, getValidation} from '../validationRules'
import AxiosWrapper from '../../../../services/axios';
import { AiOutlineExclamation } from 'react-icons/ai';
import '../../../../assets/prequal/prequaloffer.css';
import { Controller, useFormContext } from "react-hook-form";
import marketing from "../../../../services/marketing";
import PixelsService from '../../../../services/pixels';

function PrequalOffer() {
   
    const { control, handleSubmit, formState: { errors } } = useFormContext();
    const history = useHistory();
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const validations = useStoreState(state => state.siteSettingsModel.validationRules);
    const applicationResult = useStoreState(state=> state.applicationResultModel.applicationResult);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const setApplicantInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const applicantApiClient = new AxiosWrapper();
    const domain = useStoreState(state => state.siteSettingsModel.domain);
    const PixelService = new PixelsService();
    //error messages
    const refInputErrorMsg = "Please enter a reference number. ";
    const ssnInputErrorMsg = "Last four of SSN are missing.";
    const [errorMsg, setErrorMsg] = useState("");
    const termsStep = useStoreActions(actions => actions.setDisplayPrequalTerms);
    const setInitPixels = useStoreActions(actions => actions.setInitPixels);
    const initPixels = useStoreState(state => state.initPixels);
    const utm = new marketing();
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);

    useEffect(() => {
        //Google Pixels
        if(!initPixels){
            PixelService.initialize().pageView("/offer").event("google", "tracking", "offer").event("tiktok", null, "View Content");
       
            getRoutingSettings({ Url: domain, Route: window.location.pathname});
            setInitPixels(true)
        }
        
        
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        let hashString = applicantApiClient.findParam("hash");
        setApplicantInfo({ index: "hasCheckingAccount", value: applicantApiClient.findParam("hascheckingaccount") || "" });
        if(hashString){
            const newHash = hashString.replace("0x", "");
            submitHash(newHash);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

    const submitHash = async (hash) => {
        const response = await applicantApiClient.post('verify-prequal-application', { hashString: hash }, true);
        if (response && response.Success === true) {
            setApplicationResult({
                product_code: response.Payload.ProductCode,
                ref_number: response.Payload.ApplicantId,
                credit_limit: response.Payload.CreditLimit,
                result: "APPROVED",
            });
            termsStep(true)
            history.push(`/terms`+window.location.search);
        } else {
            setApplicationResult({ result: "ERROR" });
        };
    }
   

    const submitRez =  async (data) => {
        var rezRequest = {
            reservationNumber: "",
            referenceNumber: data.referenceNumber,
            hashString: "",
            ssnLast4: data.ssnLastFour,
        };
        const response = await applicantApiClient.post('verify-prequal-application', rezRequest);
        if (response) {
            if(response.ApplicantId){
                utm.updateSessionReport("confirmReservationAttempt1")
                setApplicationResult({product_code: response.ProductCode,  ref_number: response.ApplicantId, result:"APPROVED", credit_limit:response.CreditLimit});
                setShowErrorMsg(false);
                termsStep(true)
                history.push(`/terms`+window.location.search);
            }else{
                setErrorMsg("Unable to verify offer. Please check reference number and try again. ");
                setShowErrorMsg(true);
            }
           
        } else {
           
        };
    };

    useEffect(() => {
        if (applicationResult.result === "ERROR") {
            setErrorMsg("Unable to verify offer. Please check reference number and try again. ");
            setShowErrorMsg(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationResult.result]);

    return (
        <div id="prequal-offer-wrapper">
            <Row id="header-text-container" style={{ backgroundColor:  brandSettings.Primary }}>
                <h1 id="offer-header-text">Finish Right Where You Left Off!</h1>
            </Row>
            <Container id="offer-container">
                <form onSubmit={handleSubmit(submitRez)}>
                    <Row>
                        <Col id="centered-column">
                            <Row id="offer-row-left" className="d-md-none">
                                <b>
                                    <ul>
                                        <li>It wont affect your credit score</li>
                                        <li>It takes less than a minute to see if you pre-qualify</li>
                                        <li>Review your offer and apply</li>
                                    </ul>
                                </b>
                            </Row>
                            <Row id="offer-row">
                                <img src={brandSettings.CardImage} alt={`${brandSettings.ProductName} Card`} className="col-lg-12"/>
                            </Row>
                            <Row id="offer-row">
                                <p id="reflex-card-text">
                                    Enjoy automatic account reviews for credit limit increases
                                    and free access to your credit score with the {brandSettings.ProductName} Mastercard&#174;!
                                </p>
                            </Row>
                        </Col>
                        <Col id="centered-column">
                            <Row id="offer-row-left" className="d-none d-sm-none d-md-block">
                                <b>
                                    <ul>
                                        <li>It wont affect your credit score</li>
                                        <li>It takes less than a minute to see if you pre-qualify</li>
                                        <li>Review your offer and apply</li>
                                    </ul>
                                </b>
                            </Row>
                            <Row id="offer-row">
                                <Form id="offer-form">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Reference Number</Form.Label>
                                        <div className="input-container" >
                                            <Controller
                                                name="referenceNumber"
                                                control={control}
                                                rules={getValidation("referenceNumber", validations)}
                                                render={({ field }) => 
                                                    <ReservationNumberMask
                                                        {...field}
                                                        id="input-field"
                                                        placeholder="Enter reference number"
                                                    />}
                                            />
                                            {errors.referenceNumber && <AiOutlineExclamation color={errorAlert.color} size={errorAlert.size} />}
                                        </div>   
                                        {errors.referenceNumber && <span className='error-message'> { refInputErrorMsg } </span>} 
                                        {showErrorMsg && <span className='error-message'> { errorMsg } </span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Last 4 of SSN</Form.Label>
                                        <div className="input-container" >
                                            <Controller
                                                name="ssnLastFour"
                                                control={control}
                                                rules={getValidation("ssnLastFour", validations)}
                                                render={({ field }) => 
                                                    <SsnLastFourMask
                                                        {...field}
                                                        id="input-field"
                                                        placeholder="Enter Last 4 numbers of your SSN"
                                                    />}
                                            />
                                            {errors.ssnLastFour && <AiOutlineExclamation color={errorAlert.color} size={errorAlert.size} />}
                                        </div>
                                        {errors.ssnLastFour && <span className='error-message'> { ssnInputErrorMsg } </span>} 
                                    </Form.Group>
                                    <button className="btn" style={{ backgroundColor: brandSettings.Secondary }} type="submit">
                                        Return to Application
                                    </button>
                                </Form>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </Container>
        </div>
    );
}

export default PrequalOffer;