import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useStoreState } from 'easy-peasy';
import '../../../assets/terms/applicantdisclosure.css';
import axios from "../../../services/axios";
import Parser from 'react-html-parser';
import marketing from "../../../services/marketing";

function ApplicantDisclosures() {
    const [Template, setTemplate] = useState("");
    const utm = new marketing();
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setting = useStoreState(state => state.siteSettingsModel);
    const client = new axios();

    useEffect(() => {
        getTemplate();    
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);


    const getTemplate = async () => {
        let PC = setting.mainSettings.ProductCode;
        if(applicationResult.product_code !== ""){
            PC = applicationResult.product_code;
        }
        localStorage.setItem("TCProductCode", PC)
        let data = await client.get('get-pdf-html-template/'+PC);
        setTemplate(data); 
    }

    return (
        <Fragment>
            <div id="applicant-disclosure" >
            
                    {Parser(Template)}
                    
            </div>
            <div className="terms-print">
                <a  href='/tc' target="_blank" className="button-link" onClick={(e) => {utm.updateSessionReport("disclosureLink")}}>
                    <FontAwesomeIcon icon={faPrint} /> &nbsp; Rates, Fees, Costs and Limitations
                </a>
            </div>
        </Fragment>

    )
}

export default ApplicantDisclosures;