import React, { useState, useEffect, useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from "react-hook-form";
import axios from '../../../services/axios';
import { AiOutlineExclamation } from 'react-icons/ai';
import marketing from "../../../services/marketing";
import { errorAlert } from '../forms/validationRules';


function Acknowledgements(props) {

    const applicantApiClient = new axios();
    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);  
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const validations = useStoreState(state => state.siteSettingsModel.validationRules);    

    const { setValue, register } = useFormContext();
    const [authorization, setAuthorization] = useState(false);
    const [emailOffers, setEmailOffers] = useState(false);
    const isMounted = useRef(true);
    const utm = new marketing();
    const methods = useFormContext({ shouldFocusError: false });
    
    let tcAcknowldgement = validations.filter(o => o.ValidationType === "tcAcknowledgement")[0];

    const handleOpenTerms = (e) => {

        e.preventDefault();
        utm.updateSessionReport("disclosureLink")
        applicantApiClient.getPDF('get-pdf/' + applicationResult.product_code)
            .then((blob) =>  window.open(URL.createObjectURL(blob), '_blank'))
            .catch((error) => console.error(error))
    }

   

    const handleAuthorization = () => {
        setAuthorization(!authorization);
    }
    const handleEmailOffers = () => {
        setEmailOffers(!emailOffers);
    }

    useEffect(() => {
        if(isMounted.current){
            setValue("authorization", authorization, { shouldValidate: true });
        }
        return () => { isMounted.current = false }; 
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorization]);

    useEffect(() => {
        if(isMounted.current){
            setValue("emailOffers", emailOffers, { shouldValidate: true })
        }
        return () => { isMounted.current = false }; 
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailOffers])

    return (
        <div id="tcAcknowledgement2" className="row">
            {mainSettings.Flow === "CFC" ?
                <div className="col-lg-12">
                    <div className="form-check check-fields">
                        <input
                            onClick={() => { utm.updateSessionReport("iAgreeButton"); handleAuthorization(); }} 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            id="credit-protection-yes"
                            {...register("authorization", {required: tcAcknowldgement.IsRequired, shouldUnregister:true})} 
                         />
                        <label className="form-check-label" htmlFor="credit-protection-yes">
                            You acknowledge that by checking this box and pressing the "Click Here to Sign and Submit Your Application" button below,
                            you are completing and signing this Application electronically. You understand that you are applying to Celtic Bank for a {brandSettings.ProductName}
                            Mastercard® credit card and that by submitting this application you are authorizing the following: Obtain and use credit bureau
                            reports on me, check my employment history, contact me by any means, including mail, phone, text, or e-mail, and exchange information
                            about me in connection with this Application and any update, extension of credit, review, or collection of my Account. Finally,
                            you have read all of the <button type="button" className="button-link" onClick={handleOpenTerms}>Rates, Fees, Costs and Limitations</button>, <a href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a>,
                            and <a href={productSettings.OmpsUrl} target="_blank" rel="noopener noreferrer">Online Mobile Privacy Statement</a>, agree with all of the terms, and have printed or retained an electronic
                            copy for my records.
                        </label>
                    </div>
                    <div className="form-check check-fields">
                        <input className="form-check-input" type="checkbox" value="" id="credit-protection-yes" {...register("emailOffers", {required: tcAcknowldgement.IsRequired, shouldUnregister:true})} checked={emailOffers} onClick={() => { handleEmailOffers() }} />
                        <label className="form-check-label" htmlFor="credit-protection-yes">
                            I consent and understand that {brandSettings.BankName} and Continental Finance Company will use any information I provide
                            to apply for a credit product to enable physical and digital communications from selected affiliated and
                            non-affiliated marketing partners in compliance with the <a rel="noopener noreferrer" href={productSettings.PrivacyUrl} target="_blank">Privacy Notice</a>. My consent 
                            is not required for approval of this application. If I receive any email communications from affiliated or non-affiliated 
                            marketing partners, I understand that I can unsubscribe at any time by clicking the unsubscribe link in that email message.
                </label>
                    </div>
                </div> :
                <div className="col-lg-12">
                    <p>
                        I acknowledge that by checking this box and pressing the "Click Here to Sign and Submit Your Application" button below,
                        I am completing and signing this Application electronically. I understand that I am applying to {brandSettings.BankName} for a {brandSettings.ProductName}
                        Mastercard® credit card and that by submitting this application I am authorizing the following: Obtain and use credit bureau
                        reports on me, check my employment history, contact me by any means, including mail, phone, text, or e-mail, and exchange information
                        about me in connection with this Application and any update, extension of credit, review, or collection of my Account. Finally,
                        I have read all of the <a href="/tc" target="_blank" onClick={(e) => {utm.updateSessionReport("disclosureLink")}} className="button-link">Rates, Fees, Costs and Limitations</a>, <a  onClick={() => { utm.updateSessionReport("privacyLink") }}  href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, 
                        and <a onClick={() => { utm.updateSessionReport("eConsentLink") }} href={productSettings.OmpsUrl} target="_blank" rel="noopener noreferrer">Online Mobile Privacy Statement</a>, agree with all of the terms, and have printed or retained an electronic copy for my records.
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td className="faArrowRight">
                                    <span><FontAwesomeIcon icon={faArrowRight} /></span>
                                </td>
                                <td>
                                    <div className="form-check">
                                        <input onClick={() => { utm.updateSessionReport("iAgreeButton") }} className="form-check-input" type="checkbox" value="" id="tcAcknowledgement" {...register("tcAcknowledgement", {required: tcAcknowldgement.IsRequired, shouldUnregister:true})} />
                                        <label className="form-check-label" htmlFor="tcAcknowledgement">
                                            I agree to the Acknowledgements
                                    </label>
                                    </div>
                                </td>
                                
                            </tr>
                            <tr>
                            <td colSpan={2}>
                                    {methods.formState.errors.tcAcknowledgement ?
                                    <span
                                                className='p-2 text-white mb-2 ml-1'
                                                style={{ backgroundColor: "red", width: "100%", display : "block" }}
                                            >
                                                <AiOutlineExclamation color={errorAlert.color} size={errorAlert.size} />
                                                You need to agree to the acknowledgements
                                    </span> : <></>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}
export default Acknowledgements;