import React, {useEffect} from 'react';
import { useStoreState } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import {  useForm, FormProvider } from "react-hook-form";
import Banner from '../banners/banner';
import PrequalForm from '../forms/prequal/prequalform';
import PrequalOffer from '../forms/prequal/prequaloffer';
import Result from '../resultpage/result';

function PrequalFlow() {
    let mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    let productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const location = useLocation();
    const displayStepone = useStoreState(state => state.displayStepone);
    const displayBanner = useStoreState(state => state.displayBanner);
    const displayResult = useStoreState(state => state.displayResult);
    const methods = useForm({ shouldFocusError: false, mode: 'onSubmit' });

    useEffect(() => {
        let title = "";
        switch  (mainSettings.Flow) {
            case "PREQUAL":
                if (productSettings.ProductId === 1) {
                    
                }
                else {
                    title = "Reflex Mastercard - PreQual";
                    document.title = title;
                }
            break;
            default:
                title = ""
            break;

        }
        
    }, [mainSettings.Flow, productSettings.ProductId]);

    return (
        <FormProvider {...methods}>
            {displayBanner && mainSettings.Flow.toUpperCase() === "PREQUAL" && location.pathname !=="/offer" ? <Banner /> : null}
            {displayStepone ? location.pathname==="/offer" ? <PrequalOffer /> : <PrequalForm /> : null}
            
            {displayResult ? <Result basePath={location.pathname} /> : null}
        </FormProvider>
    );
}

export default PrequalFlow;
